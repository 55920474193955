import { DestroyRef, Inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, Observable, of, tap, switchMap } from 'rxjs';

import { GoogleAnalyticsService } from './google-analytics.service';
import { IntercomService } from '../chat/intercom.service';
import { HubspotAnalyticsService } from './hubspot-analytics.service';
import { AnalyticsEvent } from './analytics-event';
import { UserPropertiesQuery } from './user-properties.query';
import { LoggerService } from '../logger/logger.service';
import { HotjarService } from './hotjar.service';
import { UserProperties } from './user-properties';
import { UserPropertiesManager } from './user-properties-manager.interface';
import { USER_PROPERTIES_SERVICE } from './user-properties-injection-token';

@Injectable({
	providedIn: 'root'
})
export class AnalyticsService {
	private readonly loggerName = 'AnalyticsService';

	constructor(
		private logger: LoggerService,
		private destroyRef: DestroyRef,
		private googleAnalytics: GoogleAnalyticsService,
		private hubspot: HubspotAnalyticsService,
		private intercom: IntercomService,
		@Inject(USER_PROPERTIES_SERVICE) private userPropertiesService: UserPropertiesManager,
		private userPropertiesQuery: UserPropertiesQuery,
		private hotjar: HotjarService) {
	}

	init(): Observable<void> {
		this.userPropertiesService.init();
		this.googleAnalytics.init();
		this.hotjar.init();

		this.listenToUserProperties();
		return of(void 0);
	}

	private listenToUserProperties(): void {
		this.userPropertiesQuery.selectLoading().pipe(
			filter(loading => !loading),
			switchMap(() => this.userPropertiesQuery.select()),
			tap(res => this.updateUserProperties(res)),
			takeUntilDestroyed(this.destroyRef)
		).subscribe();
	}

	private updateUserProperties(userProperties: UserProperties) {
		this.logger.debug(this.loggerName, 'updateUserProperties()');
		this.intercom.updateUserProperties(userProperties);
		this.hotjar.updateUserProperties(userProperties);
		this.hubspot.updateUserProperties(userProperties);
	}

	getTimespan(startTime: Date): number {
		const endTime = new Date();
		const session = (endTime.getTime() - startTime.getTime()) / 1000;
		return session;
	}

	track(event: AnalyticsEvent): void {
		this.logger.debug(this.loggerName, `event(action=${event.eventAction} category=${event.eventCategory})`);
		this.googleAnalytics.emitEvent(event);
		
		// Intercom has a limit to number of events, and we are not using this feature
		this.intercom.trackEvent(event);
		this.hubspot.trackEvent(event.eventCategory);
	}

	pageView(route: string): void {
		this.logger.debug(this.loggerName, `pageView(${route})`);
		this.googleAnalytics.pageView(route);
		this.hubspot.trackPageView(route);
	}
}
