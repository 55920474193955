import { Injectable } from '@angular/core';
import { enableAkitaProdMode } from '@datorama/akita';
import { Observable, throwError, catchError, concatMap, map, tap, first, of } from 'rxjs';

import { environment } from 'src/environments/environment';
import { LoggerService } from 'src/app/logger/logger.service';
import { ContentService } from 'src/app/services/content.service';
import { AnalyticsService } from 'src/app/analytics/analytics.service';

@Injectable({
	providedIn: 'root'
})
export class CollaborationBootstrapService {
	private readonly loggerName = 'CollaborationBootstrapService';

	constructor(
		private content: ContentService,
		private loggerService: LoggerService,
		private analyticsService: AnalyticsService,
	) {}

	preLoad(): Observable<void> {
		if (environment.production) {
			enableAkitaProdMode();
		}

		return this.loggerService.init().pipe(
			concatMap(() => this.checkCookiesEnabled()),
			concatMap(() => this.content.init()),
			concatMap(() => this.analyticsService.init()),
			tap(() => this.loggerService.info(this.loggerName, 'Collaboration bootstrap finished')),
			map(() => void 0),
			catchError(err => {
				console.error(`${this.loggerName} Error initializing collaboration app`, err);
				return throwError(err);
			})
		);
	}

	// Cookies are required for the authService
	private checkCookiesEnabled(): Observable<void> {
		if (this.areCookiesEnabled()) {
			return of(void 0).pipe(first());
		}
		else {
			this.loggerService.error(this.loggerName, 'Cookies disabled');
			window.location.href = environment.routing.cookiesDisabled;
			return throwError('cookies-disabled');
		}
	}

	private areCookiesEnabled(): boolean {
		const testCookieName = 'testcookie';

		var cookieEnabled = (navigator.cookieEnabled) ? true : false;
	
		if (typeof navigator.cookieEnabled === undefined && !cookieEnabled) { 
			document.cookie = testCookieName;
			cookieEnabled = (document.cookie.indexOf(testCookieName) != -1) ? true : false;
		}

		return cookieEnabled;
	}
}