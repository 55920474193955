import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { catchError, filter, from, map, of, take } from "rxjs";

import { CollaborationTokenService } from "./services/collaboration-token.service";

@Component({
  selector: 'collaboration-auth-proxy',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollaborationAuthProxyComponent implements OnInit {

    constructor(
        private tokenService: CollaborationTokenService,
    ) {}

    ngOnInit(): void {
        this.init();
    }

    private async init(): Promise<void> {        
        await this.tokenService.initAuth0Client();

        const isAuthenticated = await this.tokenService.isAuthenticated();
        if (isAuthenticated) {
            from(this.tokenService.extractAccessToken()).pipe(
                take(1),
                filter(token => !!token),
                map(() => this.tokenService.navigateToAssessmentPage()),
                take(1),
                catchError(error => {
                    console.error(error);
                    return of(this.tokenService.navigateToExpiredLink());
                })
            ).subscribe();
        }
    }
}
