import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { TippyConfig } from '@ngneat/helipopper';

import { getDefaultConfiguration, TippyModule } from '../tippy.module';
import { SafePipe } from '../pipes/safe.pipe';
import { NgIf } from '@angular/common';

@Component({
	standalone: true,
	selector: 'mine-info-icon',
	templateUrl: './mine-info-icon.component.html',
	styleUrls: ['./mine-info-icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [TippyModule, SafePipe, NgIf]
})
export class MineInfoIconComponent {
	@Input() heliText: string;
	@Input() tpProps: Partial<TippyConfig> = {
		placement: 'top',
		maxWidth: getDefaultConfiguration().popper.maxWidth,
	};
}
