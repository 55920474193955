import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CollaborationTokenStoreService {
  
  getEmail(): string | undefined {
    return localStorage.getItem('email');
  }

  setEmail(email: string): void {
    localStorage.setItem('email', email);
  }

  getCompanyId(): string | undefined {
    return localStorage.getItem('companyId');
  }

  setCompanyId(companyId: string): void {
    localStorage.setItem('companyId', companyId);
  }

  getCompanyName(): string | undefined {
    return localStorage.getItem('companyName');
  }

  setCompanyName(companyName: string): void {
    localStorage.setItem('companyName', companyName);
  }

  getAgentEmail(): string | undefined {
    return localStorage.getItem('agentEmail');
  }

  setAgentEmail(agentEmail: string): void {
    localStorage.setItem('agentEmail', agentEmail);
  }

  getAssessmentId(): string | undefined {
    return localStorage.getItem('assessmentId');
  }

  setAssessmentId(assessmentId: string): void {
    localStorage.setItem('assessmentId', assessmentId);
  }

  getAccessToken(): string | undefined {
    return localStorage.getItem('accessToken');
  }

  setAccessToken(accessToken: string): void {
    localStorage.setItem('accessToken', accessToken);
  }

  getLogoutTitle(): string | undefined {
    return sessionStorage.getItem('logoutTitle');
  }

  setLogoutTitle(logoutTitle: string): void {
    sessionStorage.setItem('logoutTitle', logoutTitle);
  }

  getLogoutText(): string | undefined {
    return sessionStorage.getItem('logoutText');
  }

  setLogoutText(logoutText: string): void {
    sessionStorage.setItem('logoutText', logoutText);
  }

  clearAll(): void {
    for (let key of [
      'accessToken',
      'agentEmail',
      'assessmentId',
      'companyId',
      'companyName',
      'email',
    ]) {
      localStorage.removeItem(key);
    }
  }

  clearAllLogout(): void {
    for (let key of [
      'logoutTitle',
      'logoutText',
    ]) {
      sessionStorage.removeItem(key);
    }
  }
}
