export enum RightProcessingTypeEnum {
    Mutate = "DataMutation",
    Collect = "DataCollection"
}

export enum PredefinedRightIds {
    Delete = 'delete',
    Copy = 'getcopy',
    DoNotMail = 'donotmail',
    RightToEdit = 'righttoedit',
    DoNotSell = 'donotsell',
}