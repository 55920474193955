import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, first, from, map, Observable, switchMap, tap } from "rxjs";
import { JwtTokenObject } from "../../api/models/auth/jwt-token-object";
import { LoggerService } from "../../logger/logger.service";
import { ApiClientIpaasService } from "../../api/api-client-ipaas.service";
import prismatic, {
    closePopover,
    ConfigureInstanceProps,
    getMessageIframe,
    PrismaticMessageEvent,
    ShowDashboardProps,
    ShowDesignerProps,
    ShowMarketplaceProps
} from "@prismatic-io/embedded";
import { ConfigVars } from "@prismatic-io/embedded/dist/types/configVars";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class IntegrationIpaasService {
    private readonly loggerName: string = 'IntegrationIpaasService';

    constructor(
        private logger: LoggerService,
        private apiClientIpaasService: ApiClientIpaasService
    ) {
    }

    private tokenObj: JwtTokenObject;

    private listenerConfigurationLoaded: (message: MessageEvent) => void;

    private updateStatus: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    updateStatus$: Observable<string> = this.updateStatus.asObservable();

    showMarketplace(props: ShowMarketplaceProps): void {
        prismatic.showMarketplace(props);
    }

    showIntegrationDesigner(props: ShowDesignerProps): void {
        prismatic.showDesigner(props);
    }

    showDashboard(props: ShowDashboardProps): void {
        prismatic.showDashboard(props);
    }

    init(): void {
        this.logger.debug(this.loggerName, 'init()');
        prismatic.init({
            fontConfiguration: {
                google: {
                    families: ["Lato"],
                },
            },
            prismaticUrl: environment.prismatic.url
        });
    }

    generateIpaasToken(): Observable<JwtTokenObject> {
        return this.apiClientIpaasService.generateIpaasToken().pipe(
            first(),
            tap(res => this.tokenObj = res),
            tap(() => this.logger.debug(this.loggerName, 'Generated token'))
        );
    }

    // if authToken available - we should only authenticate to prismatic, otherwise generate token and authenticate
    authenticate(): Observable<void> {
        if (this.tokenObj?.authToken) {
            return from(this.authToPrismatic(this.tokenObj.authToken)).pipe(
                    tap(() => this.logger.debug(this.loggerName, 'Authenticated successfully')),
                    catchError(() => {
                        this.logger.error(this.loggerName, 'Authentication failed');
                        return this.generateTokenAndAuth();
                    }),
                );
        } else {
            return this.generateTokenAndAuth();
        }
    }

    private generateTokenAndAuth(): Observable<void> {
        return this.generateIpaasToken()
            .pipe(
                first(),
                switchMap(() => from(this.authToPrismatic(this.tokenObj.authToken))),
                tap(() => this.logger.debug(this.loggerName, 'Authenticated successfully')),
            );
    }

    private authToPrismatic(token: string): Promise<void> {
        return prismatic.authenticate({token});
    }

    removeJwtToken(): void {
        this.tokenObj = undefined;
    }

    configureInstance(instanceId: string): void {
        const config: ConfigureInstanceProps = {
            instanceId,
            skipRedirectOnRemove: true,
            usePopover: true,
            screenConfiguration: {
                isInPopover: true,
                configurationWizard: {
                    isInModal: true,
                    triggerDetailsConfiguration: "hidden"
                },
                instance: {
                    hideBackToMarketplace: true,
                    hidePauseButton: true,
                    hideTabs: ["Logs", "Test", "Executions", "Monitors"]
                }
            }
        };

        prismatic.configureInstance(config);
    }

    // setup non dsr integration
    configureNonDsrIntegrationInstance(integrationId: string): void {
        const config: ConfigureInstanceProps = {
            integrationId,
            skipRedirectOnRemove: true,
            usePopover: true,
            screenConfiguration: {
                isInPopover: true,
                configurationWizard: {
                    isInModal: true,
                    triggerDetailsConfiguration: "hidden"
                },
                instance: {
                    hideBackToMarketplace: true,
                    hidePauseButton: true,
                    hideTabs: ["Logs", "Test", "Executions", "Monitors"]
                }
            }
        };

        prismatic.configureIntegration(config);
    }

    setConfigVars(message: MessageEvent, configVars: ConfigVars): void {
        const iframe = getMessageIframe(message);
        prismatic.setConfigVars({iframe, configVars});
    }

    initializeEventListenerConfigLoaded(instanceIntegrationId: string): void {
        this.removeEventListenerConfigLoaded();

        this.listenerConfigurationLoaded = (message: MessageEvent) => {
            const { event, data } = message.data;

            switch (event) {
                case PrismaticMessageEvent.INSTANCE_CONFIGURATION_OPENED:
                    this.setConfigVars(message, {
                        "mine-integration-instance-id": {value: instanceIntegrationId}
                    });
                    break;
                case PrismaticMessageEvent.INSTANCE_DELETED:
                case PrismaticMessageEvent.INSTANCE_CONFIGURATION_CLOSED:
                    this.updateStatus.next(data.instanceName);
                    closePopover();
                    break;
            }
        };

        setTimeout(() => this.addEventListenerConfigLoaded(), 0);
    }

    addEventListenerConfigLoaded(): void {
        window.addEventListener("message", this.listenerConfigurationLoaded);
    }

    removeEventListenerConfigLoaded(): void {
        window.removeEventListener("message", this.listenerConfigurationLoaded);
    }

    getInstanceStatus(instanceId: string): Observable<boolean> {
        return from(prismatic.graphqlRequest({
            query: `query getInstancesStatus {
                        instances {
                            nodes {
                              id
                              enabled
                            }
                        }
                    }
                `,
        })).pipe(
            map(res => res?.data?.instances?.nodes?.find(node => node.id === instanceId)?.enabled ?? false)
        );
    }
}
