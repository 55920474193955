import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, HostListener, OnInit } from '@angular/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { ContentPipeModule } from 'src/app/services/content/content-pipe.module';

@Component({
  standalone: true,
  selector: 'google-copilot',
  templateUrl: './google-copilot.component.html',
  styleUrl: './google-copilot.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ContentPipeModule, AsyncPipe, NgIf],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class GoogleCopilotComponent implements OnInit {
  readonly assetsUrl = environment.assets.url;
  readonly googleChatbot = environment.googleChatbot;

  chatbotTitle: string;

  chatbotIcon: string;

  constructor(
    private contentPipe: ContentPipe,
    private authService: AuthService,
  ) { }

  @HostListener('document:click', ['$event.target']) onClick(targetElement) {
    const dfMessengerBubble: any = document.querySelector('df-messenger-chat-bubble');
		const clickedInside = dfMessengerBubble?.contains(targetElement);

		if (!clickedInside) {
      dfMessengerBubble?.closeChat();
		}
  }
  
  @HostListener('document:keydown.escape', ['$event']) onEscape() {
    const dfMessengerBubble: any = document.querySelector('df-messenger-chat-bubble');
    dfMessengerBubble?.closeChat();
  }
  
  ngOnInit(): void {
    this.chatbotTitle = this.contentPipe.transform('chatbot.title');
    this.chatbotIcon = `${this.assetsUrl}/Portal/copilot.svg`;
  }

  get isLoggedIn$(): Observable<boolean> {
    return this.authService.isLoggedIn$;
  }
}
