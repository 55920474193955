import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map, Observable } from 'rxjs';

import { ProfileStore } from './profile.store';
import { Profile } from '../models/profile.interface';
import { ProfileAgent } from 'src/app/api/models/profile/profile-agent';
import { RoleEnum } from 'src/app/core/models/permission-role.enum';
import { ProfileStatus } from 'src/app/api/models/profile/profile-agent.enum';
import { ProfilePlanDetails } from 'src/app/api/models/profile/profile-plan-details';
import { ConnectedToMineStatusEnum } from 'src/app/api/models/profile/connected-to-mine-status.enum';

@Injectable({ 
	providedIn: 'root' 
})
export class ProfileQuery extends Query<Profile> {

	constructor(
		protected store: ProfileStore,
	) {
		super(store);
	}

	selectCompanyId(): Observable<string> {
		return this.select('companyId');
	}

	getCompanyId(): string {
		return this.getValue().companyId;
	}

	getUserId(): string {
		return this.getValue().userId;
	}

	selectCompanyName(): Observable<string> {
		return this.select('companyName');
	}
	
	selectCompanyIcon(): Observable<string> {
		return this.select('companyIcon');
	}

	selectFullname(): Observable<string> {
		return this.select(['firstName', 'lastName']).pipe(
			map(profile => `${profile.firstName} ${profile.lastName}`)
		);
	}

	selectAgentRole(): Observable<RoleEnum> {
		return this.select('role');
	}

	getRole(): RoleEnum {
		return this.getValue().role;
	}

	selectAdmins(): Observable<ProfileAgent[]> {
		return this.select('agents').pipe(
			map(agents => agents.filter(a => a.role === RoleEnum.Admin))
		);
	}
	
	selectAgents(verifiedOnly = false): Observable<ProfileAgent[]> {
		return this.select('agents').pipe(
			map(agents => verifiedOnly ? agents.filter(agent => agent.status !== ProfileStatus.Invitee) : agents),
			map(agents => agents?.sort((a, b) => b.role?.localeCompare(a.role) || a.name?.localeCompare(b.name)))
		);
	}

	selectAgentsCount(): Observable<number> {
		return this.select('agents').pipe(
			map(agents => agents.length),
		);
	}

	selectAgentsByIds(agentsIds: Set<string>): Observable<ProfileAgent[]> {
		return this.select('agents').pipe(
			map(agents => agents?.filter(a => agentsIds?.has(a.id))),
		);
	}

	getAgentById(agentId: string): ProfileAgent {
		return this.getValue()?.agents?.find(agent => agent.id === agentId);
	}
	
	selectConsentManagementData(): Observable<Pick<Profile, 'cmpId' | 'createdByPartner'>> {
		return this.select(['cmpId', 'createdByPartner']);
	}

	selectAdminMail(): Observable<string> {
		return this.selectAdmins().pipe(
			map(admins => !!admins ? admins[0]?.email : '')
		);
	}

	getAgentEmail(): string {
		return this.getValue().email;
	}

	selectPlanDetails(): Observable<ProfilePlanDetails> {
		return this.select('planDetails');
	}

	selectConnectToMineStatus(): Observable<ConnectedToMineStatusEnum> {
		return this.select('connectedToMineStatus');
	}
}
