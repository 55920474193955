import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { BaseApiClientSystemsService } from "src/app/api/base-api-client-systems.service";
import { HttpClientHelperService } from "src/app/api/http-client-helper.service";
import { CompanySystemsResponse, SystemsIconsResponse, SystemsIconsV2Payload, SystemsIconsV2Response } from "src/app/api/models/systems/systems.interface";
import { SystemInstance } from "src/app/systems/models/systems.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiClientCollaborationSystemsService extends BaseApiClientSystemsService {
  protected readonly controller: string = 'collaboration/systems';

  constructor(httpClientHelper: HttpClientHelperService) {
    super(httpClientHelper);
  }

  override getCompanySystems(): Observable<CompanySystemsResponse> {
    return this.httpClientHelper.invokeGetAuth(`${this.controller}/CompanySystems`);
  }

  override updateSystem(systemId: string, system: Partial<SystemInstance>): Observable<void> {
    return this.httpClientHelper.invokePatchAuth(`${this.controller}/Systems/${systemId}`, system);
  }

  override getSystemsIcons(systemTypes: string[]): Observable<SystemsIconsResponse> {
    return this.httpClientHelper.invokePostAuth(`${this.controller}/SystemsIcons`, {
      systems: systemTypes.map(type => {
        return { "systemType": type };
      }),
		});
  }

  override iconsV2(payload: SystemsIconsV2Payload): Observable<SystemsIconsV2Response> {
    return this.httpClientHelper.invokePostAuth<SystemsIconsV2Response>(`${this.controller}/icons`, payload);
  }
}
