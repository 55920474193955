import { RoutesManager } from 'src/app/shared/models/routes.interfaces';
import { OnboardingFlow } from '../../models/onboarding-manager.interface';

export const onboardingFlow: OnboardingFlow = {
	route: `${RoutesManager.onboarding}/${RoutesManager.dataMapping}`,
	postSignupStep: {
		path: `${RoutesManager.onboarding}/${RoutesManager.onboarding_data_mapping}/${RoutesManager.onboarding_create_account}`,
	},
	lastStep: {
		path: `${RoutesManager.onboarding}/${RoutesManager.onboarding_data_mapping}/${RoutesManager.onboarding_scheduler}`,
        nextRoute: `${RoutesManager.dataMapping}`
	},
};
