import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { BaseApiClientEmployeesService } from "src/app/api/base-api-client-employees.service";
import { HttpClientHelperService } from "src/app/api/http-client-helper.service";
import { EmployeesListResponse } from "src/app/api/models/data-mapping/data-mapping.interface";
import { EmployeesSearchResult, SearchEmployee } from "src/app/api/models/employees/employees.intrface";

@Injectable({
  providedIn: 'root'
})
export class ApiClientCollaborationEmployeesService extends BaseApiClientEmployeesService {
  protected readonly controller: string = 'collaboration/employees';

  constructor(httpClientHelper: HttpClientHelperService) {
    super(httpClientHelper);
  }

  getEmployeesByIds(ids: string[]): Observable<EmployeesListResponse> {
		if (ids?.length) {
			let qParams = new HttpParams();
			qParams = qParams.append('ids', ids.join(','));
			return this.httpClientHelper.invokeGetAuth<EmployeesListResponse>(`${this.controller}/byIds`, qParams);
		}
		return of({} as EmployeesListResponse);
    }

	searchEmployee(search: SearchEmployee): Observable<EmployeesSearchResult> {
		let qParams = new HttpParams();
		qParams = qParams.append('searchTerm', search.searchTerm || '');
		if (search.excludeSystemId) {
			qParams = qParams.append('excludeSystemId', search.excludeSystemId);
		}
		return this.httpClientHelper.invokeGetAuth<EmployeesSearchResult>(`${this.controller}/search`, qParams);
	}
}
