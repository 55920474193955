import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { HttpClientHelperService } from "../http-client-helper.service";
import {
  DsrExtendedTemplate,
  DsrShallowTemplate,
  DsrTemplateUsages,
  DsrTemplateUsagesResponse,
  DsrTestTemplateLocale
} from "../models/dsr/dsr-templates.interface";

@Injectable({
    providedIn: 'root'
})
export class ApiClientDsrTemplatesService {

    private readonly dsrController = 'dsr';
    private readonly communicationController = 'communication';
    private readonly templatesController = 'templates';
    private readonly version = 'v1';

    constructor(
        private httpClientHelper: HttpClientHelperService
    ) {
    }

    getTemplatesShallow(): Observable<DsrShallowTemplate[]> {
        return this.httpClientHelper.invokeGetAuth<DsrShallowTemplate[]>(`${this.dsrController}/${this.version}/${this.communicationController}/${this.templatesController}`);
    }

    getTemplate(templateId: string): Observable<DsrExtendedTemplate> {
        return this.httpClientHelper.invokeGetAuth<DsrExtendedTemplate>(`${this.dsrController}/${this.version}/${this.communicationController}/${this.templatesController}/${templateId}`);
    }

    getTemplatesUsage(): Observable<DsrTemplateUsagesResponse> {
        return this.httpClientHelper.invokeGetAuth<DsrTemplateUsagesResponse>(`${this.dsrController}/${this.version}/${this.communicationController}/${this.templatesController}/in-use`);
    }

    getTemplateUsage(templateId: string): Observable<DsrTemplateUsages> {
        return this.httpClientHelper.invokeGetAuth<DsrTemplateUsages>(
            `${this.dsrController}/${this.version}/${this.communicationController}/${this.templatesController}/in-use/${templateId}`);
    }

    testTemplateEmail(content: DsrTestTemplateLocale): Observable<void> {
        return this.httpClientHelper.invokePostAuth<void>(`${this.dsrController}/${this.version}/${this.communicationController}/${this.templatesController}/test-email`, content);
    }

    createTemplate(displayName: string): Observable<DsrShallowTemplate> {
        return this.httpClientHelper.invokePostAuth<DsrShallowTemplate>(`${this.dsrController}/${this.version}/${this.communicationController}/${this.templatesController}`, {displayName});
    }

    deleteTemplate(templateId: string): Observable<void> {
        return this.httpClientHelper.invokeDeleteAuth(`${this.dsrController}/${this.version}/${this.communicationController}/${this.templatesController}/${templateId}`)
            .pipe(map(() => void 0));
    }

    deleteTemplateLocale(templateId: string, locale: string): Observable<void> {
        return this.httpClientHelper.invokeDeleteAuth(`${this.dsrController}/${this.version}/${this.communicationController}/${this.templatesController}/${templateId}/${locale}`)
            .pipe(map(() => void 0));
    }

    updateTemplate(templateId: string, template: Partial<DsrExtendedTemplate>): Observable<DsrExtendedTemplate> {
        return this.httpClientHelper.invokePatchAuth<DsrExtendedTemplate>(`${this.dsrController}/${this.version}/${this.communicationController}/${this.templatesController}/${templateId}`, template);
    }
}