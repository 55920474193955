import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';

import { IntercomService } from './intercom.service';
import { LoggerService } from '../logger/logger.service';
import { HubspotChatService } from './hubspot-chat.service';
import { ProfileQuery } from '../profile/state/profile.query';
import { FeatureFlagQuery } from '../feature-flag/state/feature-flag.query';
import { FeatureFlags } from '../api/models/profile/profile-feature-flags.enum';
import { MineOSChatbotService } from '../mineos-chatbot/services/mineos-chatbot.service';
import { Profile } from '../profile/models/profile.interface';

@Injectable({
    providedIn: 'root'
})
export class ChatService {
    private readonly loggerName: string = 'ChatService';
    private hubspotEnabled = false;
    private mineosChatbotFF = false;
    private identity: string = undefined;

    constructor(
        private logger: LoggerService,
        private profileQuery: ProfileQuery,
        private hubspot: HubspotChatService,
        private intercom: IntercomService,
        private featureFlagsQuery: FeatureFlagQuery,
        private mineosChatbotService: MineOSChatbotService,
    ) { 
        const flags = this.featureFlagsQuery.getMultipleFlags([FeatureFlags.DevMineOSChatbot, FeatureFlags.GoogleCopilot]);
        this.mineosChatbotFF = flags.devMineOSChatbot || flags.googleCopilot;
    }

    init(): Observable<void> {
        this.logger.trace(this.loggerName, `init`);
        if (this.mineosChatbotFF) { return; }
        this.initInternal(this.profileQuery.getValue());

        // the identity or feature flag can change when a user logs in/out
        return this.profileQuery.select().pipe(
            tap(profile => {
                if ((this.featureFlagsQuery.getFlag(FeatureFlags.PortalHubspotWidget) !== this.hubspotEnabled) || (profile.email !== this.identity)) {
                    this.initInternal(profile);
                }
            }),
            map(() => void 0),
        );
    }

    private initInternal(profile: Profile): void {
        this.identity = profile.email;

        // both are loaded by index.html so we have to give each a chance to handle it
        this.hubspot.init();
        this.intercom.init(profile);
    }

    show(): void {
        if (this.mineosChatbotFF) {
            this.mineosChatbotService.open();
        }
        else if (this.hubspotEnabled) {
            this.hubspot.show();
        }
        else {
            this.intercom.show();
        }
    }

    hide(): void {
        if (this.mineosChatbotFF) {
            this.mineosChatbotService.close();
        }
        else if (this.hubspotEnabled) {
            this.hubspot.hide();
        }
        else {
            this.intercom.hide();
        }
    }

    disable(): void {
        if (this.mineosChatbotFF) {
            return;
        }
        else if (this.hubspotEnabled) {
            return;
        }
        else {
            this.intercom.disable();
        }
    }

    updateUrl(): void {
        if (this.mineosChatbotFF) {
            return;
        }
        else if (this.hubspotEnabled) {
            this.hubspot.refreshChatWidget();
        }
        else {
            this.intercom.update();
        }
    }
}
