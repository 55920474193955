import { Pipe, PipeTransform } from '@angular/core';
import { LoggerService } from 'src/app/logger/logger.service';
import { ContentService } from '../content.service';
import { ContentPipeOptions } from './content-schema.interface';
import { ContentMode } from './content.enum';
import { ContentQuery } from './state/content.query';

@Pipe({
	name: 'content',
})
export class ContentPipe implements PipeTransform {
	private readonly loggerName: string = 'ContentPipe';

	constructor(
		private contentQuery: ContentQuery,
		private loggerService: LoggerService,
		private contentService: ContentService
	) {}

	/**
	 * @param {string} contentKey 
	 * @param {ContentPipeOptions} [options] language = may be string or Languages enum. params = key/value pairs to replace placeholders in return text
	 * @example in HTML: <p>{{data | content: { params: contentParamsObject } }}</p>
	 * @example in TS: pipe.transform(contentKey, { params: {key: value }})
	 * @returns string or json object
	 */
	transform(contentKey: string, options?: ContentPipeOptions) {
		if (!contentKey) {
			return;
		}
		const splittedKeys = contentKey.split('.');

		if (splittedKeys.length < 2) {
			// content key is not valid
			this.loggerService.error(this.loggerName, `content is not valid: ${contentKey}`);
			return '';
		}

		const content = this.fetch(this.contentQuery.getSchema(splittedKeys[0], options?.language), splittedKeys.slice(1));
		//debug mode available only for string types
		if (this.contentService.contentMode === ContentMode.Debug && typeof content === 'string') {
			return contentKey;
		}

		if (options?.params) {
			return this.replacePlaceholders(content, options.params);
		}

		return content;
		
	}

	private fetch(schema: any, properties: string[]): any {
		let content = schema;
		properties.forEach(p => {
			content = content[p];
		});

		return content;
	}

	private replacePlaceholders(content: string | object, placeholders: { [key: string]: string | number }): string | object {
		for (const [key, value] of Object.entries(placeholders)) {
			const placeholder = new RegExp(`{{${key}}}`, 'gi');
			
			if (typeof content === 'string') {
				content = this.replaceText(content, placeholder, value);
			}
			else {
				//support params that are a part of a value inside a component
				const updatedContent = {}; 
				for (let [contentKey, contentValue] of Object.entries(content)) {
					updatedContent[contentKey] = this.replaceText(contentValue, placeholder, value);
				}
				content = updatedContent;
			}
		}
		return content;
	}

	private replaceText(content: string, oldValue: string | RegExp, value: string | number): string {
		return content?.replace(oldValue, (value == null) ? '' : '' + value) ?? '';
	}
}
