import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullPathMatch, RoutesManager } from '../shared/models/routes.interfaces';
import { CollaborationAuthProxyComponent } from './collaboration-auth-proxy.component';
import { CollaborationLogoutComponent } from './collaboration-logout.component';

const routes: Routes = [
	{
		path: RoutesManager.collaboration,
		loadChildren: async () => (await import('./assessments/assessments.module')).CollaborationAssessmentsModule,
	},
	{
		path: RoutesManager.collaborationAuthProxy,
		component: CollaborationAuthProxyComponent,
	},
	{
		path: RoutesManager.collaborationLogout,
		component: CollaborationLogoutComponent,
	},
	{
		path: '**',
		redirectTo: RoutesManager.pageNotFound,
		pathMatch: FullPathMatch
	},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class CollaborationRoutingModule {}
