import { Injectable } from "@angular/core";
import { AiAssessmentCustomControlUpdateEvent, AiAssessmentFormControlProperties, AiAssessmentLogicByControl, AiAssessmentLogicDependecyOperation, AiAssessmentLogicDependecyOperationArgs, AiAssessmentLogicOptionsBySection, AiAssessmentValueByControl, AiAssessmentsFormControl } from "../../models/ai-assessments.interface";
import { AiAssessmentFormControlLogicField, AiAssessmentFormControlLogicType, AiAssessmentsFormControlTypeEnum } from "../../models/ai-assessments.enum";
import { DropdownOption } from "src/app/shared/mine-dropdown/mine-dropdown.interface";
import { FormGroup } from "@angular/forms";
import { AddItemConfig } from "src/app/shared/mine-chips-input/mine-chips-input.interface";
import { LOGIC_DEFAULT_VALUES } from "../../ai-assessments-templates/ai-assessments-template-form/ai-assessments-template-form.constant";

const CHECK_FUNCTION_BY_LOGIC_TYPE = {
  [AiAssessmentFormControlLogicType.HasExactly]: (questionValues: string[], targetValues: string[]) => {
    return questionValues.every(value => targetValues.includes(value)) && questionValues.length === targetValues.length;
  },
  [AiAssessmentFormControlLogicType.HasSome]: (questionValues: string[], targetValues: string[]) => {
    return questionValues.some(value => targetValues.includes(value));
  },
};

@Injectable({ providedIn: 'root' })
export class AiAssessmentsLogicHelper {

  valueByControl: AiAssessmentValueByControl = {};

  buildFormOptions(sections: AiAssessmentsFormControl[], addItemConfig: AddItemConfig): AiAssessmentLogicOptionsBySection {
    const logicOptionsBySection: AiAssessmentLogicOptionsBySection = {};
    sections.map(({ controls }, sectionIndex) => {
      if (!logicOptionsBySection[sectionIndex]) {
        logicOptionsBySection[sectionIndex] = {
          availableFieldsById: {},
          fieldPropertiesById: {},
        };
      }
      const availableFields: DropdownOption[] = [];
      controls.forEach(({
        type,
        id,
        label,
        options,
        multiple: multipleUndefinedOrFalse,
      }) => {
        logicOptionsBySection[sectionIndex].availableFieldsById[id] = [...availableFields];

        if (type !== AiAssessmentsFormControlTypeEnum.Dropdown) {
          return;
        }
        const optionLabelsById = options?.reduce((byId, { id, value }) => {
          byId[id] = value;
          return byId;
        }, {} as Record<string, string>) ?? {};

        availableFields.push({ id, value: label });
        const multiple = multipleUndefinedOrFalse !== undefined && multipleUndefinedOrFalse !== false;
        logicOptionsBySection[sectionIndex].fieldPropertiesById[id] = {
          label,
          options,
          optionLabelsById,
          multiple,
          addItemConfig: {
            ...addItemConfig,
            dropDownOptions: [...options],
          },
        };
      });
    });
    return logicOptionsBySection;
  }

  buildLogicByControl(sections: AiAssessmentsFormControl[], currentLogicByControl?: AiAssessmentLogicByControl): AiAssessmentLogicByControl {
    const logicByControl: AiAssessmentLogicByControl = {};
    sections.forEach(
      ({ controls }) => controls.forEach(
        ({ id, logic: savedLogic }) => {
          const logic = currentLogicByControl?.[id] ?? savedLogic;
          if (!logic) {
            return;
          }
          logicByControl[id] = logic;
        }));
    return logicByControl;
  }

  initStateByControl(sections: AiAssessmentsFormControl[], form?: FormGroup<any>): Record<string, boolean> {
    this.valueByControl = this.buildValueByControl(sections, form);
    return this.buildStateByControl(sections);
  }

  buildStateByControl(sections: AiAssessmentsFormControl[]): Record<string, boolean> {
    const stateByControl: Record<string, boolean> = {};
    sections.forEach(
      ({ controls }) => controls.forEach(
        (control) => {
          stateByControl[control.id] = this.shouldControlDisplay(control, stateByControl);
        }));
    return stateByControl;
  }

  updateStateControlStateByKey(
    sections: AiAssessmentsFormControl[],
    controlId: string,
    updatedValue: string
  ): Record<string, boolean> {
    this.valueByControl[controlId] = updatedValue;
    return this.buildStateByControl(sections);
  }

  updateLogicDependencies(
    currentLogicFields: AiAssessmentLogicByControl,
    operation: AiAssessmentLogicDependecyOperation,
    operationArgs: AiAssessmentLogicDependecyOperationArgs = [],
  ): AiAssessmentLogicByControl {
    return operation(currentLogicFields, ...operationArgs);
  }

  onCustomControlUpdate(
    currentLogicFields: AiAssessmentLogicByControl,
    updateEvent: AiAssessmentCustomControlUpdateEvent,
    sections: AiAssessmentsFormControl[],
    logicOptionsBySection: AiAssessmentLogicOptionsBySection,
  ): AiAssessmentLogicByControl {
    return this.selectCustomControlOperation(
      currentLogicFields, 
      updateEvent, 
      sections, 
      logicOptionsBySection
    );
  }

  mergeSectionLogic(sections: AiAssessmentsFormControl[], currentLogicFields: AiAssessmentLogicByControl): AiAssessmentsFormControl[] {
    return sections.map(section => {
      const controls = section.controls.map(control => {
        if (currentLogicFields[control.id]) {
          return { ...control, logic: currentLogicFields[control.id] };
        }
        return control;
      });
      return { ...section, controls };
    });
  }

  private selectCustomControlOperation(
    currentLogicFields: AiAssessmentLogicByControl,
    { control, prevControl }: AiAssessmentCustomControlUpdateEvent,
    sections: AiAssessmentsFormControl[],
    logicOptionsBySection: AiAssessmentLogicOptionsBySection,
  ): AiAssessmentLogicByControl {
    if (!control) {
      return this.reset(currentLogicFields, control.name);
    }
    if (control.options !== prevControl.options) {
      const newOptions = [...control.options];
      return this.updateOptions(currentLogicFields, sections, logicOptionsBySection, control.id, newOptions, null);
    }
    return currentLogicFields;
  }

  private buildValueByControl(sections: AiAssessmentsFormControl[], form?: FormGroup<any>): AiAssessmentValueByControl {
    const valueByControl: AiAssessmentValueByControl = {};
    sections.forEach(
      ({ name: sectionName, controls }) => controls.forEach(
        ({ id, name: controlName, value }) => {
          const formValue = form?.get(sectionName)?.get(controlName)?.value;
          valueByControl[id] = formValue || value || "";
        }));
    return valueByControl;
  }

  private shouldControlDisplay(control: AiAssessmentsFormControl, stateByControl: Record<string, boolean>): boolean {
    const { logic } = control;
    if (!logic) {
      return true;
    }

    const targetKey = logic.questionId;
    const targetValue = this.valueByControl[targetKey];
    const targetIsHidden = stateByControl[targetKey] === false;

    if (targetValue === undefined) {
      return true;
    }

    if (targetIsHidden) {
      return false;
    }

    const targetValues = this.buildTargetValues(targetValue);
    if (targetValues === undefined || !(targetValues instanceof Array || !logic.questionValue)) {
      return false;
    }

    const questionValues = logic.questionValue.split(',');
    const checkFunction = CHECK_FUNCTION_BY_LOGIC_TYPE[logic.type];
    return checkFunction(questionValues, targetValues);
  }

  private buildTargetValues(targetValue: string | string[] | DropdownOption): string[] {
    const targetValueAsDropdownOption = targetValue as DropdownOption;
    if (targetValueAsDropdownOption?.id) {
      return [targetValueAsDropdownOption?.id];
    }
    if (typeof targetValue === 'string') {
      return targetValue.split(',');
    }
    return targetValue as string[];
  }

  findControlById(id: string, sections: AiAssessmentsFormControl[]): AiAssessmentFormControlProperties {
    let controlFound: AiAssessmentsFormControl | null = null;
    let sectionFound: AiAssessmentsFormControl | null = null;
    let controlIndexFound: number | null = null;
    let sectionIndexFound: number | null = null;

    sections.forEach((section, sectionIndex) => section.controls.forEach((control, controlIndex) => {
      if (control.id !== id) {
        return;
      }
      controlFound = control;
      sectionFound = section;
      controlIndexFound = controlIndex;
      sectionIndexFound = sectionIndex;
    }));
    
    return { 
      control: controlFound,
      section: sectionFound,
      controlIndex: controlIndexFound,
      sectionIndex: sectionIndexFound,
    };
  }

  updateOptions(
    currentLogicFields: AiAssessmentLogicByControl,
    sections: AiAssessmentsFormControl[],
    logicOptionsBySection: AiAssessmentLogicOptionsBySection,
    controlId: string,
    newOptions: DropdownOption[],
    newValue?: string,
  ): AiAssessmentLogicByControl {
    Object.entries(currentLogicFields).forEach(([id, logic]) => {
      if (logic?.questionId === controlId) {
        if (!logic || !logic.questionValue?.length) {
          return;
        }
        const { sectionIndex } = this.findControlById(controlId, sections);
        const multiple = logicOptionsBySection[sectionIndex]?.fieldPropertiesById?.[controlId]?.multiple;
        const options = logic.questionValue.split(',');

        const updatedOptions = multiple
          ? options.map((option) => {
            const found = newOptions.map(({ value }) => value).some(value => value === option);
            if (found) {
              return option;
            }
            return newValue;
          })
          : options.filter((option) => newOptions.some(({ id }) => id === option));

        const questionValue = updatedOptions.filter(option => !!option).join(',');
        currentLogicFields[id] = { ...logic, questionValue };
      }
    });
    return currentLogicFields;
  }

  toggleLogic(
    currentLogicFields: AiAssessmentLogicByControl,
    controlId: string,
    hasLogic: boolean
  ): AiAssessmentLogicByControl {
    if (hasLogic) {
      currentLogicFields[controlId] = { ...LOGIC_DEFAULT_VALUES };
    } else {
      delete currentLogicFields[controlId];
    }
    return currentLogicFields;
  }

  updateFieldValue(
    currentLogicFields: AiAssessmentLogicByControl,
    controlId: string,
    field: string,
    newValue: string,
    multiple: boolean
  ): AiAssessmentLogicByControl {
    const currentLogic = currentLogicFields[controlId];
    if (
      (!newValue && newValue !== '') ||
      !currentLogic) {
      return currentLogicFields;
    }
    currentLogicFields[controlId] = {
      ...currentLogicFields[controlId],
      [field]: newValue,
    };
    if (
      field === AiAssessmentFormControlLogicField.QuestionId &&
      currentLogic?.questionId !== newValue
    ) {
      currentLogicFields[controlId].questionValue = '';
      if (!multiple) {
        currentLogicFields[controlId].type = AiAssessmentFormControlLogicType.HasExactly;
      }
    }
    return currentLogicFields;
  }

  reset(
    currentLogicFields: AiAssessmentLogicByControl,
    controlId: string | string[],
  ): AiAssessmentLogicByControl | undefined {
    const controlIds = controlId instanceof Array ? controlId : [controlId];
    Object.entries(currentLogicFields).forEach(([id, logic]) => {
      if (!controlIds.includes(logic.questionId)) {
        return;
      }
      currentLogicFields[id] = { ...LOGIC_DEFAULT_VALUES };
    });
    return currentLogicFields;
  }

  resetValue(
    currentLogicFields: AiAssessmentLogicByControl,
    controlId: string,
    multiple: boolean,
  ): AiAssessmentLogicByControl | undefined {
    Object.entries(currentLogicFields).forEach(([id, logic]) => {
      if (logic?.questionId !== controlId) {
        return;
      }
      currentLogicFields[id].questionValue = '';
      if (!multiple) {
        currentLogicFields[id].type = AiAssessmentFormControlLogicType.HasExactly;
      }
    });
    return currentLogicFields;
  }
}