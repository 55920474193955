import { Injectable, Signal } from "@angular/core";
import { Observable } from "rxjs";
import { DsrLocalizationStore } from "./dsr-localization.store";

@Injectable({
    providedIn: 'root'
})
export class DsrLocalizationQuery {
    
    constructor(
        private store: DsrLocalizationStore,
    ) { }

    setLoading(loading: boolean): void {
        this.store.set({ 'loading': loading });
    }

    getLoading(): Signal<boolean | undefined> {
        return this.store.signal('loading');
    }

    selectLoading(): Observable<boolean | undefined> {
        return this.store.select('loading');
    }

    setLanguagesMap(languageMap: Map<string, string>): void {
        this.store.set( {'languagesMap': languageMap });
    }

    getLanguagesMap(): Signal<Map<string, string>> {
        return this.store.signal('languagesMap');
    }

    selectLanguagesMap(): Observable<Map<string, string>> {
        return this.store.select('languagesMap'); 
    }
}