import { Injectable } from "@angular/core";
import { RxState } from "@rx-angular/state";
import { DsrTemplatesState } from "./dsr-templates-state.interface";
import { DsrShallowTemplate } from "../../../../api/models/dsr/dsr-templates.interface";

@Injectable({
    providedIn: 'root'
})
export class DsrTemplatesStore extends RxState<DsrTemplatesState> {
    private readonly initState = {
        loading: true,
        templates: new Map<string, DsrShallowTemplate>(),
    } as DsrTemplatesState;

    constructor() {
        super();
        this.set({...this.initState}); // set the initial state of the store
    }
}