<label class="input__header" [attr.for]="id">
    <ng-content select="[mine-input-label]"></ng-content>
    <ng-container *ngIf="required">
        <span class="input__header--required" [ngStyle]="{ backgroundColor: requiredBgColor }">&nbsp;*</span>    
    </ng-container>
</label>

<input #input [attr.id]="id" class="input__element" [ngClass]="{'input__element--error': !!error }" [type]="type"
    [ngStyle]="{ backgroundColor: inputBgColor }" [value]="value" [placeholder]="placeholder" 
    [disabled]="disabled" (input)="onChange($event.target.value)"
    (blur)="onFocus(inputFocus.Out)" (focus)="onFocus(inputFocus.In)">

<div class="input__bottom-text">
    <span *ngIf="!!error; else showHint" class="input__error">{{error}}</span>
    <span class="input__right-text"><ng-content select="[mine-input-right-text]"></ng-content></span>

    <ng-template #showHint>
        <span class="input__hint"><ng-content select="[mine-input-hint]"></ng-content></span>
    </ng-template>
</div>

