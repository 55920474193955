import { Pipe, PipeTransform } from '@angular/core';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { CollaboratorsChip } from 'src/app/ai-assessments/dialogs/ai-assessment-share-dialog/interface/collaborator';
import { AiAssessmentInstance, AiAssessmentPage } from 'src/app/api/models/ai-assessments/ai-assessments.interface';
import { AiAssessmentCollaborationState } from '../models/ai-assessments.enum';

@Pipe({
    standalone: true,
    name: 'collaboratorsChip'
})
export class CollaboratorsChipPipe implements PipeTransform {

    constructor(
        private contentPipe: ContentPipe,
    ) {}

    transform(instance: AiAssessmentInstance | Partial<AiAssessmentPage>): CollaboratorsChip {
        switch (instance?.collabState) {
            case AiAssessmentCollaborationState.Shared:
                return {
                    text: this.contentPipe.transform('ai-assessments-collaboration.sharedBadge'),
                    backgroundColor: 'var(--mine-grey-light-2)',
                    textColor: 'var(--mine-blue-dark)',
                    iconColor: 'var(--mine-grey-normal)'
                };
            case AiAssessmentCollaborationState.Submitted:
                return {
                    text: this.contentPipe.transform('ai-assessments-collaboration.completedBadge'),
                    backgroundColor: 'var(--avatar-blue-dark)',
                    textColor: 'var(--mine-white)',
                    iconColor: 'var(--mine-white)'
                };
            default:
                return null;
        }
    }
}