import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';

import { LeavePageAbstract } from './leave-page.interface';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { DialogsManagerService } from 'src/app/services/dialogs-manager.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class LeavePageGuard implements CanDeactivate<LeavePageAbstract>, OnDestroy {
  private readonly defaultConfig = {
    title: this.contentPipe.transform('common.leavePageTitle'),
    okBtn: this.contentPipe.transform('common.leave'),
    cancelBtn: this.contentPipe.transform('common.stay'),
    showXBtn: true,
  };

  private currentComponent: LeavePageAbstract | null = null;

  constructor(
    private contentPipe: ContentPipe,
    private dialogService: DialogsManagerService
  ) {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  canDeactivate(
    component: LeavePageAbstract,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    this.currentComponent = component; // Track the current component
    return this.showWarningDialog(component);
  }

  private handleBeforeUnload = (event: BeforeUnloadEvent): void => {
    if (!this.shouldAllowNavigation(this.currentComponent)) {
      event.preventDefault();
      event.returnValue = ''; // Triggers the browser's default confirmation dialog
    }
  };

  private shouldAllowNavigation(component: LeavePageAbstract | null): boolean {
    if (!component) {
      return true;
    }

    const someActiveExtraGuards = component.extraGuards?.some((guard) => guard()) || false;
    return component.skipGuard && !someActiveExtraGuards;
  }

  private showWarningDialog(component: LeavePageAbstract): Observable<boolean> | boolean {
    if (this.shouldAllowNavigation(component)) {
      return true;
    }

    const dialogRef = this.dialogService.openDialogCommon(
      ConfirmationDialogComponent,
      component.leavePageConfig ?? this.defaultConfig
    );

    return dialogRef.afterClosed$;
  }

  ngOnDestroy(): void {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  }
}
