export enum CustomValueTypeEnum {
	BusinessUnit = 'BusinessUnit',
	LegalBasis = 'LegalBasis',
	TransferMechanism = 'TransferMechanism',
	Mitigation = 'Mitigation',
	DataSubject = 'DataSubject',
	PaEntity = 'PaEntity',
	DataType = 'DataType',
	User = 'User',
	DecommissionJustification = 'DecommissionJustification',
}