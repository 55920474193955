import { Injectable } from '@angular/core';
import { LoggerService } from '../logger/logger.service';
import { environment } from 'src/environments/environment';
import { AnalyticsEvent } from '../analytics/analytics-event';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';
import { UserProperties } from '../analytics/user-properties';
import { Profile } from '../profile/models/profile.interface';

declare global {
	interface Window { Intercom: any; }
}

@Injectable({
	providedIn: 'root'
})
export class IntercomService {
	private readonly loggerName: string = 'IntercomService';

	private disabled: boolean = false;
	private hidden: boolean = true;

	private isVisible = new BehaviorSubject<boolean>(false);
	isVisible$ = this.isVisible.asObservable();

	constructor(private logger: LoggerService, private breakpointObserver: BreakpointObserver) {}

	disable(): void {
		this.logger.warn(this.loggerName, 'disabling');
		this.disabled = true;
	}

	init(profile: Profile): void {
		this.logger.trace(this.loggerName, 'init()');
		
		// we always load Intercom for product tours and announcements
		
		try {
			// override Intercom visibility settings only if on Mobile
			this.hidden = window.innerWidth <= 600 ? true : undefined;
			window.Intercom('boot', { app_id: environment.intercom.appId, hide_default_launcher: this.hidden });
			
			this.isVisible.next(!this.hidden);
			this.listenToLayoutChanges();
			this.boot(profile);
		}
		catch (e) {
			this.logger.error(this.loggerName, 'Exception while initializing Intercom: ' + e);
			this.disable();
		}
	}

	listenToLayoutChanges() : void {
		if(this.disabled) {
			return;
		}

		this.breakpointObserver.observe([
			'(max-width: 600px)'
			  ]).subscribe(result => {
				let shouldHide = result.matches ? true : undefined;
				if(shouldHide !== this.hidden) {
					this.hidden = shouldHide;
					this.isVisible.next(!this.hidden);
					this.updateWidgetVisibility();
				}
			  });
	}

	private boot(profile: Profile): void {
		if(this.disabled) {
			this.logger.trace(this.loggerName, 'disabled');
			return;
		}
		
		this.logger.trace(this.loggerName, 'boot()');
		let name = profile.firstName;
		if(profile.lastName !== undefined) {
			name = `${profile.firstName} ${profile.lastName}`;
		}

		this.logger.debug(this.loggerName, `boot() with hide_default_launcher=${this.hidden}`);
		window.Intercom('boot', {
			app_id: environment.intercom.appId,
			user_id: profile.userId,
			role: profile.role,
			company_id: profile.companyId,
			name: name,
			email: profile.email,
			created_at: profile.memberSince,
			user_hash: profile.intercomUserHash,
			plan: profile.planDetails?.plan,
			hide_default_launcher: this.hidden,
			company: {
				id: profile.companyId,
				name: profile.companyName,
				plan: profile.planDetails?.plan
			}
		});
	}

	trackEvent(event: AnalyticsEvent): void {
		if(this.disabled) {
			this.logger.trace(this.loggerName, 'disabled');
			return;
		}

		// Intercom limits the number of events
		//this.logger.trace(this.loggerName, `trackEvent(${event.eventCategory})`);
		//window.Intercom('trackEvent', event.eventCategory);
	}

	// This request is throttled after 20 calls/30 mins. reloading the page resets the quota.
	update(): void {
		if(this.disabled) {
			this.logger.trace(this.loggerName, 'disabled');
			return;
		}

		this.logger.trace(this.loggerName, 'update()');
		window.Intercom('update');
	}

	updateWidgetVisibility(): void {
		this.logger.debug(this.loggerName, `updateWidgetVisibility(): hidden=${this.hidden}`);
		window.Intercom('update', {
			hide_default_launcher: this.hidden
		});
	}

	// This request is throttled after 20 calls/30 mins. reloading the page resets the quota.
	updateUserProperties(userProperties: UserProperties): void {
		if(this.disabled) {
			this.logger.trace(this.loggerName, 'disabled');
			return;
		}

		this.logger.trace(this.loggerName, 'updateInfo()');
		window.Intercom('update', {
			num_open_requests: userProperties.OpenRequests,
			num_closed_requests: userProperties.ClosedRequests,
			num_total_requests: userProperties.TotalRequests,
			num_unverified_requests: userProperties.UnverifiedRequests,

			is_verified: userProperties.IsVerified,
			company_id: userProperties.CompanyId,
			company_name: userProperties.CompanyName,
			role: userProperties.Role,

			plan: userProperties.PlanName,
			plan_modified_at: userProperties.PlanLastModifiedAt,

			identity_provider: userProperties.IdentityProvider,
			closed_in_last_30_days: userProperties.ClosedTicketsInLast30Days,

			connected_to_mine: userProperties.ConnectedToMineStatus,

			company: {
				id: userProperties.CompanyId,
				name: userProperties.CompanyName,
				plan: userProperties.PlanName
			}
		});
	}

	hide(): void {
		this.logger.trace(this.loggerName, 'hide()');
		window.Intercom('hide');
	}

	shutdown(): void {
		this.logger.trace(this.loggerName, 'shutdown()');
		window.Intercom('shutdown');
	}

	show(): void {
		if(this.disabled) {
			this.logger.warn(this.loggerName, 'disabled');
			return;
		}

		this.logger.trace(this.loggerName, 'show()');
		window.Intercom('show');
	}
}
