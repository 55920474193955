import { Inject, Injectable } from "@angular/core";
import { RxState } from "@rx-angular/state";
import { rxActions } from "@rx-angular/state/actions";
import { catchError, combineLatest, delay, map, of, shareReplay } from "rxjs";

import { LoggerService } from "src/app/logger/logger.service";
import { AiAssessmentsState } from "../models/ai-assessments-state.interface";
import { AiAssessmentActions } from "../models/ai-assessment-actions.interface";
import { BaseApiClientAiAssessmentsService } from "src/app/api/base-api-client-ai-assessments.service";
import { API_CLIENT_ASSESSMENTS_SERVICE } from "src/app/api/injectors/api-client-ai-assessments.token";

@Injectable({
    providedIn: 'root'
})
export class AiAssessmentsStore extends RxState<AiAssessmentsState> {

    private readonly loggerName: string = 'AiAssessmentsStore';
    
    private readonly initState = {
        empty: undefined,
        loading: true,
        disabled: false,
        templates: undefined,
        assessments: undefined,
    } as AiAssessmentsState;

    private readonly _templates$ = this.apiClientAiAssessmentsService.getTemplates().pipe(
        shareReplay(),
        delay(100),
        map(templates => new Map(templates.map(template => ([template.id, template])))),
        catchError(err => {
            console.error(err);
            this.logger.error(this.loggerName, err);
            return of(new Map());
        })
    );
    
    private readonly _assessments$ = this.apiClientAiAssessmentsService.getInstances().pipe(
        shareReplay(),
        delay(100),
        map(assessments => new Map(assessments.map(assessment => ([assessment.id, assessment])))),
        catchError(err => {
            console.error(err);
            this.logger.error(this.loggerName, err);
            return of(new Map());
        })
    );

    public actions = rxActions<AiAssessmentActions>();

    constructor(
        private logger: LoggerService,
        @Inject(API_CLIENT_ASSESSMENTS_SERVICE) private apiClientAiAssessmentsService: BaseApiClientAiAssessmentsService,
    ) {
        super();
        this.set(this.initState);
        this.connect('templates', this._templates$);
        this.connect('assessments', this._assessments$);
        this.connect('loading',  combineLatest([this._templates$, this._assessments$]).pipe(
            map(() => false)
        ));
        this.connect('empty', this._assessments$.pipe(
            map(assessments => !assessments?.size)
        ));
    }
}