import { Injectable } from '@angular/core';
import { UserPropertiesStore } from 'src/app/analytics/user-properties.store';
import { CollaborationTokenStoreService } from './collaboration-token-store.service';
import { AuthProviders } from 'src/app/api/models/auth/auth-providers';
import { UserPropertiesManager } from 'src/app/analytics/user-properties-manager.interface';

@Injectable({
	providedIn: 'root'
})
export class CollaborationUserPropertiesService implements UserPropertiesManager {
    private readonly loggerName = 'CollaborationUserPropertiesService';

    constructor(
        private tokenStore: CollaborationTokenStoreService,
        private userPropertiesStore: UserPropertiesStore
    ) { }

    public init(): void {
        const companyId = this.tokenStore.getCompanyId();
        const email = this.tokenStore.getEmail();

        this.userPropertiesStore.update({
            Email: email,
            Id: email, 
            CompanyId: companyId,
            IdentityProvider: AuthProviders.auth0
        });
    }
}