import { CanMatchFn, Route, Router, UrlSegment, UrlTree } from "@angular/router";
import { inject } from "@angular/core";

import { FeatureFlagQuery } from "../../feature-flag/state/feature-flag.query";
import { FeatureFlags } from "../../api/models/profile/profile-feature-flags.enum";
import { RoutesManager } from "../models/routes.interfaces";
import { LoggerService } from "../../logger/logger.service";

const loggerName: string = 'RedirectTemplatesGuard';

const RedirectTemplatesGuardFn = (route: Route, segments: UrlSegment[]): boolean | UrlTree => {
  const featureFlagQuery = inject(FeatureFlagQuery);
  return featureFlagQuery.getFlag(FeatureFlags.DevDSRCustomTemplates) ? RedirectTemplates(segments) : true;
};

const RedirectTemplates = (segments: UrlSegment[]): UrlTree => {
  const router = inject(Router);
  const logger = inject(LoggerService);

  const updatedRoute = `${RoutesManager.dsr_setup}/${RoutesManager.dsr_templates}`;
  logger.debug(loggerName, `Stored redirectUrl: ${updatedRoute}`);
  return router.parseUrl(updatedRoute);
};

export const RedirectTemplatesGuard: CanMatchFn = RedirectTemplatesGuardFn;