import { catchError, map, tap, Observable, BehaviorSubject, of } from 'rxjs';
import { PaginationResponse, PaginatorPlugin } from '@datorama/akita';
import { Inject, Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';

import { environment } from 'src/environments/environment';
import { SystemsService } from 'src/app/systems/state/systems.service';
import { ManageEmployeeInSystem } from '../../systems/models/systems.interface';
import { ApiClientDataMappingService } from 'src/app/api/api-client-data-mapping.service';
import { AuthProviders } from 'src/app/api/models/auth/auth-providers';
import { SentenceCasePipe } from 'src/app/shared/pipes/sentence-case.pipe';
import { EmployeesQuery } from './employees.query';
import { EMPLOYEES_PAGINATOR } from './employees-paginator';
import { EmployeesState, EmployeesStore } from './employees.store';
import { CallbackArgs } from 'src/app/api/models/auth/callback-args';
import { LoginCallbackResponse } from 'src/app/auth/login-callback-response';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { DiscoveryToolsService } from 'src/app/discovery-tools/discovery-tools.service';
import { DataMappingAuthResponse, Employee, EmployeeDataMapping, EmployeeSystem } from 'src/app/api/models/data-mapping/data-mapping.interface';
import { Department, EmployeesFilter, EmployeesSearchResult, EmployeesSystemAdditionalData, ManageEmployee, SearchEmployee } from 'src/app/api/models/employees/employees.intrface';
import { ManageAsyncActionsEmployeesService } from 'src/app/employees/employees-managment/manage-async-actions-employees.service';
import { DiscoveryErrorDescription } from 'src/app/discovery-tools/models/discovery-auth-errors.enum';
import { EmployeesAsyncActions } from 'src/app/heavy-action-dialog/heavy-action-dialog.enum';
import { RoutesManager } from 'src/app/shared/models/routes.interfaces';
import { MineSort } from 'src/app/shared/mine-sort/mine-sort.interface';
import { SystemEmployeeEvidenceResponse } from 'src/app/api/models/systems/systems.interface';
import { BaseApiClientSystemsService } from 'src/app/api/base-api-client-systems.service';
import { API_CLIENT_SYSTEMS_SERVICE } from 'src/app/api/injectors/api-client-systems-service.token';
import { BaseApiClientEmployeesService } from 'src/app/api/base-api-client-employees.service';
import { API_CLIENT_EMPLOYEES_SERVICE } from 'src/app/api/injectors/api-client-employees.token';

@Injectable({
	providedIn: 'root'
})
export class EmployeesService  {
	private readonly loggerName: string = 'DataMappingEmployeeService';

	private employeesCountSubject = new BehaviorSubject<number>(0);
	employeesCount$ = this.employeesCountSubject.asObservable();

	constructor(
		private employeesQuery: EmployeesQuery,
		private employeesStore: EmployeesStore,
		private apiClientDataMappingService: ApiClientDataMappingService,
		private discoveryToolsService: DiscoveryToolsService,
		private systemsService: SystemsService,
		private sentenceCasePipe: SentenceCasePipe,
		private contentPipe: ContentPipe,
		@Inject(EMPLOYEES_PAGINATOR) public paginatorRef: PaginatorPlugin<EmployeesState>,
		@Inject(API_CLIENT_SYSTEMS_SERVICE) private apiClientSystemsService: BaseApiClientSystemsService,
		@Inject(API_CLIENT_EMPLOYEES_SERVICE) private apiClientEmployeesService: BaseApiClientEmployeesService,
		private manageAsyncActionsEmployeesService: ManageAsyncActionsEmployeesService,
	) {	}

	getAuthDataMapping(companyId: string, provider: AuthProviders, externalRedirect = false, rwc = true): Observable<DataMappingAuthResponse> {
		return this.apiClientDataMappingService.getAuthDataMapping(this.getAuthUrlForDataMapping(companyId, provider, externalRedirect, rwc));
	}

	getAuthUrlForDataMapping(companyId: string, provider: AuthProviders, externalRedirect = false, rwc = true, email?: string): string {
		const providerUrl = environment.dataMappingAuth.redirectUrl[provider];
		const redirectUrl = externalRedirect ? providerUrl.external : providerUrl.selfAdd;
		let url = `${this.sentenceCasePipe.transform(provider)}DataMappingAuth/${externalRedirect ? 'EmployeeLogin' : 'Login'}?rwc=${rwc}&companyId=${companyId}&redirect=${redirectUrl}`;
		if (email) {
			url = url.concat(`&invitedEmail=${email}`);
		}

		return url;
	}

	handleAuthCallback(params: ParamMap, externalRedirect = false, provider: AuthProviders): Observable<LoginCallbackResponse> {
		const callbackArgs = new CallbackArgs();
		callbackArgs.oAuthAuthorizationResponse = {
			code: params.get('code'),
			state: params.get('state'),
			error: params.get('error'),
			errorDescription: params.get('errorDescription'),
		};

		const providerUrl = environment.dataMappingAuth.redirectUrl[provider];
		callbackArgs.redirectUrl = externalRedirect ? providerUrl.external : providerUrl.selfAdd;
		return this.apiClientDataMappingService.handleAuthCallback(callbackArgs, provider);
	}

    getAdminInstallRedirectUrl(): Observable<string> {
        const callbackUrl = `${window.location.protocol}//${window.location.host}/${RoutesManager.discovery}/${RoutesManager.discoveryToolsEmail}MicrosoftCallback/`;
        return this.apiClientDataMappingService.getAdminInstallRedirectUrl(callbackUrl).pipe(map(res => res.redirectUrl));
    }

    getAzureConnectRedirectUrl(): Observable<string> {
        const callbackUrl = `${window.location.protocol}//${window.location.host}/${RoutesManager.discovery}/EntraCallback/`;
        return this.apiClientDataMappingService.getAzureConnectRedirectUrl(callbackUrl).pipe(map(res => res.redirectUrl));
    }

	getErrorText(err: DiscoveryErrorDescription): string {
		switch (err) {
			case DiscoveryErrorDescription.UserAlreadyConnected:
				return this.contentPipe.transform('data-mapping.userAlreadyConnected');
			case DiscoveryErrorDescription.DomainNotAssociated:
				return this.contentPipe.transform('data-mapping.notCompanyDomain');
			case DiscoveryErrorDescription.NotCompanyEmail:
				return this.contentPipe.transform('data-mapping.notCompanyEmail');
			default:
				return this.contentPipe.transform('data-mapping.somethingWentWrong');
		}
	}

	connectWorkspace(): Observable<string> {
		return this.discoveryToolsService.connectGoogleWorkspace();
	}

	getEmployeeSystemIds(employeeId: string): Observable<EmployeeSystem[]> {
		return this.apiClientSystemsService.getSystemsByEmployee(employeeId).pipe(
			map(res => res.employeeSystems ?? []),
			tap(res => this.employeesStore.update(employeeId, {systemsInEmployee: res})),
		);
	}

	inviteEmployee(employee: Employee): Observable<Employee> {
		this.employeesStore.setLoading(true);
		return this.apiClientEmployeesService.inviteEmployee(employee)
		.pipe(
			tap((res) => this.employeesQuery.updateStore(res as EmployeeDataMapping)),
			tap(() => this.employeesStore.setLoading(false)),
			catchError((err)=>{
				this.employeesStore.setLoading(false);
				throw err;
			})
		);
	}

	updateEmployeeInSystem(systemId: string, employeeInSystem: ManageEmployeeInSystem): Observable<void> {
		return this.apiClientSystemsService.manageEmployeeInSystem(systemId, employeeInSystem).pipe(
			tap(() => this.updateSystemAccountCount(systemId, employeeInSystem.isAssigned)),
		);
	}

	private updateSystemAccountCount(systemId: string, isAssigned: boolean): any {
		this.systemsService.updateAccountCount(systemId, isAssigned);
	}

	archiveEmployee(employeeId: string, isArchived: boolean): Observable<void> {
		return this.apiClientEmployeesService.manageEmployee({id: employeeId, isArchived} as ManageEmployee).pipe(
			tap(() => this.afterArchiveEmployee(employeeId, isArchived)),
		);
	}

	private afterArchiveEmployee(employeeId: string, isArchived: boolean): void {
		this.employeesStore.update(employeeId, {isArchived});
		this.manageAsyncActionsEmployeesService.setEmployeesAsyncAction(isArchived ?  EmployeesAsyncActions.ArchiveEmployee : EmployeesAsyncActions.UnArchiveEmployee);
		this.refreshEmployeesPaginatorData();
	}

	getEmployeesPage(page: number, perPage: number, filterBy: EmployeesFilter, sortBy: MineSort): Observable<PaginationResponse<EmployeeDataMapping>> {
		return this.apiClientEmployeesService.getPaginatedEmployees(page, perPage, filterBy, sortBy);
	}

	getEmployeesDepartments(): Observable<Department[]> {
		return this.apiClientEmployeesService.getEmployeesDepartments().pipe(
			map(response => response.departments)
		);
	}

	setEmployeesCount(count: number): void {
		this.employeesCountSubject.next(count);
	}

	getEmployeesCount(): number {
		return this.employeesCountSubject.value;
	}

	refreshEmployeesPaginatorData(): void {
		this.paginatorRef.clearCache();
		this.paginatorRef.refreshCurrentPage();
	}

	searchEmployee(search: SearchEmployee): Observable<EmployeesSearchResult> {
		return this.apiClientEmployeesService.searchEmployee(search);
	}

	getEmployeesByIds(employeeIds: string[]): Observable<EmployeeDataMapping[]> {
		if (employeeIds?.length) {
			return this.apiClientEmployeesService.getEmployeesByIds(employeeIds).pipe(
				map(res => res.data)
			);
		}
		return of([]);
    }

	getEmployeesSystemAdditionalData(systemId: string): Observable<EmployeesSystemAdditionalData> {
		return this.apiClientEmployeesService.getEmployeesSystemAdditionalData(systemId);
	}

	getEmployeeEvidence(systemId: string, employeeId: string): Observable<SystemEmployeeEvidenceResponse> {
		return this.apiClientSystemsService.getSystemEmployeesEvidence(systemId, employeeId);
	}
}
