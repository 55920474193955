import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ContentPipeModule } from 'src/app/services/content/content-pipe.module';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'under-maintencance',
  standalone: true,
  imports: [ContentPipeModule, NgOptimizedImage],
  templateUrl: './under-maintencance.component.html',
  styleUrl: './under-maintencance.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnderMaintencanceComponent {
  assetsUrl = environment.assets.url;

}
