<df-messenger *ngIf="isLoggedIn$ | async"
  [attr.location]="googleChatbot.location"
  [attr.project-id]="googleChatbot.projectId"
  [attr.agent-id]="googleChatbot.agentId"
  [attr.language-code]="googleChatbot.languageCode"
  [attr.max-query-length]="googleChatbot.maxQueryLength"
  [attr.allow-feedback]="googleChatbot.allowFeedback">

  <df-messenger-chat-bubble 
    [attr.chat-icon]="chatbotIcon" 
    [attr.chat-title]="chatbotTitle">
  </df-messenger-chat-bubble>
</df-messenger>