import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { CollaborationAppModule } from './app/collaboration/collaboration.module';
import { RoutesManager } from './app/shared/models/routes.interfaces';

if (environment.production) {
  enableProdMode();
}

// Determine which module to bootstrap based on the URL
const isCollaborationRoute = () => {
  return location.pathname.startsWith(`/${RoutesManager.collaboration}/`)
    || location.pathname.startsWith(`/${RoutesManager.collaborationLogout}`)
    || location.pathname.startsWith(`/${RoutesManager.collaborationAuthProxy}`);
};

// Dynamically bootstrap the appropriate module
const bootstrapModule = isCollaborationRoute() ? CollaborationAppModule : AppModule;

platformBrowserDynamic().bootstrapModule(bootstrapModule)
  .catch(err => {
    console.error(err);

    // report error to user.
    // TODO: Make this pretty
    const errorNode = document.createElement('p');
    errorNode.id = 'main-error';
    errorNode.innerText = 'Oops something went wrong. Please share this with our tech support nerds: ' + (err?.message !== undefined ? err.message : err);
    document.body.appendChild(errorNode);
  });
