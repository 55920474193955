import { Pipe, PipeTransform } from '@angular/core';
import { LocalizationOrigin } from 'src/app/dsr/dsr-setup/dsr-localization/models/localization.enum';
import { ContentPipe } from 'src/app/services/content/content.pipe';

@Pipe({
  standalone: true,
  name: 'localeCodeToDisplayName'
})
export class LocaleCodeToDisplayNamePipe implements PipeTransform {
 
  localeMap = new Map<string, string>();

  //we are making this devisiotn until there will be onw localization for dsr
  templateLocaleMap = new Map<string, string>();

  constructor(
    private contentPipe: ContentPipe,
  ) {
    const supportedLanguages = this.contentPipe.transform('localization-languages.supportedLanguages');
    const templateSupportedLanguages = [...this.contentPipe.transform('templates-localization.languages'), this.contentPipe.transform('templates-localization.defaultEnglish')];
    supportedLanguages.forEach(lang => this.localeMap.set(lang.localizationCode, lang.nameForDisplay));
    templateSupportedLanguages.forEach(lang => this.templateLocaleMap.set(lang.localizationCode, lang.nameForDisplay));
  }

  transform(code: string, origin?: LocalizationOrigin): string {
    if (origin === LocalizationOrigin.DsrTemplates) {
      return this.templateLocaleMap.get(code);
    } else {
      return this.localeMap.get(code);
    }
  }

}
