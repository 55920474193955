import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';

import { environment } from "src/environments/environment";
import { CollaborationTokenStoreService } from "./collaboration-token-store.service";
import { GoogleAnalyticsService } from "src/app/analytics/google-analytics.service";

@Injectable({
    providedIn: "root"
})
export class CollaborationHttpHeadersInterceptor implements HttpInterceptor {

    constructor(
        private analytics: GoogleAnalyticsService,
        private tokenStore: CollaborationTokenStoreService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headers = this.setCommonHeaders(req);
        const addWithCradentials = req.url.startsWith(`${environment.api.accessPointUrl}`);
        
        if (addWithCradentials) {
            const accessToken = this.tokenStore.getAccessToken();
            const companyId = this.tokenStore.getCompanyId();
            const email = this.tokenStore.getEmail();

            if (companyId) {
                headers = headers.set('CompanyId', companyId);
            }
            if (email) {
                headers = headers.set('CollaboratorEmail', email);
            }
            if (accessToken) {
                headers = headers.set('Authorization', `Bearer ${accessToken}`);
            }

            const requestWithHeaders = req.clone({
                headers,
                withCredentials: addWithCradentials
            });

            return next.handle(requestWithHeaders);
        }

        return next.handle(req.clone({ headers }));
    }

    private setCommonHeaders(req: HttpRequest<any>): HttpHeaders {
        let headers = req.headers;
        headers = headers.set('session-id', this.analytics.getSessionId());

        // Only set Content-Type header for non-FormData requests
        if (!(req.body instanceof FormData)) {
            headers = headers.set('Content-Type', 'application/json; charset=utf-8');
        }
        
        return headers;
    }
}
