import { Injectable } from "@angular/core";
import { combineLatest, first, map, Observable, switchMap, filter, throwError, catchError, EMPTY } from "rxjs";

import { HttpClientHelperService } from "./http-client-helper.service";
import {
    AiAssessmentAttachment,
    AiAssessmentInstance,
    AiAssessmentLockStatus,
    AiAssessmentPage, AssessmentTemplatePageResponse,
    AssessmentTemplateRequest,
    AssessmentTemplateResponse
} from "./models/ai-assessments/ai-assessments.interface";
import { AiAssessmentsV2ParserService } from "../ai-assessments/services/ai-assessments-v2-parser.service";
import { CustomFieldsQuery } from "../company-settings/state/custom-fields/custom-fields.query";
import { CustomValuesQuery } from "../company-settings/state/custom-values/custom-values.query";
import { AiAssessmentStatusEnum } from "../ai-assessments/models/ai-assessments.enum";

@Injectable({
  	providedIn: 'root'
})
export class BaseApiClientAiAssessmentsService {
    protected readonly instancesController: string = 'assessment/v2/assessment-page';
    protected readonly templatesController: string = 'assessment/v2/template-page';

    constructor(
        protected httpClientHelper: HttpClientHelperService,
        protected customFieldsQuery: CustomFieldsQuery,
        protected customValuesQuery: CustomValuesQuery,
        protected aiAssessmentsV2ParserService: AiAssessmentsV2ParserService
	) {}

    getInstances(): Observable<AiAssessmentInstance[]> {
        return combineLatest([
            this.customFieldsQuery.selectLoading(),
            this.customValuesQuery.selectLoading(),
        ]).pipe(
            map(loaders => loaders.every(loader => !loader)),
            filter(loaded => loaded),
            switchMap(() =>
                this.httpClientHelper.invokeGetAuth<AiAssessmentPage[]>(`${this.instancesController}`)
            ),
            first(),
            map((pages: AiAssessmentPage[]) => {
                try {
                    return pages.map(page => this.aiAssessmentsV2ParserService.parsePageResponseToInstanceV1(page));
                }
                catch (parseError) {
                    throw parseError;
                }
            }),
            catchError((error) => {
                return throwError(() => error);
            })
        );
    }

    getInstanceById(id: string, lean = false): Observable<AiAssessmentInstance> {
        return this.httpClientHelper.invokeGetAuth<AiAssessmentPage>(`${this.instancesController}/${id}`).pipe(
            map((page: AiAssessmentPage) => {
                try {
                    return this.aiAssessmentsV2ParserService.parsePageResponseToInstanceV1(page);
                }
                catch (parseError) {
                    throw parseError;
                }
            }),
            catchError((error) => {
                return throwError(() => error);
            })
        );
    }

    updateInstance(request: AiAssessmentInstance): Observable<AiAssessmentInstance> {
        try {
            const parsed = this.aiAssessmentsV2ParserService.parseInstanceToPageRequestV2(request);
            return this.httpClientHelper.invokePutAuth<AiAssessmentPage>(
                `${this.instancesController}/${request.id}`,
                parsed
            ).pipe(
                map((page: AiAssessmentPage) => {
                    try {
                        return this.aiAssessmentsV2ParserService.parsePageResponseToInstanceV1(page);
                    }
                    catch (parseError) {
                        throw parseError;
                    }
                }),
                catchError((error) => {
                    return throwError(() => error);
                })
            );
        }
        catch (parseError) {
            return throwError(() => parseError);
        }
    }

    updateInstanceState(instanceId: string, state: AiAssessmentStatusEnum): Observable<AiAssessmentInstance> {
        return this.httpClientHelper.invokePatchAuth<AiAssessmentInstance>(`${this.instancesController}/${instanceId}/state`, { state });
    }

    updateInstanceReviewDate(instanceId: string, reviewDate?: string, ownerId?: string): Observable<void> {
        return this.httpClientHelper.invokePostAuth<void>(`${this.instancesController}/${instanceId}/set-review`, {reviewDate, ownerId, setNoFutureReminders: !reviewDate}).pipe(
            map(() => void 0)
        );
    }

    createInstance(request: AiAssessmentInstance): Observable<AiAssessmentInstance> {
        try {
            request.createdAt = new Date().toISOString();
            const parsed = this.aiAssessmentsV2ParserService.parseInstanceToPageRequestV2(request);

            return this.httpClientHelper.invokePostAuth<AiAssessmentPage>(
                `${this.instancesController}`,
                parsed
            ).pipe(
                map(page => {
                    try {
                        return this.aiAssessmentsV2ParserService.parsePageResponseToInstanceV1(page);
                    }
                    catch (parseError) {
                        throw parseError;
                    }
                }),
                catchError(error => throwError(() => error))
            );
        }
        catch (parseError) {
            return throwError(() => parseError);
        }
    }

    deleteInstance(id: string): Observable<void> {
        return this.httpClientHelper.invokeDeleteAuth<void>(`${this.instancesController}/${id}`);
    }

    getTemplates(): Observable<AssessmentTemplateResponse[]> {
        return this.httpClientHelper.invokeGetAuth<AssessmentTemplatePageResponse[]>(`${this.templatesController}`).pipe(
            map(templatePages => {
                try {
                    return templatePages.map(templatePage => this.aiAssessmentsV2ParserService.parseTemplatePageToResponse(templatePage));
                }
                catch (parseError) {
                    throw parseError;
                }
            }),
            catchError(error => throwError(() => error))
        );
    }

    updateTemplate(request: AssessmentTemplateResponse): Observable<void> {
        return this.httpClientHelper.invokePutAuth<void>(`${this.templatesController}/${request.id}`, this.aiAssessmentsV2ParserService.parseTemplateRequestToPageV2(request)).pipe(
            map(() => void 0),
            catchError(error => throwError(() => error))
        );
    }

    createTemplate(request: AssessmentTemplateRequest): Observable<AssessmentTemplateResponse> {
        return this.httpClientHelper.invokePostAuth<AssessmentTemplatePageResponse>(`${this.templatesController}`, this.aiAssessmentsV2ParserService.parseTemplateRequestToPageV2(request)).pipe(
            map(templatePage => {
                try {
                    return this.aiAssessmentsV2ParserService.parseTemplatePageToResponse(templatePage);
                }
                catch (parseError) {
                    throw parseError;
                }
            }),
            catchError(error => throwError(() => error))
        );
    }

    deleteTemplate(id: string): Observable<void> {
        return this.httpClientHelper.invokeDeleteAuth<void>(`${this.templatesController}/${id}`);
    }

    revokeCollaborator(id: string, email: string): Observable<AiAssessmentInstance> {
        return this.httpClientHelper.invokeDeleteAuth<any>(`${this.instancesController}/${id}/collaborators`, { email }).pipe(
            map(page => {
                    try {
                        return this.aiAssessmentsV2ParserService.parsePageResponseToInstanceV1(page);
                    }
                    catch (parseError) {
                        throw parseError;
                    }
                }
            ),
            catchError(error => throwError(() => error))
        );
    }   

    inviteCollaborator(id: string, email: string, message: string): Observable<AiAssessmentInstance> {
        const payload = { email };
        if (!!message) {
            payload['inviteMessage'] = message;
        }
        return this.httpClientHelper.invokePostAuth<any>(`${this.instancesController}/${id}/collaborators`, payload).pipe(
            map(page => {
                    try {
                        return this.aiAssessmentsV2ParserService.parsePageResponseToInstanceV1(page);
                    }
                    catch (parseError) {
                        throw parseError;
                    }
                }
            ),
            catchError(error => throwError(() => error))
        );
    }
    
    uploadInstanceAttachment(instanceId: string, file: File): Observable<AiAssessmentAttachment> {
        return this.httpClientHelper.invokeUploadFileAuth<AiAssessmentAttachment>(`${this.instancesController}/${instanceId}/attachment`, file);
    }

    getInstanceAttachmentUrl(instanceId: string, attachmentId: string): Observable<string> {
        return this.httpClientHelper.invokeGetAuth<string>(`${this.instancesController}/${instanceId}/attachment/${attachmentId}`);
    }

    deleteInstanceAttachment(instanceId: string, attachmentId: string): Observable<AiAssessmentAttachment> {
        return this.httpClientHelper.invokeDeleteAuth<AiAssessmentAttachment>(`${this.instancesController}/${instanceId}/attachment/${attachmentId}`);
    }

    submitInstance(request: AiAssessmentInstance): Observable<AiAssessmentInstance> {
        // Do we have submit for agent as well?
        return EMPTY;
    }

    getLockStatus(instanceId: string): Observable<AiAssessmentLockStatus> {
        return this.httpClientHelper.invokeGetAuth<AiAssessmentLockStatus>(`${this.instancesController}/${instanceId}/lock-status`);
    }

    setLockStatus(instanceId: string, isLocked: boolean): Observable<AiAssessmentLockStatus> {
        return this.httpClientHelper.invokePost<AiAssessmentLockStatus>(`${this.instancesController}/${instanceId}/lock-status`, {
            isLocked
        });
    }
}