import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClientHelperService } from './http-client-helper.service';
import { Risk, RiskCatalog, RiskCatalogsResponse, RiskRegistryFilters } from './models/risks/risks.interface';
import { HttpParams } from '@angular/common/http';
import { MineSort } from '../shared/mine-sort/mine-sort.interface';
import { SortDirectionEnum } from '../shared/sort-direction.enum';

@Injectable({
  providedIn: 'root'
})
export class BaseApiClientRisksService {
	protected readonly controller: string = 'risks';

	constructor(protected httpClientHelper: HttpClientHelperService) { }

    getRisksCatalog(): Observable<RiskCatalogsResponse> {
        return this.httpClientHelper.invokeGetAuth<RiskCatalogsResponse>(`${this.controller}/catalog`);
    }

    createRiskCatalog(riskCatalog: RiskCatalog): Observable<RiskCatalog> {
      return this.httpClientHelper.invokePostAuth<RiskCatalog>(`${this.controller}/catalog-item`, riskCatalog);
    }

    updateRiskCatalog(id: number, riskCatalog: RiskCatalog): Observable<RiskCatalog> {
      return this.httpClientHelper.invokePutAuth<RiskCatalog>(`${this.controller}/catalog-item/${id}`, riskCatalog);
    }

    updateRiskCatalogByType(type: string, riskCatalog: RiskCatalog): Observable<RiskCatalog> {
      return this.httpClientHelper.invokePutAuth<RiskCatalog>(`${this.controller}/catalog-item/type/${type}`, riskCatalog);
    }

    deleteRiskCatalog(id: number): Observable<void> {
      return this.httpClientHelper.invokeDeleteAuth<void>(`${this.controller}/catalog-item/${id}`);
    }

    getRiskRegistry(search = '', filters: RiskRegistryFilters = null, sort: MineSort = null): Observable<Risk[]> {
      return this.httpClientHelper.invokeGet<Risk[]>(`${this.controller}/risks`, this.getQueryParams(search, filters, sort));
    }

    protected getQueryParams(search: string, filters: RiskRegistryFilters, sort: MineSort): HttpParams {
      let qParams = new HttpParams();
      if (!!search) {
        qParams = qParams.append('search.query', search);
      }

      qParams = qParams.appendAll({
        'filter.mitigations': filters?.mitigations ?? [] ,
        'filter.residualRisk': filters?.residualRisk ?? [],
        'filter.inherentRisk': filters?.inherentRisk ?? [],
        'filter.type': filters?.riskTypes ?? [],
        'relations.assessmentTypes': filters?.assessmentTypes ?? [],
        'relations.relatedEntityIds': filters?.dataSources ?? [],
      });

      qParams = this.applySortParams(qParams, sort);

      return qParams;
    }

    private applySortParams(qParams: HttpParams, sort: MineSort): HttpParams {
      if (!!sort && sort?.direction !== SortDirectionEnum.Default) {
          qParams = qParams.append('orderBy.column', sort.active);
  
          if (sort.direction === SortDirectionEnum.Asc) {
              qParams = qParams.append('orderBy.descending', false);
          } else if (sort.direction === SortDirectionEnum.Desc) {
              qParams = qParams.append('orderBy.descending', true);
          }
      }
      return qParams;
  }
}
