import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseApiClientRisksService } from "src/app/api/base-api-client-risks.service";
import { HttpClientHelperService } from "src/app/api/http-client-helper.service";
import { Risk, RiskCatalog, RiskCatalogsResponse, RiskRegistryFilters } from "src/app/api/models/risks/risks.interface";
import { MineSort } from "src/app/shared/mine-sort/mine-sort.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiClientCollaborationRisksService extends BaseApiClientRisksService {
  protected readonly controller: string = 'collaboration/risks';

  constructor(httpClientHelper: HttpClientHelperService) {
    super(httpClientHelper);
  }

  override getRisksCatalog(): Observable<RiskCatalogsResponse> {
    return this.httpClientHelper.invokeGetAuth<RiskCatalogsResponse>(`${this.controller}/catalog`);
  }

  override createRiskCatalog(riskCatalog: RiskCatalog): Observable<RiskCatalog> {
    return this.httpClientHelper.invokePostAuth<RiskCatalog>(`${this.controller}/catalog-item`, riskCatalog);
  }

  override getRiskRegistry(search = '', filters: RiskRegistryFilters = null, sort: MineSort = null): Observable<Risk[]> {
    return this.httpClientHelper.invokeGet<Risk[]>(`${this.controller}/risks`, this.getQueryParams(search, filters, sort));
  }
}
