export enum SystemStatusEnum {
    Archived = "Archived",
}

export enum SystemsFiltersEnum {
    All = 'All',
    Category = 'Category',
    Feedback = 'Feedback',
    Frameworks = 'Frameworks',
    ReviewStatus = 'ReviewStatus',
    RequestHandling = 'RequestHandling',
    BusinessOwner = 'BusinessOwner',
    Stakeholders = 'Stakeholders',
    Status = 'Status',
    Vendor = 'Vendor',
    Functions = 'functions',
}

export enum SystemStatusFiltersEnum {
    Approved = 'Approved',
    Draft = 'Draft',
}