import { Inject, Injectable } from '@angular/core';
import { Observable, BehaviorSubject, interval, Subject, switchMap, takeUntil, catchError, of, tap, startWith } from 'rxjs';
import { BaseApiClientAiAssessmentsService } from 'src/app/api/base-api-client-ai-assessments.service';
import { API_CLIENT_ASSESSMENTS_SERVICE } from 'src/app/api/injectors/api-client-ai-assessments.token';
import { AiAssessmentLockStatus } from 'src/app/api/models/ai-assessments/ai-assessments.interface';

@Injectable({
  providedIn: 'root'
})
export class AiAssessmentsLockStatusService {
  private pollingInterval = 10_000;
  private stopPollingSubject = new Subject<void>();
  private lockStatusSubject = new BehaviorSubject<AiAssessmentLockStatus | null>(null);

  lockStatus$: Observable<AiAssessmentLockStatus | null> = this.lockStatusSubject.asObservable();

  constructor(
    @Inject(API_CLIENT_ASSESSMENTS_SERVICE) private apiClientAiAssessmentsService: BaseApiClientAiAssessmentsService,
  ) {}

  startPollingLockStatus(instanceId: string): void {
    interval(this.pollingInterval).pipe(
      startWith(0),
      takeUntil(this.stopPollingSubject),
      switchMap(() =>
        this.apiClientAiAssessmentsService.getLockStatus(instanceId).pipe(
          catchError((error) => {
            console.error(`Polling error for instance ${instanceId}:`, error);
            return of(null);
          })
        )
      ),
      tap((lockStatus) => this.lockStatusSubject.next(lockStatus))
    ).subscribe();
  }

  stopPolling(): void {
    this.stopPollingSubject.next();
    this.stopPollingSubject.complete();
    this.stopPollingSubject = new Subject<void>();
  }

  updateLockStatus(lockStatus: AiAssessmentLockStatus): void {
    this.lockStatusSubject.next(lockStatus);
  }
}