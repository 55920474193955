import { NgModule } from '@angular/core';
import { DecimalPipe } from "@angular/common";
import { DaysAgoPipe } from '../requests/common/days-ago.pipe';
import { DisplayNumberPipe } from '../shared/pipes/display-number.pipe';
import { CountryFromCodePipe } from '../shared/pipes/country-code.pipe';
import { SplitUpperCasePipe } from '../shared/pipes/split-upper-case.pipe';
import { TicketStateToStatusPipe } from '../requests/common/ticket-state-to-status.pipe';
import { RadarTableCsvHelper } from '../radar/radar-table/radar-table-csv-helper';
import { RequestsTableCsvHelper } from '../requests/requests-table/requests-table-csv-helper';
import { SystemsTableCsvHelper } from '../systems/systems-home/systems-table/systems-table-csv-helper';
import { TrashSystemsTableCsvHelper } from '../unused-assets/trash-systems/trash-systems-table-csv-helper';
import { EmployeesTableCsvHelper } from '../employees/employees-managment/employees-table/employees-table-csv-helper';
import { ProcessingActivityTableCsvHelper } from '../processing-activities/processing-activity-table/processing-activity-table-csv-helper';
import { RiskCalulationPipe } from '../processing-activities/services/risk-calulation.pipe';
import {
    PiiDataTypesTableCsvHelper
} from "../pii-classifier/pii-system-findings/pii-system-data-types/data-types-table/data-types-table-csv-helper";
import {DataTypeMatchesFormatPipe} from "../data-types/pipes/data-type-matches-format.pipe";
import {
  PiiSchemasTableCsvHelper
} from "../pii-classifier/pii-system-findings/pii-system-schema/schema-table/schema-table-csv-helper";
import { SystemFieldIdToNamePipe } from "../systems/pipes/system-field-id-to-name.pipe";
import { DataSubjectIdToNamePipe } from '../processing-activities/pipes/data-subject-id-to-name.pipe';
import { ActivityFieldIdToNamePipe } from '../processing-activities/pipes/activity-field-id-to-name.pipe';
import { LocationCodesToTextPipe } from '../shared/pipes/location-codes-to-text.pipe';
import { AiAssessmentsTableCsvHelper } from '../ai-assessments/services/ai-assessments-table-csv-helper';
import { AiAssessmentTemplateNamePipe } from '../ai-assessments/pipes/ai-assessment-template-name.pipe';
import { AiAssessmentStatusPipe } from '../ai-assessments/pipes/ai-assessment-status.pipe';
import { AiAssessmentsService } from '../ai-assessments/services/ai-assessments.service';
import { AiAssessmentsExportHelper } from '../ai-assessments/services/ai-assessments-export-helper';
import { RiskRegistryTableCsvHelper } from '../risks/services/risk-registry-table-csv-helper';
import { CollaboratorsChipPipe } from '../ai-assessments/pipes/collaborators-chip.pipe';

const standalonePipes = [
    DaysAgoPipe,
    CountryFromCodePipe,
    DisplayNumberPipe,
    SplitUpperCasePipe,
    TicketStateToStatusPipe,
    RiskCalulationPipe,
    DataTypeMatchesFormatPipe,
    SystemFieldIdToNamePipe,
    DataSubjectIdToNamePipe,
    ActivityFieldIdToNamePipe,
    LocationCodesToTextPipe,
    AiAssessmentTemplateNamePipe,
    AiAssessmentStatusPipe,
    CollaboratorsChipPipe
];

@NgModule({
  imports: [
    ...standalonePipes
  ],
  providers: [
    ...standalonePipes,
    RadarTableCsvHelper,
    PiiDataTypesTableCsvHelper,
    PiiSchemasTableCsvHelper,
    SystemsTableCsvHelper,
    RequestsTableCsvHelper,
    EmployeesTableCsvHelper,
    TrashSystemsTableCsvHelper,
    ProcessingActivityTableCsvHelper,
    RiskRegistryTableCsvHelper,
    AiAssessmentsTableCsvHelper,
    AiAssessmentsService,
    CollaboratorsChipPipe,
    DecimalPipe,
    AiAssessmentsExportHelper
  ]
})
export class CsvConvertorModule {}