import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClientHelperService } from "../http-client-helper.service";
import { EligibleRegion, NewPrivacyRightData, OptionalTasksResponse, PrivacyRightDeepItem, PrivacyRightExtendedResponse, PrivacyRightItem, PrivacyRightShallowResponse, PrivacyRightUpdatedWorkflow, PrivacyRightWorkflow, PrivacyRightWorkflowResponse, WorkflowShallowResponse } from "../models/dsr/dsr-rights.interface";

@Injectable({
  	providedIn: 'root'
})
export class ApiClientDsrRightsService {

	private readonly dsrController = 'dsr';
	private readonly rightsController = 'rights';
	private readonly version = 'v1';

    constructor(
        private httpClientHelper: HttpClientHelperService
    ) { }

	getPrivacyRightsShallow(): Observable<PrivacyRightShallowResponse> {
		return this.httpClientHelper.invokeGetAuth<PrivacyRightShallowResponse>(`${this.dsrController}/${this.version}/${this.rightsController}/list`);
	}

	getPrivacyRightsExtended(localCode: string = "en-us"): Observable<PrivacyRightExtendedResponse> {
		return this.httpClientHelper.invokeGetAuth<PrivacyRightExtendedResponse>(`${this.dsrController}/${this.version}/${this.rightsController}/extended-list?local=${localCode}`);
	}

	getOptionalTasks(rightId: string): Observable<OptionalTasksResponse> {
		return this.httpClientHelper.invokeGetAuth<OptionalTasksResponse>(`${this.dsrController}/${this.version}/${this.rightsController}/${rightId}/optional-tasks`);
	}

	getPrivacyRightDisplaySettings(rightId: string): Observable<PrivacyRightDeepItem> {
		return this.httpClientHelper.invokeGetAuth<PrivacyRightDeepItem>(`${this.dsrController}/${this.version}/${this.rightsController}/${rightId}/display-settings`);
	}

	getPrivacyRightProcessingSettings(rightId: string): Observable<PrivacyRightWorkflowResponse> {
		return this.httpClientHelper.invokeGetAuth<PrivacyRightWorkflowResponse>(`${this.dsrController}/${this.version}/${this.rightsController}/${rightId}/processing-settings`);
	}

	updatePrivacyRightDisplaySettings(id: string, right: Partial<PrivacyRightDeepItem>): Observable<void> {
		return this.httpClientHelper.invokePatchAuth<void>(`${this.dsrController}/${this.version}/${this.rightsController}/${id}/display-settings`, right);
	}

	addWorkflow(rightId: string, workflowName: string): Observable<Pick<PrivacyRightWorkflow, 'workflowId'>> {
		return this.httpClientHelper.invokePost<Pick<PrivacyRightWorkflow, 'workflowId'>>(`${this.dsrController}/${this.version}/${this.rightsController}/${rightId}/processing-settings`, {workflowName});
	}
	
	deleteWorkflow(rightId: string, workflowId: string): Observable<void> {
		return this.httpClientHelper.invokeDeleteAuth<void>(`${this.dsrController}/${this.version}/${this.rightsController}/${rightId}/processing-settings/${workflowId}`);
	}
	
	updatePrivacyRightWorkflows(id: string, workflows: PrivacyRightUpdatedWorkflow[]): Observable<void> {
		const data = {
			workflows
		};
		return this.httpClientHelper.invokePatchAuth<void>(`${this.dsrController}/${this.version}/${this.rightsController}/${id}/processing-settings`, data);
	}

	updatePrivacyRightDecisionSettings(id: string, eligibleRegions: EligibleRegion[]): Observable<void> {
		return this.httpClientHelper.invokePatchAuth<void>(`${this.dsrController}/${this.version}/${this.rightsController}/${id}/decision-settings`, {eligibleRegions});
	}

	getWorkflowsShallow(rightId: string): Observable<WorkflowShallowResponse> {
		return this.httpClientHelper.invokeGetAuth<WorkflowShallowResponse>(`${this.dsrController}/${this.version}/${this.rightsController}/${rightId}/shallow-workflows`);
	}

	createNewPrivacyRight(data: NewPrivacyRightData): Observable<PrivacyRightItem> {
		return this.httpClientHelper.invokePostAuth<PrivacyRightItem>(`${this.dsrController}/${this.version}/${this.rightsController}`, data);
	}

	deletePrivacyRight(rightId: string): Observable<void> {
		return this.httpClientHelper.invokeDeleteAuth<void>(`${this.dsrController}/${this.version}/${this.rightsController}/${rightId}`);
	}

}