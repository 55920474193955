import { FeatureFlagQuery } from './../../feature-flag/state/feature-flag.query';
import { DsrPrivacyRightsQuery } from './../../dsr/dsr-setup/dsr-privacy-rights/state/dsr-privacy-rights.query';
import { Injectable } from '@angular/core';
import { DaysAgoPipe } from '../common/days-ago.pipe';
import { RequestSortKey } from '../models/request-sort-key.enum';
import { RequestItem } from 'src/app/api/models/requests/requests.interface';
import { RequestItemDeep } from 'src/app/api/models/requests/requests.interface';
import { TicketSource } from 'src/app/api/models/requests/ticket-source.enum';
import { TicketStateToStatusPipe } from '../common/ticket-state-to-status.pipe';
import { SplitUpperCasePipe } from 'src/app/shared/pipes/split-upper-case.pipe';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { ColumnSettings } from '../models/column';

import * as dayjs from 'dayjs';
import { TicketStateEnum } from 'src/app/api/models/requests/ticket-state.enum';
import { LocationCodesToTextPipe } from 'src/app/shared/pipes/location-codes-to-text.pipe';

@Injectable()
export class RequestsTableCsvHelper {

  constructor(
    private contentPipe: ContentPipe,
    private daysAgoPipe: DaysAgoPipe,
    private splitUpperCasePipe: SplitUpperCasePipe,
    private locationToCode: LocationCodesToTextPipe,
    private ticketStateToStatusPipe: TicketStateToStatusPipe,
    private dsrPrivacyRightQuery: DsrPrivacyRightsQuery,
    private featureFlagQuery: FeatureFlagQuery
  ) {}

  getValueByKey(request: RequestItemDeep | RequestItem, key: string): string {    
    const columns = this.contentPipe.transform('requests-table.tableColumnsClosed').concat(this.contentPipe.transform('requests-table.tableColumns')) as ColumnSettings[];
    const values: Partial<Record<RequestSortKey, string>> = {
      actionId: request.actionId ?? '-',
      closingReason: '-',
      country: request.countryCode ? this.locationToCode.transform(`:${request.countryCode}:`) : '-',
      createdAt: request.createdAt ? this.daysAgoPipe.transform(request.createdAt) : '-',
      dueDate: request.dueDate ? this.getDueDate(request.dueDate) : '-',
      name: '-',
      domain: this.getDomain(request),
      userEmail: request.userEmail ?? '-',
      ticketSource: request.ticketSource ? this.splitUpperCasePipe.transform(request.ticketSource) : '-',
      ticketState: request.ticketState ? this.ticketStateToStatusPipe.transform((<RequestItem>request).ticketState as TicketStateEnum) : '-',
      privacyRightId: this.dsrPrivacyRightQuery.getRights()().get(request.privacyRightId)?.displayName ?? '-',
    };
      
    return values[columns.find(column => column.key === key)?.dataPropName];
  }

  private getDomain(request: RequestItemDeep | RequestItem): string {
    const ticketSourcesToHideDomain = [TicketSource.Api, TicketSource.EmailForwarding, TicketSource.Form, TicketSource.Manual];
    if (!request.ticketSource || ticketSourcesToHideDomain.includes(request.ticketSource)) {
      return '-';
    }
    return request.domain;
  }

  private getDueDate(dueDate: string): string {
		const days = dayjs(dueDate).diff(new Date().toDateString(), 'days');
		const isDueDatePassed = days < 0;
		
		if (isDueDatePassed) {
			return this.daysAgoPipe.transform(dueDate);
		}

		return days === 1 ? 
				this.contentPipe.transform('ticket.futureDueDateSingular', { params: {number: days} } ) :
				this.contentPipe.transform('ticket.futureDueDate', { params: {number: days} } );
	}
}