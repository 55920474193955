import {Inject, Injectable, Injector} from '@angular/core';
import { catchError, EMPTY, filter, first, map, merge, Observable, switchMap, tap } from 'rxjs';

import { LoggerService } from 'src/app/logger/logger.service';
import {
  CustomDataSubjects,
  DebugModeSettings,
  IconUploadResponse
} from './../../api/models/company-settings/company-settings.interface';
import { ProcessingActivitiesQuery } from 'src/app/processing-activities/state/processing-activities.query';
import { Branding, CompanySettings, Inventory, Customization, Workflow, Security, CustomField, SystemFieldResponse, RopaDetails, CopyPreview } from 'src/app/api/models/company-settings/company-settings.interface';
import { CompanySettingsQuery } from './company-settings.query';
import { CompanySettingsStore } from './company-settings.store';
import { ApiClientSessionsService } from 'src/app/api/api-client-sessions.service';
import { SessionInfo } from 'src/app/api/models/sessions/sessions.interface';
import { ProcessingActivitiesService } from 'src/app/processing-activities/state/processing-activities.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { ProfileStore } from 'src/app/profile/state/profile.store';
import { API_CLIENT_COMPANY_SETTINGS_SERVICE } from 'src/app/api/injectors/api-client-company-settings.token';
import { BaseApiClientCompanySettingsService } from 'src/app/api/base-api-client-company-settings.service';

@Injectable({ 
	providedIn: 'root' 
})
export class CompanySettingsService {
	private readonly loggerName: string = 'CompanySettingsService';

	constructor(
		private injector: Injector,
		private logger: LoggerService,
		private companySettingsStore: CompanySettingsStore,
		private companySettingsQuery: CompanySettingsQuery,
		@Inject(API_CLIENT_COMPANY_SETTINGS_SERVICE) private apiClientCompanySettings: BaseApiClientCompanySettingsService,
		private processingActivitiesQuery: ProcessingActivitiesQuery,
		private apiClientSessions: ApiClientSessionsService,
		private fileUploadService: FileUploadService,
		private profileStore: ProfileStore,
	) { }

	init(): Observable<void> {
		this.logger.debug(this.loggerName, 'init()');
		return this.setStore().pipe(first(), map(() => void 0));
	}

	private setStore(): Observable<CompanySettings | void> {
		const loading$ = this.companySettingsQuery.selectLoading();

		const getDataFromStore$ = loading$.pipe(
			filter(response => !response),
			switchMap(() => this.companySettingsQuery.select()),
			tap(() => this.logger.info(this.loggerName, 'get company settings from store'))
		);

		const getDataFromServer$ = loading$.pipe(
			filter(response => response),
			switchMap(() => this.getCompanySettings()),
			tap(() => this.logger.info(this.loggerName, 'get company settings from server'))
		);

		return merge(getDataFromStore$, getDataFromServer$).pipe(first());
	}

	getSessionsInfo(): Observable<SessionInfo[]> {
		const loading$ = this.companySettingsQuery.select('sessions');
		 
		const getDataFromStore$ = loading$.pipe(
			filter(response => !!response),
			map(response => response.info),
		);

		const getDataFromServer$ = loading$.pipe(
			filter(response => !response),
			switchMap(() => this.apiClientSessions.getSessionsInfo()),
			map(response => response.sessions),
		);

		return merge(getDataFromStore$, getDataFromServer$).pipe();
	}
	
	getCompanySettings(): Observable<CompanySettings> {
		return this.apiClientCompanySettings.getCompanySettings().pipe(
			tap(res => this.companySettingsStore.update(res)),
			tap(() => this.companySettingsStore.setLoading(false)),
		);
	}
	
	updateCustomization(customization: Customization): Observable<void> {
		return this.apiClientCompanySettings.updateCustomization(customization).pipe(
			tap(() => this.companySettingsStore.update({ customization })),
		);
	}

	updateBranding(branding: Branding): Observable<void> {
		return this.apiClientCompanySettings.updateBranding(branding).pipe(
			tap(() => this.companySettingsStore.update({ branding })),
		);
	}

	updateInventory(inventory: Inventory): Observable<Inventory> {
		return this.apiClientCompanySettings.updateInventory(inventory).pipe(
			tap((inventoryRes) => this.companySettingsStore.update({ inventory: inventoryRes })),
		);
	}

	updateInventoryEntities(entities: SystemFieldResponse[]): Observable<SystemFieldResponse[]> {
		return this.apiClientCompanySettings.updateInventoryEntities({ inventoryEntities: entities}).pipe(
				map(response => response.inventoryEntities),
				tap(entities => this.companySettingsStore.update(state => ({
						inventory: {
							...state.inventory,
							inventoryEntities: entities
						}
					}))
				) 
			);
	}

	validateEntityUsed(id: string): Observable<boolean> {
		return this.processingActivitiesQuery.selectDataflowCustomEntities().pipe(
				map(res => !!(res.find(entity => entity.source.id === id || entity.destination.id === id)))
			);
	}

	addWorkflow(workflow: Workflow): Observable<void> {
		const workflows = this.companySettingsQuery.getWorkflows();
		
		return this.apiClientCompanySettings.addWorkflow(workflow).pipe(
			tap(() => this.companySettingsStore.update(state => ({
				...state,
				inventory: {
					...this.companySettingsQuery.getInventory(),
					workflows: workflows ? workflows.concat(workflow.workflow) : [workflow.workflow]}
			})))
		);
	}

	addCustomMitigation(name: string): Observable<void> {		
		return this.apiClientCompanySettings.addCustomMitigation(name).pipe(
			tap((mitigatins) => this.companySettingsStore.update(state => ({
				...state,
				activities: {
					customMitigations: mitigatins.customMitigations
				}
			}))),
			tap(() => this.logger.info(this.loggerName, `Added custom mitigation: ${name}`)),
			map(() => void 0),
			catchError(error => {
				this.logger.error(this.loggerName, error.message);
				return EMPTY;
			})
		);
	}

	addCustomDataSubject(name: string): Observable<void> {		
		return this.apiClientCompanySettings.addDataSubject(name).pipe(
			tap(customDataSubjects => this.updataDataSubjectsInStore(customDataSubjects)),
			tap(() => this.logger.info(this.loggerName, `Added custom data subject: ${name}`)),
			map(() => void 0),
			catchError(error => {
				this.logger.error(this.loggerName, error.message);
				return EMPTY;
			})
		);
	}

	updateDataSubject(dataSubject: CustomField): Observable<void> {
		return this.apiClientCompanySettings.updateDataSubject(dataSubject).pipe(
			tap(customDataSubjects => this.updataDataSubjectsInStore(customDataSubjects)),
			tap(() => this.logger.info(this.loggerName, `Updated custom data subject: ${dataSubject.id}`)),
			map(() => void 0),
			catchError(error => {
				this.logger.error(this.loggerName, error.message);
				return EMPTY;
			})
		);
	}

	deleteDataSubject(id: string): Observable<void> {
		return this.apiClientCompanySettings.deleteDataSubject(id).pipe(
			tap(customDataSubjects => this.updataDataSubjectsInStore(customDataSubjects)),
			tap(() => this.logger.info(this.loggerName, `Delete custom data subject: ${id}`)),
			switchMap(() => this.refreshProcessingActivitiesStore()),
			catchError(error => {
				this.logger.error(this.loggerName, error.message);
				return EMPTY;
			})
		);
	}

	private updataDataSubjectsInStore(custom: CustomDataSubjects): void {
		this.companySettingsStore.update(state => ({
			...state,
			dataSubjects: custom
		}));
	}

	updateCustomMitigation(mitigation: CustomField): Observable<void> {
		return this.apiClientCompanySettings.updateCustomMitigation(mitigation).pipe(
			tap(mitigatins => this.companySettingsStore.update(state => ({
				...state,
				activities: {
					customMitigations: mitigatins.customMitigations
				}
			}))),
			tap(() => this.logger.info(this.loggerName, `Updated custom mitigation with id: ${mitigation.id}`)),
			map(() => void 0),
			catchError(error => {
				this.logger.error(this.loggerName, error.message);
				return EMPTY;
			})
		);
	}

	deleteCustomMitigation(id: string): Observable<void> {
		return this.apiClientCompanySettings.deleteCustomMitigation(id).pipe(
			tap(mitigatins => this.companySettingsStore.update(state => ({
				...state,
				activities: {
					customMitigations: mitigatins.customMitigations
				}
			}))),
			tap(() => this.logger.info(this.loggerName, `Deleted custom mitigation with id: ${id}`)),
			switchMap(() => this.refreshProcessingActivitiesStore()),
			catchError(error => {
				this.logger.error(this.loggerName, error.message);
				return EMPTY;
			})
		);
	}

	saveSecurity(security: Security): Observable<void> {
		return this.apiClientCompanySettings.updateSecurity(security).pipe(
			tap(() => this.companySettingsStore.update(state => ({
				...state,
				security
			}))),
		);
	}

	saveCopyPreview(data: CopyPreview): Observable<void> {
		return this.apiClientCompanySettings.updateCopyPreview(data).pipe(
			tap(() => this.companySettingsStore.update(state => ({
				...state,
				copyPreview: data
			}))),
		);
	}

	saveRopaDetails(ropaDetails: RopaDetails): Observable<void> {
		return this.apiClientCompanySettings.saveRopaDetails(ropaDetails).pipe(
			tap(() => this.companySettingsStore.update(state => ({
				...state,
				ropaDetail: {
					details: ropaDetails.details
				}
			}))),
		);
	}

	private refreshProcessingActivitiesStore(): Observable<void> {
		return this.injector.get(ProcessingActivitiesService).getProcessingActivities(true).pipe(map(() => void 0)); 
	}
	
	uploadIcon(file: File): Observable<void> {
		let iconData: IconUploadResponse;

	return this.apiClientCompanySettings.uploadIcon(file.name).pipe(
		filter(res => !!res.filePath),
		tap(res => iconData = res),
		switchMap(res => this.fileUploadService.upload(file, res.url)),
		switchMap(() => this.apiClientCompanySettings.saveIcon(iconData.filePath)),
		tap(() => this.profileStore.update(state => ({companyIcon: iconData.fullFilePath }))),
		);
	}

	setDebugMode(debugMode: DebugModeSettings): Observable<void> {
		
		return this.apiClientCompanySettings.setDebugMode(debugMode).pipe(
			tap(() => this.companySettingsStore.update(state => ({
				...state,
				dataClassifier: {
					piiDebugMode: debugMode.debug
				}
			}))),
		);
	}
}