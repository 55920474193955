import { Injectable } from "@angular/core";
import { catchError, map, Observable, of, throwError } from "rxjs";

import { HttpClientHelperService } from "src/app/api/http-client-helper.service";
import { AiAssessmentAttachment, AiAssessmentInstance, AiAssessmentLockStatus, AiAssessmentPage, AssessmentTemplateResponse } from "src/app/api/models/ai-assessments/ai-assessments.interface";
import { AiAssessmentsV2ParserService } from "src/app/ai-assessments/services/ai-assessments-v2-parser.service";
import { BaseApiClientAiAssessmentsService } from "src/app/api/base-api-client-ai-assessments.service";
import { CustomFieldsQuery } from "src/app/company-settings/state/custom-fields/custom-fields.query";
import { CustomValuesQuery } from "src/app/company-settings/state/custom-values/custom-values.query";

@Injectable({
  providedIn: 'root'
})
export class ApiClientCollaborationAssessmentsService extends BaseApiClientAiAssessmentsService {
  protected readonly instancesController = 'collaboration/assessments';

  constructor(
    httpClientHelper: HttpClientHelperService,
    customFieldsQuery: CustomFieldsQuery,
    customValuesQuery: CustomValuesQuery,
    aiAssessmentsV2ParserService: AiAssessmentsV2ParserService
  ) {
    super(httpClientHelper, customFieldsQuery, customValuesQuery, aiAssessmentsV2ParserService);
  }

  override submitInstance(request: AiAssessmentInstance): Observable<AiAssessmentInstance> {
    try {  
      return this.httpClientHelper.invokePostAuth<AiAssessmentPage>(
        `${this.instancesController}/${request.id}/submit`
      ).pipe(
        map(() => ({} as AiAssessmentInstance)),
        catchError(error => throwError(() => error))
      );
    } catch (parseError) {
      return throwError(() => parseError);
    }
  }

  override uploadInstanceAttachment(instanceId: string, file: File): Observable<AiAssessmentAttachment> {
    return this.httpClientHelper.invokeUploadFileAuth<AiAssessmentAttachment>(`${this.instancesController}/${instanceId}/attachment`, file);
  }

  override getInstanceAttachmentUrl(instanceId: string, attachmentId: string): Observable<string> {
      return this.httpClientHelper.invokeGetAuth<string>(`${this.instancesController}/${instanceId}/attachment/${attachmentId}`);
  }

  override getTemplates(): Observable<AssessmentTemplateResponse[]> {
    return of([]);
  }

  override getInstanceById(id: string, lean = false): Observable<AiAssessmentInstance> {

    const apiRoute = lean ? `${this.instancesController}/${id}/lean` : `${this.instancesController}/${id}`;
  
    return this.httpClientHelper.invokeGetAuth<AiAssessmentPage>(apiRoute).pipe(
      map((page: AiAssessmentPage) => {
        try {
          return this.aiAssessmentsV2ParserService.parsePageResponseToInstanceV1(page);
        } catch (parseError) {
          throw parseError;
        }
      }),
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  override getLockStatus(instanceId: string): Observable<AiAssessmentLockStatus> {
    return this.httpClientHelper.invokeGetAuth<AiAssessmentLockStatus>(`${this.instancesController}/${instanceId}/lock-status`);
  }

  override setLockStatus(instanceId: string, isLocked: boolean): Observable<AiAssessmentLockStatus> {
    return this.httpClientHelper.invokePost<AiAssessmentLockStatus>(`${this.instancesController}/${instanceId}/lock-status`, {
        isLocked
    });
  }
}
