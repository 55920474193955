import { Component, OnInit, ChangeDetectionStrategy, Input, HostBinding, ViewChildren, QueryList, SimpleChanges, OnChanges, SimpleChange } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { Animations } from 'src/app/animations/animations';
import { AnalyticsEvent } from 'src/app/analytics/analytics-event';
import { AnalyticEventsLabels } from 'src/app/analytics/analytics-events-labels';
import { AppSidenavPimpleService } from './app-sidenav-pimple.service';
import { AnalyticsService } from 'src/app/analytics/analytics.service';
import { EventActionType } from 'src/app/analytics/event-action.enum';
import { MineExpansionPanelComponent } from 'src/app/shared/mine-expansion-panel/mine-expansion-panel.component';
import { FeatureFlagQuery } from 'src/app/feature-flag/state/feature-flag.query';
import { ContentPipe } from 'src/app/services/content/content.pipe';
import { NavMenuItem } from './app-sidenav-content.interface';
import { PermissionsService } from 'src/app/auth/permissions.service';
import { RolePermissionsHelper } from 'src/app/auth/role-permmissions-helper';
import { FeatureFlags } from 'src/app/api/models/profile/profile-feature-flags.enum';
import { MenuItemId } from './menu-item.enum';

@Component({
	selector: 'app-sidenav-content',
	templateUrl: './app-sidenav-content.component.html',
	styleUrls: ['./app-sidenav-content.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [Animations.pimple]
})
export class AppSidenavContent implements OnInit, OnChanges {
	@HostBinding('class.shrink')
	@Input()
	shrink: boolean = false;

	@ViewChildren("subMenu") subMenus: QueryList<MineExpansionPanelComponent>;

	items: NavMenuItem[] = [];
	pimpleMap: Map<string,  Observable<number>> = new Map();

	readonly tpProps = {
		placement: 'right',
	};

	constructor(
		private contentPipe: ContentPipe, 
		private featureFlagQuery: FeatureFlagQuery,
		private router: Router,
		private analytics: AnalyticsService,
		private pimpleService: AppSidenavPimpleService,
		private permissionsService: PermissionsService,
	) {}

	ngOnInit(): void {
		this.initItems();
	}

	ngOnChanges(changes: SimpleChanges): void {
		const shrink: SimpleChange = changes.shrink;
		shrink?.currentValue ? this.closeAllSubmenus() : this.openActiveSubmenu();
	}

	onItemClick(item: NavMenuItem): void {
		this.logAnalyticsEvent(item);
		if(!item.subItem) {
			item.active = !item.active;
		}
	}

	private closeAllSubmenus(): void {
		this.subMenus?.forEach(menu => menu.forceClose());
	}

	private logAnalyticsEvent(item: NavMenuItem): void {
		if(!item.analyticsLabel) {
			return;
		}
		this.analytics.track({
		  eventCategory: AnalyticEventsLabels[item.analyticsLabel] ?? item.analyticsLabel,
		  eventAction: EventActionType.Click,
		} as AnalyticsEvent);
	}

	private openActiveSubmenu(): void {
		this.items.forEach(item => item.active = false);
		this.highlightActiveItem();
		setTimeout(() => {
			const activeNavItem: NavMenuItem = this.items.find((i) => i.active);
			if (!activeNavItem?.subMenu?.length) {
				return;
			}
			const submenuIndex = this.items.filter((i) => i.subMenu?.length && this.permissionsService.getIsAllowed(i.permittedRoles)).findIndex((i) => i.active);
			this.subMenus?.get(submenuIndex)?.forceOpen();
		}, 500);
	}

	private initItems(): void {
		this.items = this.contentPipe.transform(
			this.featureFlagQuery.getFlag(FeatureFlags.DevSidenavV2) ?
				`nav-menu-v2.items` : `nav-menu.items`
		) as NavMenuItem[];

		this.items = this.items
			.filter(item => this.checkFeatureFlag(item))
			.map((item) => {
				this.checkPimple(item);
				item = this.checkSubitems(item);
				return {
					...item,
					active: false,
				};
			});
		
		if (!this.featureFlagQuery.getFlag(FeatureFlags.DevAssessmentsRopaMigration)) {
			const complianceItemIndex = this.items.findIndex(item => item.id === MenuItemId.Compliance);
			if (complianceItemIndex !== -1) {
				this.items[complianceItemIndex].subMenu = this.items[complianceItemIndex].subMenu
					?.filter(item => item.id !== ('processingActivities' as MenuItemId));
			}
		}

		this.highlightActiveItem();
	}

	private highlightActiveItem(): void {
		const activeUrl = this.router.url;
		let activeNavMenu = this.items.find(i=> i.route && activeUrl.includes(i.route));
		if(!activeNavMenu) {
			activeNavMenu = this.items.find(i => i.subMenu?.length && i.subMenu?.find(subItem => activeUrl.includes(subItem?.route)));
		}
		activeNavMenu ? activeNavMenu.active = true : "";
	}

	private checkSubitems(item: NavMenuItem): NavMenuItem {
		if (item.subMenu?.length) {
			
			item = {...item,
				permittedRoles: RolePermissionsHelper.getPerrmitedRolesByMenuId(item.id), 
				subMenu: item.subMenu
				.filter(item => this.checkFeatureFlag(item))
        		.filter(item => this.checkFeatureFlagRemove(item))
				.map(subNav => {
					item.subMenu.forEach(i => {
						this.checkPimple(i);					
					});
					return({...subNav, 
						subItem: true,
						permittedRoles: RolePermissionsHelper.getPerrmitedRoleByRoute([subNav.route])
					});
				})};
			} else {
				item = {
					...item,
					permittedRoles: RolePermissionsHelper.getPerrmitedRoleByRoute([item.route]), 
				};
			}
			return item;
			
	}

	private checkPimple(item: NavMenuItem): void {
		if (item.pimple) {
			this.pimpleMap.set(item.id ,this.pimpleService.getPimpleData(item.id));
		}
	}

	private checkFeatureFlag(item: NavMenuItem): boolean {
		// remove once we are removing the flag in unleash prod + squidex
		if(!item.featureFlagName || item.featureFlagName === 'dataTypesClassification') {
			return true;
		} 
		return this.featureFlagQuery.getFlag(item.featureFlagName);
	}

  private checkFeatureFlagRemove(item: NavMenuItem): boolean {
    if(!item.hideMenuItemFeatureFlag) {
      return true;
    }

    return !this.featureFlagQuery.getFlag(item.hideMenuItemFeatureFlag);
  }
}
