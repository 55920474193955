import { Inject, Injectable, Injector } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable, catchError, combineLatest, filter, map, switchMap, tap  } from 'rxjs';

import { LoggerService } from 'src/app/logger/logger.service';
import { DataType } from 'src/app/data-types/models/data-types.interface';
import { CustomDataType } from 'src/app/api/models/company-settings/custom-data-types.interface';
import { SystemsService } from 'src/app/systems/state/systems.service';
import { CustomValuesQuery } from 'src/app/company-settings/state/custom-values/custom-values.query';
import { ProcessingActivitiesService } from 'src/app/processing-activities/state/processing-activities.service';
import { DataTypesStore } from 'src/app/data-types/state/data-types.store';
import { DataTypesQuery } from 'src/app/data-types/state/data-types.query';
import { CustomValueTypeEnum } from 'src/app/api/models/company-settings/custom-values.enum';
import { CustomValue } from 'src/app/api/models/company-settings/custom-values.interface';
import { API_CLIENT_COMPANY_SETTINGS_SERVICE } from 'src/app/api/injectors/api-client-company-settings.token';
import { BaseApiClientCompanySettingsService } from 'src/app/api/base-api-client-company-settings.service';

@Injectable({ 
	providedIn: 'root' 
})
export class CustomDataTypesService {
	
	private readonly loggerName: string = 'CustomDataTypesService';

	private customDataTypesChangedSubject = new BehaviorSubject<boolean>(false);
	customDataTypesChanged$ = this.customDataTypesChangedSubject.asObservable();

	constructor(
		private injector: Injector,
		private logger: LoggerService,
		private dataTypesStore: DataTypesStore,
		private dataTypesQuery: DataTypesQuery,
		private customValuesQuery: CustomValuesQuery,
		@Inject(API_CLIENT_COMPANY_SETTINGS_SERVICE) private apiClientCompanySettings: BaseApiClientCompanySettingsService,
	) { }

	loadCustomDataTypes(): Observable<DataType[]> {
		return this.customValuesQuery.selectLoading().pipe(
			filter(res => !res),
			switchMap(() => this.customValuesQuery.selectCustomValuesByType(CustomValueTypeEnum.DataType)),
			map(res => this.convertCustomDataTypesToDataTypes(res)),
			catchError(err => {
				this.logger.error(this.loggerName, `Cant load custom data types from custom values. error message: ${err.message}`);
				return EMPTY;
			}),
		);
	}

	saveNewCustomDataType(name: string): Observable<CustomDataType> {
		return this.apiClientCompanySettings.saveNewCustomDataType(name).pipe(
			tap(res => this.dataTypesStore.add(this.convertCustomDataTypesToDataTypes([res])[0])),
			tap(() => this.customDataTypesChangedSubject.next(true)),
			catchError(err => {
				this.logger.error(this.loggerName, `Cant save new custom data type. data type name: ${name} error message: ${err.message}`);
				return EMPTY;
			}),
		);
	}

	private convertCustomDataTypesToDataTypes(customDataTypes: CustomDataType[] | CustomValue[]): DataType[] {
		return customDataTypes.map(d => ({
			id: d.id,
			name: d.name,
			isCustom: true,
			frameworks: [],
			category: 'Custom',
		})) as DataType[];
	}

	saveCustomDataTypeToStore(customField: CustomValue): void {
		const data = {
			id: customField.id,
			name: customField.name,
			isCustom: true,
			frameworks: [],
			category: 'Custom',
		};

		this.dataTypesStore.add(data);
	}

	saveUpdateCustomDataTypeToStore(customFields: CustomValue[]): void {
		const data: DataType[] = this.convertCustomDataTypesToDataTypes(customFields);
		data.forEach(d => {
			this.dataTypesStore.update(d.id, {name: d.name});
		});
	}

	isCustomDataTypeAlreadyExist(dataTypeName: string): boolean {
		return this.dataTypesQuery.getDataTypesByName(dataTypeName)?.length > 0;
	}

	deleteCustomDataType(id: string): Observable<void> {
		return this.apiClientCompanySettings.deleteCustomDataType(id).pipe(
			tap(() => this.dataTypesStore.remove(id)),
			switchMap(() => this.refreshStores()),
		);
	}

	deleteCustomDataTypeFromStore(id: string): void {
		this.dataTypesStore.remove(id);
	}

	// for refresh relevant stores after delete custom data type. inject the services only if we need to delete
	private refreshStores(): Observable<void> {
		const systemsService = this.injector.get(SystemsService);
		const processingActivitiesService = this.injector.get(ProcessingActivitiesService);
		this.customDataTypesChangedSubject.next(true);
		return combineLatest([
			systemsService.getSystemsFromServer(),
			processingActivitiesService.getProcessingActivities(true),
		]).pipe(map(() => void 0)); 
	  }

	  updateDataTypesSubject(): void {
		this.customDataTypesChangedSubject.next(true);
	  }
}