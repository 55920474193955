import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { Observable, EMPTY, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoggerService } from '../logger/logger.service';
import { RoutesManager } from '../shared/models/routes.interfaces';
import { OAuthAuthorizationResponse } from '../api/models/auth/o-auth-authorization-response';
import { AuthProviders } from '../api/models/auth/auth-providers';
import { CallbackStateEnum } from '../api/models/auth/callback-state-enum';
import { LoginCallbackResponse } from './login-callback-response';
import { LocalStorageHelper, RedirectUrl } from '../services/localStorage-helper';
import { UnauthorizedResponseReason } from '../api/models/auth/unauthorized-response-reason';
import { MineSnackbarType } from '../shared/mine-snackbar/mine-snackbar-type';
import { MineSnackbarService } from '../shared/mine-snackbar/mine-snackbar.service';
import { ContentPipe } from '../services/content/content.pipe';

@Injectable({
	providedIn: 'root'
})
export class AuthResolverService implements Resolve<boolean> {
	private readonly loggerName: string = 'AuthResolverService';

	constructor(
		private authService: AuthService,
		private router: Router,
		private logger: LoggerService,
		private snackbar: MineSnackbarService,
		private contentPipe: ContentPipe,
	) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Observable<never> {
		const params = route.queryParamMap;

		if (params.keys.length) {
			this.logger.debug(this.loggerName, `Received ${params.keys.length} query params`);
			// TODO: Do we still need the scope paramater?
			const scope = params.get('scope');

			const callbackParams = new OAuthAuthorizationResponse();
			callbackParams.code = params.get('code');
			callbackParams.state = params.get('state');
			callbackParams.error = params.get('error');
			callbackParams.errorDescription = params.get('errorDescription');

			this.handleCallback(<CallbackStateEnum>params.get('state'), callbackParams);
			return EMPTY;
		}

		return of(true);
	}
	
	private handleCallback(state: CallbackStateEnum, params: OAuthAuthorizationResponse): void {
		switch(state) {
			case CallbackStateEnum.GoogleLogin:
				this.login(params, AuthProviders.google);
				break;
			case CallbackStateEnum.MicrosoftLogin:
				this.login(params, AuthProviders.microsoft);
				break;
			case CallbackStateEnum.Auth0Login:
				this.login(params, AuthProviders.auth0);
				break;
			case CallbackStateEnum.SsoLogin:
				this.login(params, AuthProviders.sso);
				break;
			default:
				throw Error(`unknown callback state: ${state}`);
		}
	}

	private login(params: OAuthAuthorizationResponse, provider: AuthProviders): void {
		this.logger.debug(this.loggerName, 'login()');

		const loginSub = this.authService.loginCallback(params, provider)
			.subscribe(
				response => {
					this.handleLoginCallbackResponse(response, provider);
					loginSub.unsubscribe();
				});
	}

	private handleLoginCallbackResponse(response: Partial<LoginCallbackResponse>, provider: AuthProviders): void {
		if (response.authenticationSuccess) {
			this.loginCallbackSuccess(response);
		}
		else {
			this.loginCallbackFailure(response, provider);
		}
	}

	private loginCallbackSuccess(response: Partial<LoginCallbackResponse>): void {
		if (LocalStorageHelper.getRedirect() && !response.newUser) {
			const redirectUrl: RedirectUrl = LocalStorageHelper.getRedirect();
			this.logger.info(this.loggerName, `Redirecting to: \'${redirectUrl.url}\' queryParams: ${JSON.stringify(redirectUrl.queryParams)}`);
			this.router.navigate(redirectUrl.url, { queryParams: redirectUrl.queryParams }).then(() => LocalStorageHelper.removeRedirect());
		}
		else {
			const url = response.newUser ? this.getNewUserUrl() : [RoutesManager.homePage];
			this.logger.info(this.loggerName, `Redirecting to: \'${url}\'`);
			this.router.navigate(url).then(() => LocalStorageHelper.removeRedirect());
		}
	}

	private loginCallbackFailure(response: Partial<LoginCallbackResponse>, provider: AuthProviders): void {
		if (response.restartWithConsent) { 
			window.location.href = this.authService.getLoginUrl(provider, true);
		}
		else {
			this.handleLoginCallbackError(response);
		}
	}

	private handleLoginCallbackError(response: Partial<LoginCallbackResponse>): void {
		this.logger.info(this.loggerName, `Signin unauthorized: ${response.unauthorizedResponseReason}`);

		switch (response.unauthorizedResponseReason) {
			case UnauthorizedResponseReason.noPermission:
				window.location.href = environment.routing.permissionsDeclinedUrl;
				return;

			case UnauthorizedResponseReason.unsupportedAccount:
				this.router.navigate([RoutesManager.login]);
				this.snackbar.showTimed(MineSnackbarType.Error, this.contentPipe.transform('common.unsupportedAccount'));
				return;
				
			case UnauthorizedResponseReason.notAllowed:
				window.location.href = environment.routing.pendingVerificationUrl;
				return;

			case UnauthorizedResponseReason.emailUnverified:
				this.router.navigate([RoutesManager.onboarding, RoutesManager.onboarding_verify_auth0]);
				return;
			
			case UnauthorizedResponseReason.alreadyExists:
				this.router.navigate([RoutesManager.login]);
				this.snackbar.showTimed(MineSnackbarType.Error, this.getAlreadyExistMessage(response));
				return;

			case UnauthorizedResponseReason.notAllowedProvider:
				this.router.navigate([RoutesManager.login]);
				this.snackbar.showTimed(MineSnackbarType.Error, this.contentPipe.transform('authentication.providerNotAllowed'));
				return;
			
			default:
				this.router.navigate([RoutesManager.login]);
				this.snackbar.showTimed(MineSnackbarType.Error, this.contentPipe.transform('common.unexpectedError'));
				return;
		}
	}

	private getAlreadyExistMessage(response: Partial<LoginCallbackResponse>): string {
		const provider = response.identityProvider.toLowerCase() as AuthProviders;
		switch (provider) {
			case AuthProviders.google:
				return this.contentPipe.transform('authentication.googleExistMessage');
			case AuthProviders.microsoft:
				return this.contentPipe.transform('authentication.microsoftExistMessage');
			case AuthProviders.auth0:
				return this.contentPipe.transform('authentication.authExistsMessage');

			default:
				return this.contentPipe.transform('common.emailAlreadyExists');
		}
	}

	private getNewUserUrl(): string[] {
		if (LocalStorageHelper.getOnboardingStarted()) {
			LocalStorageHelper.removeOnboardingStarted();
			const onboardingRedirect = [RoutesManager.onboarding, RoutesManager.onboarding_data_mapping, RoutesManager.onboarding_create_account, RoutesManager.onboarding_company];
			return LocalStorageHelper.getRedirect()?.url ?? onboardingRedirect;
		}
		else {
			return [RoutesManager.onboarding, RoutesManager.onboarding_data_mapping];
		}
	}
}
