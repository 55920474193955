import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClientHelperService } from '../../api/http-client-helper.service';
import { FeatureFlagsApiService } from '../../feature-flag/feature-flag.interface';
import { FeatureFlagsResponse } from '../../api/models/profile/profile-feature-flags';
import { FeatureFlags, PublicFeatureFlags } from '../../api/models/profile/profile-feature-flags.enum';

@Injectable({
	providedIn: 'root',
})
export class ApiClientCollaborationFeaturesService implements FeatureFlagsApiService {
	private readonly controller = 'features';
	private readonly collaborationController = 'collaboration/company/features';
	
	constructor(private httpClientHelper: HttpClientHelperService) {}

	getFeatureFlags(list: FeatureFlags[]): Observable<FeatureFlagsResponse> {
		return this.httpClientHelper.invokePostAuth<FeatureFlagsResponse>(`${this.collaborationController}`, { featureKeys: list });
	}

	getPublicFeatureFlags(list: PublicFeatureFlags[]): Observable<{ [key: string]: string }> {
		return this.httpClientHelper.invokePost<{ [key: string]: string }>(`${this.controller}/Public`, { featureKeys: list });
	}
}
