import { Component, ChangeDetectionStrategy, Input, forwardRef, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { InputFocus, InputType } from './mine-input.enum';

@Component({
  standalone: true,
  selector: 'mine-input',
  templateUrl: './mine-input.component.html',
  styleUrls: ['./mine-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule],
  providers: [{       
    provide: NG_VALUE_ACCESSOR, 
    useExisting: forwardRef(() => MineInputComponent),
    multi: true     
  }],
})
export class MineInputComponent implements ControlValueAccessor, AfterViewInit {
  @Input() id: string; //unique id is a must
  @Input() required: boolean;
  @Input() requiredBgColor: string = 'inherit';
  @Input() inputBgColor: string = 'inherit';
  @Input() placeholder: string = '';
  @Input() error = '';
  @Input() focus = false;
  @Input() type = InputType.Text;
  @Output() focusEvent: EventEmitter<InputFocus> = new EventEmitter<InputFocus>();
  
  @ViewChild('input') input: ElementRef<any>;

  value: string;
  disabled = false;
  readonly maxDate = new Date();

  inputFocus = InputFocus;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    if (this.focus) {
      setTimeout(() => {
        this.input.nativeElement.focus();
      }, 0);
    }
  }

  writeValue(value: string): void {
    this.value = value;
    if (this.input) {
      this.input.nativeElement.value = value;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
    if (this.input) {
      this.renderer.setProperty(this.input.nativeElement, 'disabled', isDisabled);
    }
	}

  onChange = (val: string) => {};
  onTouched = (val: string) => {};

  onFocus(focusType: InputFocus): void {
    if (this.focusEvent) {
      this.focusEvent.emit(focusType);
    }
  }
}