import { Injectable, Signal } from "@angular/core";
import { Observable } from "rxjs";
import { DsrTemplatesStore } from "./dsr-templates.store";
import { DsrShallowTemplate } from "../../../../api/models/dsr/dsr-templates.interface";

@Injectable({
    providedIn: 'root'
})
export class DsrTemplatesQuery {

    constructor(
        private store: DsrTemplatesStore,
    ) { }

    setLoading(loading: boolean): void {
        this.store.set({ 'loading': loading });
    }

    getLoading(): Signal<boolean | undefined> {
        return this.store.signal('loading');
    }

    selectLoading(): Observable<boolean | undefined> {
        return this.store.select('loading');
    }

    setTemplates(templates: DsrShallowTemplate[]): void {
        const mapTemplates = new Map<string, DsrShallowTemplate>();
        templates.forEach(template => {
            mapTemplates.set(template.id, template);
        });
        
        this.store.set({ 'templates': mapTemplates });
    }

    setTemplatesMap(templates: Map<string, DsrShallowTemplate>): void {
        this.store.set({ 'templates': templates });
    }

    getTemplates(): Signal<Map<string, DsrShallowTemplate>> {
        return this.store.signal('templates');
    }

    selectTemplates(): Observable<Map<string, DsrShallowTemplate>> {
        return this.store.select('templates');
    }

}