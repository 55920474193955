import { Injectable } from "@angular/core";
import { RxState } from "@rx-angular/state";
import { LocalizationState } from "../models/localization-state.interface";

@Injectable({
    providedIn: 'root'
})
export class DsrLocalizationStore extends RxState<LocalizationState> {

    private readonly initState = {
        loading: true,
        languagesMap: null
    } as LocalizationState;

    constructor() {
        super();
        this.set({...this.initState}); // set the initial state of the store
    }

}