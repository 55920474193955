import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BaseApiClientCompanySettingsService } from "src/app/api/base-api-client-company-settings.service";

import { HttpClientHelperService } from "src/app/api/http-client-helper.service";
import { CustomFieldV2 } from "src/app/api/models/company-settings/custom-fields.interface";
import { CustomValuesResponse } from "src/app/api/models/company-settings/custom-values.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiClientCollaborationCompanySettingsService extends BaseApiClientCompanySettingsService {
  protected readonly controller: string = 'collaboration/settings';

  constructor(httpClientHelper: HttpClientHelperService) {
    super(httpClientHelper);
  }

  override getCustomValues(): Observable<CustomValuesResponse> {
		return this.httpClientHelper.invokeGetAuth<CustomValuesResponse>(`${this.controller}/custom-values`);
	}

  override getCustomFields(): Observable<CustomFieldV2[]> {
		return this.httpClientHelper.invokeGetAuth<CustomFieldV2[]>(`${this.controller}/custom-fields`);
	}
}
