import { FeedbackStateEnum, FeedbackTypeEnum } from './../../api/models/feedback/feedback.enum';
import { Feedback, FeedbackRequest } from './../../api/models/feedback/feedback.interface';
import { ApiClientFeedbackService } from './../../api/api-client-feedback.service';
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, tap, switchMap, first, map } from "rxjs";
import { CatalogSystemIcon, CustomSystemIcon, SystemIconV2, SystemsIconsV2Response, UnverifiedSystem } from "src/app/api/models/systems/systems.interface";
import { LoggerService } from "src/app/logger/logger.service";
import { SystemsIconsService } from "src/app/systems/state/systems-icons.service";
import { UnverifiedSystemsStore } from "./unverified-systems.store";
import { MappingProgressResponse } from 'src/app/api/models/data-mapping/data-mapping.interface';
import { ApiClientDataMappingService } from 'src/app/api/api-client-data-mapping.service';
import { DataSourceOriginEnum } from 'src/app/api/models/systems/systems.enum';
import { SpecialSystemType } from 'src/app/systems/models/custom-system-type.enum';
import { BaseApiClientSystemsService } from 'src/app/api/base-api-client-systems.service';
import { API_CLIENT_SYSTEMS_SERVICE } from 'src/app/api/injectors/api-client-systems-service.token';

@Injectable({
	providedIn: 'root',
})
export class UnverifiedSystemsService {
    private readonly loggerName: string = 'UnverifiedSystemsService';

    employeesScanNotCompleted = new BehaviorSubject<string[]>([]); // employees their scan didn't finished
    employeesScanNotCompleted$ = this.employeesScanNotCompleted.asObservable();

    constructor(
        private logger: LoggerService,
        private systemsIconsService: SystemsIconsService,
        private unverifiedSystemsStore: UnverifiedSystemsStore,
        private apiClientFeedback: ApiClientFeedbackService,
        private apiClientDataMappingService: ApiClientDataMappingService,
        @Inject(API_CLIENT_SYSTEMS_SERVICE) private apiClientSystems: BaseApiClientSystemsService,
    ) { }

    init(): Observable<void> {
		this.logger.debug(this.loggerName, 'init()');
        return this.getUnverifiedSystemsFromServer();
	}

    getUnverifiedSystemsFromServer(): Observable<void> {
        return this.apiClientSystems.getUnverifiedSystems().pipe(
            first(),
            map(res => res?.result),
            switchMap(res => this.getSystemsWithIcons(res)),
            map(res => this.getUpdatedSystem(res)),
            map(systems => this.updateSystemsStore(systems)),            
        );
    }

    getUnverifiedSystem(systemId: string): Observable<UnverifiedSystem> {
        return this.apiClientSystems.getUnverifiedSystem(systemId).pipe(     
        );
    }

    //check - if not type email - change usage to null,
    //check - if not type email, sso, okta - change connected accounts to null
    private getUpdatedSystem(unverifiedSystems: UnverifiedSystem[]): UnverifiedSystem[] {
        const validOriginMethods = [DataSourceOriginEnum.Email, DataSourceOriginEnum.GoogleSso, DataSourceOriginEnum.OktaSso, DataSourceOriginEnum.EntraSso];

        return unverifiedSystems.map(system => {
            const updatedSystem = { ...system };
            if (!system.dataSourceOrigin.some(method => validOriginMethods.includes(method))) {
                updatedSystem.connectedAccounts = null;
                !!updatedSystem.systemUsage && (updatedSystem.systemUsage.discoveryScore = null);
              } else {
                if (!system.dataSourceOrigin.includes(DataSourceOriginEnum.Email)) {
                    !!updatedSystem.systemUsage && (updatedSystem.systemUsage.discoveryScore = null);
                }
                if (!system.dataSourceOrigin.some(method => validOriginMethods.includes(method))) {
                    updatedSystem.connectedAccounts = null;
                }
              }
              return updatedSystem;

          });
    }

    private updateSystemsStore(unverifiedSystems: UnverifiedSystem[] = []): void {
        this.unverifiedSystemsStore.set(unverifiedSystems);
    }

    private getSystemsWithIcons(unverifiedSystems: UnverifiedSystem[] = []): Observable<UnverifiedSystem[]> {
        const systems = this.compileSystemsIconsPayload(unverifiedSystems);
        return this.systemsIconsService.getSystemsIconsV2({ systems }).pipe(
            map(iconsResponse => this.setIcons(unverifiedSystems, iconsResponse)),
            tap(() => this.logger.info(this.loggerName, 'set icons of unverified systems (V2)')),
        );
    }

    private compileSystemsIconsPayload(unverifiedSystems: UnverifiedSystem[] = []): SystemIconV2[] {
        const customSystemTypes: string[] = Object.values(SpecialSystemType);
        const customSystems: CustomSystemIcon[] = [];
        const regularSystems: Record<string, CatalogSystemIcon> = {};
        unverifiedSystems.forEach(({ systemType, systemId }) => { 
            if (customSystemTypes.includes(systemType)) {
                customSystems.push({ systemId });
            }
            regularSystems[systemType] = { systemType };
        });
        return [...customSystems, ...Object.values(regularSystems)];
    }

    private setIcons(unverifiedSystems: UnverifiedSystem[], iconsResponse: SystemsIconsV2Response): UnverifiedSystem[] {
        const customSystemTypes: string[] = Object.values(SpecialSystemType);
        const { customSystemIcons, iconsByService } = iconsResponse;
        return unverifiedSystems.map(system => {
            const iconPath = customSystemTypes.includes(system.systemType) ? customSystemIcons[system.systemId] : iconsByService[system.systemType];
            return {
                ...system,
                iconPath
            };
        });
    }

    removeUnverifiedSystemsFromStore(systems: string[]): void {
        this.unverifiedSystemsStore.remove(systems);
    }

    moveFromUnverifiedToTrash(systemIds: string[]): Observable<void> {
        return this.apiClientSystems.moveToTrash(systemIds).pipe(
            tap(() => this.removeUnverifiedSystemsFromStore(systemIds)),
        );
    }

    moveFromUnverifiedToInventory(systemIds: string[]): Observable<void> {
        return this.apiClientSystems.moveToInventory(systemIds).pipe(
            tap(() => this.removeUnverifiedSystemsFromStore(systemIds)),
        );
    }

    askFeedback(systemIds: string[], employeeId: string): Observable<void> {
        const body: FeedbackRequest = {
            feedbackTypes: [FeedbackTypeEnum.System],
            systemIds,
            assignedReviewerId: employeeId
        };

        return this.apiClientFeedback.requestFeedback(body).pipe(
            map(() => this.unverifiedSystemsStore.update(systemIds, {generalFeedbackState: FeedbackStateEnum.Pending}))
        );
    }

    getFeedbacks(systemId: string): Observable<Feedback[]> {
        return this.apiClientFeedback.getSystemFeedbacks(systemId).pipe(
            map(response => response.feedbackData.filter(feedback => feedback.feedbackState !== FeedbackStateEnum.Canceled)),
        );
    }

    updateFeedbackState(systemId: string, feedbackId: string, state: FeedbackStateEnum): Observable<void> {
        return this.apiClientFeedback.agentUpdateFeedbackState(feedbackId, state).pipe(
            tap(() => this.unverifiedSystemsStore.update(systemId, {generalFeedbackState: FeedbackStateEnum.None}))
        );
    }

    getRadarProgress(): Observable<MappingProgressResponse> {
		return this.apiClientDataMappingService.getDataMappingProgress();
	}
}
