import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { FeatureFlagQuery } from 'src/app/feature-flag/state/feature-flag.query';
import { Observable } from 'rxjs';
import { RoutesManager } from '../models/routes.interfaces';

export const UnderMaintenanceGuard: CanActivateFn = (route, state): Observable<boolean | UrlTree>=> {
  const featureFlag = route.data.featureFlag;

  const featureFlagQuery = inject(FeatureFlagQuery);
  const router = inject(Router);

  return featureFlagQuery.selectFlag(featureFlag).pipe(
    map(isUnderMaintenance => isUnderMaintenance ? router.parseUrl(`/${RoutesManager.underMaintenance}`) : true)
  );
};