import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { catchError, delay, EMPTY, iif, mergeMap, Observable, of, retryWhen, tap, throwError } from "rxjs";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";

import { environment } from "src/environments/environment";
import { LoggerService } from "src/app/logger/logger.service";
import { ContentPipe } from "src/app/services/content/content.pipe";
import { RoutesManager } from "src/app/shared/models/routes.interfaces";
import { MineSnackbarType } from "src/app/shared/mine-snackbar/mine-snackbar-type";
import { MineSnackbarService } from "src/app/shared/mine-snackbar/mine-snackbar.service";
import { CollaborationTokenStoreService } from "./collaboration-token-store.service";

@Injectable({
    providedIn: "root"
})
export class CollaborationHttpErrorsInterceptor implements HttpInterceptor {
	private readonly loggerName: string = "HttpErrorsInterceptor";
	
	constructor(
		private router: Router,
		private logger: LoggerService,
		private contentPipe: ContentPipe,
		private snackbarService: MineSnackbarService,
		private tokenStore: CollaborationTokenStoreService,
	) {}
   
	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			catchError((error: HttpErrorResponse) => {
				if (error.error === "missing_refresh_token") {
                    this.logger.debug(this.loggerName, `Missing refresh token`);
                }
				if (error.status >= 400 && error.status < 500) {
					return this.handleClientErrors(error, req, next);
				}
				else if (error.status >= 500 || error.status == 0) {
					return this.handleServerErrors(error, req, next);
				}
				else {
					this.handleErrorLogging(`General HTTP error. error code: ${error.status}, error message: ${error.message}`);
					return throwError(() => error);
				}
			})
		);
	}
	
	private handleServerErrors(error: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
		let retryDelay = 500;
		let retries = 1;
		
		return next.handle(req).pipe(
			retryWhen(errors => errors.pipe(
				mergeMap(err => 
					iif(() => retries === environment.api.errorHandling.maxNumOfRetries, 
						throwError(() => err).pipe(
							tap(() => this.handleMaxRetryError(err))
						),
						of(err).pipe(
							tap(() => this.logger.error(this.loggerName, `retry number ${retries} for http call: ${err.url}`)),
							tap(() => retries++),
							delay(retryDelay *= 2))
						)
					)
				)
			)
		);
	}

	private handleMaxRetryError(error: HttpErrorResponse): void {
		this.handleErrorLogging(
			`Finish retries, HTTP server side error - error code: ${error.status}, error message: ${error.message}`
		);
	
		this.router.navigate([RoutesManager.collaborationLogout], {
			queryParams: {
				redirectTo: `${window.location.origin}/${RoutesManager.collaboration}/${RoutesManager.collaborationExpiredLink}`,
				title: `Status: ${error.status}`,
				text: error.message,
			},
		});
	}

	private handleClientErrors(error: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
		if (!this.tokenStore.getAccessToken()) {
			this.logger.info(this.loggerName, `User not authenticated`);
			this.router.navigate([RoutesManager.collaborationLogout], {
				queryParams: {
					redirectTo: `${window.location.origin}/${RoutesManager.collaboration}/${RoutesManager.collaborationExpiredLink}`,
					...this.contentPipe.transform('ai-assessments-collaboration.collaborationAuthError')
				},
			});
			return EMPTY;
		}

		//401 - not authenticated
		//403 - forbidden
		//440 - session expired
		if (error.status === 401 || error.status === 403 || error.status === 440) {
			return of(
				this.router.navigate([RoutesManager.collaborationLogout], {
					queryParams: {
						redirectTo: `${window.location.origin}/${RoutesManager.collaboration}/${RoutesManager.collaborationExpiredLink}`,
						...this.contentPipe.transform('ai-assessments-collaboration.collaborationRevokedError', {
							params: {
								companyName: this.tokenStore.getCompanyName()
							}
						})
					},
				})
			);
		}
		else {
			this.handleErrorLogging(`HTTP client side error - error code: ${error.status}, error message: ${error.message}`);
			this.snackbarService.showTimed(MineSnackbarType.Error, this.contentPipe.transform('common.defaultError'));
		}
	}

	private handleErrorLogging(logMsg: string): void {
		console.error(logMsg);
		this.logger.error(this.loggerName, logMsg);
	}
}
