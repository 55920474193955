import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { UserProperties } from './user-properties';

import { produce } from 'immer';

export function createInitialState(): UserProperties {
	return {
        Email: undefined,
        Id: undefined,
        FirstName: undefined,
        LastName: undefined,
        CompanyId: undefined,
        CompanyName: undefined,
        PlanName: undefined,
        PlanLastModifiedAt: undefined,
        TotalRequests: undefined,
        ClosedRequests: undefined,
        OpenRequests: undefined,
        UnverifiedRequests: undefined,
        Role: undefined,
        //RejectRuleEnabled: undefined,
        //RejectResponseEnabled: undefined,
        //AckResponseEnabled: undefined,
        //CompletedResponseEnabled: undefined,
        //CanceledResponseEnabled: undefined,
        IsVerified: undefined,
        IdentityProvider: undefined,
        ClosedTicketsInLast30Days: undefined,
        ConnectedToMineStatus: undefined
		};
	};

@Injectable({ 
  	providedIn: 'root' 
})
@StoreConfig({ 
  name: 'userProperties',
	idKey: 'id',
  producerFn: produce
})
export class UserPropertiesStore extends Store<UserProperties> {

  constructor() {
    super(createInitialState());
  }

}
