import { FeatureFlagQuery } from 'src/app/feature-flag/state/feature-flag.query';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { map, Observable } from 'rxjs';
import { AutomationType } from 'src/app/api/models/automations/automation-type.enum';
import { Automation } from 'src/app/api/models/automations/automations.interface';
import { CompanySettingsQuery } from 'src/app/company-settings/state/company-settings.query';
import { AutomationState, AutomationStore } from './automations.store';

@Injectable({
	providedIn: 'root'
})
export class AutomationsQuery extends QueryEntity<AutomationState> {

	private readonly templates = [
		AutomationType.CompletedConfiguration,
		AutomationType.RejectConfiguration,
		AutomationType.CanceledConfiguration,
		AutomationType.CopyCompletedConfiguration,
		AutomationType.CopyRejectConfiguration,
		AutomationType.CopyCanceledConfiguration,
	];

	private readonly rightToEdit = [
		AutomationType.RightToEditCompleteConfig,
		AutomationType.RightToEditRejectConfig,
		AutomationType.RightToEditCancelConfig
	];
	
	private readonly doNotMail = [
		AutomationType.DoNotMailCompleteConfig,
		AutomationType.DoNotMailRejectConfig,
		AutomationType.DoNotMailCancelConfig
	];

	private readonly doNotSell = [
		AutomationType.DoNotSellCancelConfig,
		AutomationType.DoNotSellCompleteConfig,
		AutomationType.DoNotSellRejectConfig
	];

	constructor(protected store: AutomationStore, private settingsQuery: CompanySettingsQuery, private featureFlagQuery: FeatureFlagQuery) {
		super(store);
	}

	selectStatus(): Observable<Automation[]> {
		return this.selectActive(({ automations }) => automations).pipe(
			map((items) => items.filter((item) => item.id === AutomationType.AckConfiguration))
		);
	}

	selectTemplatesOnly(): Observable<Automation[]> {
		return this.selectActive(({ automations }) => automations).pipe(
			map((items) => items.filter((item) => item.id !== AutomationType.AckConfiguration))
		);
	}

	selectTemplatesForRights(): Observable<Automation[]> {
		return this.selectEntity('en-US').pipe(
			map(entity => entity.automations),
			map(items => items.filter((item) => item.id !== AutomationType.AckConfiguration))
		);
	}

	getAutomation(type: string): Automation {
		const entity = this.getActive();
		return entity.automations.find(item => item.id === type);
	}

	selectAutomation(type: string): Observable<Automation> {
		return this.selectActive(({ automations }) => automations).pipe(
			map((items) => items.find((auto) => auto.id === type))
		);
	}

	selectManyAutomations(types: string[]) {
		return this.selectActive(({ automations }) => automations).pipe(
			map((items) => items.filter((item) => types.includes(item.id)))
		);
	}

	getManyAutomations(types: string[]): Automation[] {
		const automations = this.getActive().automations;
		return automations.filter((item) => types.includes(item.id));
	}
	
	private setTemplatesTypes(doNotSell: boolean, doNotMail: boolean, rightToEdit: boolean) {
		let tpls = [...this.templates];

		if (doNotSell) {
			tpls = [...tpls, ...this.doNotSell];
		}
		if (doNotMail) {
			tpls = [...tpls, ...this.doNotMail];
		}
		if (rightToEdit) {
			tpls = [...tpls, ...this.rightToEdit];
		}

		return tpls;
	}
}
