import { Injectable } from '@angular/core';
import { tap, first, Observable, map, filter, switchMap } from 'rxjs';
import { LoggerService } from '../../../../logger/logger.service';
import { ApiClientDsrTemplatesService } from "../../../../api/dsr/api-client-dsr-templates.service";
import { DsrTemplatesQuery } from "../state/dsr-templates.query";
import {
  DsrExtendedTemplate,
  DsrTemplateUsages,
  DsrTemplateUsagesResponse
} from 'src/app/api/models/dsr/dsr-templates.interface';
import { DsrShallowTemplate } from "../../../../api/models/dsr/dsr-templates.interface";
import { DropdownOption } from "../../../../shared/mine-dropdown/mine-dropdown.interface";
import { DsrLocalizationQuery } from "../../dsr-localization/state/dsr-localization.query";

@Injectable({
    providedIn: 'root'
})
export class DsrTemplatesService {
    private readonly loggerName = 'DsrTemplatesService';

    constructor(
        private loggerService: LoggerService,
        private apiClientDsrTemplateService: ApiClientDsrTemplatesService,
        private dsrTemplatesQuery: DsrTemplatesQuery,
        private dsrLocalizationQuery: DsrLocalizationQuery
    ) { }

    initTemplates(): void {
        this.apiClientDsrTemplateService.getTemplatesShallow().pipe(
            first(),
            tap(templates => this.dsrTemplatesQuery.setTemplates(templates)),
            tap(() => this.loggerService.info(this.loggerName, 'get templates data')),
            tap(() => this.dsrTemplatesQuery.setLoading(false)))
            .subscribe();
    }

    deleteTemplateInStore(id: string): void {
        const templatesMap = this.dsrTemplatesQuery.getTemplates()();
        if (templatesMap.has(id)) {
            templatesMap.delete(id);

            this.dsrTemplatesQuery.setTemplatesMap(templatesMap);
        }
    }

    updateTemplateInStore(id: string, template: Partial<DsrExtendedTemplate>): void {
        const templatesMap = this.dsrTemplatesQuery.getTemplates()();
        if (templatesMap.has(id)) {
          const updatedTemplate = { ...templatesMap.get(id), ...template };
          templatesMap.set(id, updatedTemplate);
    
          this.dsrTemplatesQuery.setTemplatesMap(templatesMap);
        }
    }

    selectTemplateDropdownOptions(): Observable<DropdownOption[]> {
        return this.dsrTemplatesQuery.selectTemplates()
            .pipe(
                map(templates => Array.from(templates.values())),
                map(templates => templates.map(template => ({ id: template.id, value: template.displayName })))
            );
    }

    selectManyTemplateDropdownOptions(templateIds: string[]): Observable<DropdownOption[]> {
        return this.selectTemplateDropdownOptions().pipe(
            map(templates => templateIds.length > 0 ? templates.filter(t => templateIds.includes(t.id)) : templates), // when no templates to filter, we return all templates
        );
    }

    createTemplate(templateName: string): Observable<DsrShallowTemplate> {
        return this.apiClientDsrTemplateService.createTemplate(templateName).pipe(
            tap((newTemplate: DsrShallowTemplate) => this.onCreateTemplate(newTemplate)),
            tap(() => this.loggerService.info(this.loggerName, `create template: ${templateName}`))
        );
    }

    getTemplate(id: string): DsrShallowTemplate {
        return this.dsrTemplatesQuery.getTemplates()().get(id);
    }

    private onCreateTemplate(newTemplate: DsrShallowTemplate): void {
        const templates = this.dsrTemplatesQuery.getTemplates()();
        templates.set(newTemplate.id, newTemplate);
        this.dsrTemplatesQuery.setTemplatesMap(templates);
    }

    getTemplateData(templateId: string): Observable<DsrExtendedTemplate> {
        return this.dsrLocalizationQuery.selectLoading().pipe(
            filter(res => !res),
            switchMap(() => this.apiClientDsrTemplateService.getTemplate(templateId)),
            map(template => this.getCompletedTempalte(template)),
        );
    }

    selectTemplatesUsage(): Observable<Map<string, DsrTemplateUsages>> {
      return this.apiClientDsrTemplateService.getTemplatesUsage().pipe(
        map(response => this.templatesUsageArrayToMap(response))
      );
    }

    selectTemplateUsage(templateId: string): Observable<DsrTemplateUsages> {
        return this.apiClientDsrTemplateService.getTemplateUsage(templateId);
    }

    private templatesUsageArrayToMap(response: DsrTemplateUsagesResponse): Map<string, DsrTemplateUsages> {
      const usages = response.templateIdToUsages;
      const usageMap = new Map<string, DsrTemplateUsages>();
      usages.forEach(usage => usageMap.set(usage.templateId, usage.templateUsages));
      return usageMap;
    }

    private getCompletedTempalte(template: DsrExtendedTemplate): DsrExtendedTemplate {
        const languages = this.dsrLocalizationQuery.getLanguagesMap()();
        const completedLocales = Object.fromEntries(
            [...languages.keys()].map((key) => [
                key,
                template.locales?.[key] || { body: "" }
            ])
        );
        return { ...template, locales: completedLocales };
    }
}