// CORE //
import { JL } from 'jsnlog';
import { Observable } from 'rxjs';
import { CoreModule } from '../core/core.module';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, UrlSerializer } from '@angular/router';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// SHARED //
import { GlobalErrorHandler } from '../services/global-error-handler';
import { HttpLoggerInterceptor } from '../api/http-logger.interceptor';
import { HtmlDecodePipe } from '../shared/pipes/html-decode.pipe';
import { SentenceCasePipe } from '../shared/pipes/sentence-case.pipe';
import { SplitUpperCasePipe } from '../shared/pipes/split-upper-case.pipe';
import { CamelCaseWithSpacesPipe } from '../shared/pipes/camel-case-with-spaces.pipe';
import { LowerCaseFirstLetterPipe } from '../shared/pipes/lower-case-first-letter.pipe';
import { AiAssessmentStatusPipe } from '../ai-assessments/pipes/ai-assessment-status.pipe';
import { RiskCalulationPipe } from '../processing-activities/services/risk-calulation.pipe';
import { ConvertPiiTypeToDataTypePipe } from '../shared/pipes/convert-pii-type-to-data-type.pipe';
import { MineSnackbarComponent } from '../shared/mine-snackbar/mine-snackbar.component';

// SERVICES //
import { CustomUrlSerializer } from '../services/custom-url-serializer.service';
import { CollaborationBootstrapService } from './services/collaboration-bootstrap.service';
import { ApiClientCollaborationFeaturesService } from './api/api-client-collaboration-features.service';
import { ApiClientCollaborationSystemsService } from './api/api-client-collaboration-systems.service';
import { CollaborationHttpErrorsInterceptor } from './services/collaboration-http-errors.interceptor';
import { CollaborationHttpHeadersInterceptor } from './services/collaboration-http-header.interceptor';
import { CollaborationUserPropertiesService } from './services/collaboration-user-properties.service';
import { ApiClientCollaborationRisksService } from './api/api-client-collaboration-risks.service';
import { ApiClientCollaborationEmployeesService } from './api/api-client-collaboration-employees.service';
import { ApiClientCollaborationAssessmentsService } from './api/api-client-collaboration-assessments.service';
import { ApiClientCollaborationCompanySettingsService } from './api/api-client-collaboration-company-settings.service';

// MODULES //
import { ContentPipeModule } from '../services/content/content-pipe.module';
import { CollaborationRoutingModule } from './collaboration-routing.module';

// INJECTORS //
import { API_CLIENT_RISKS_SERVICE } from '../api/injectors/api-client-risks.token';
import { API_CLIENT_EMPLOYEES_SERVICE } from '../api/injectors/api-client-employees.token';
import { API_CLIENT_COMPANY_SETTINGS_SERVICE } from '../api/injectors/api-client-company-settings.token';
import { API_CLIENT_ASSESSMENTS_SERVICE } from '../api/injectors/api-client-ai-assessments.token';
import { API_CLIENT_SYSTEMS_SERVICE } from '../api/injectors/api-client-systems-service.token';
import { FEATURE_FLAGS_API_SERVICE } from '../feature-flag/feature-flag-injection-token';
import { USER_PROPERTIES_SERVICE } from '../analytics/user-properties-injection-token';

// COMPONENTS //
import { CollaborationComponent } from './collaboration.component';

function initApp(bootstrapService: CollaborationBootstrapService): () => Observable<void> {
	return () => bootstrapService.preLoad();
}

const pipes = [
	DatePipe,
	TitleCasePipe,
	HtmlDecodePipe,
	SentenceCasePipe,
	SplitUpperCasePipe,
  RiskCalulationPipe,
  AiAssessmentStatusPipe,
	CamelCaseWithSpacesPipe,
  LowerCaseFirstLetterPipe,
  ConvertPiiTypeToDataTypePipe,
];

@NgModule({
  declarations: [
    CollaborationComponent,
  ],
  imports: [
    CoreModule,
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    MineSnackbarComponent,
    ContentPipeModule,
    RouterModule,
    HttpClientModule,
    CollaborationRoutingModule,
  ],
  providers: [
    [...pipes],
    { provide: 'JSNLOG', useValue: JL },
    { provide: APP_INITIALIZER, useFactory: initApp, deps: [CollaborationBootstrapService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpLoggerInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: CollaborationHttpErrorsInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CollaborationHttpHeadersInterceptor, multi: true },
    { provide: FEATURE_FLAGS_API_SERVICE, useClass: ApiClientCollaborationFeaturesService },
    { provide: USER_PROPERTIES_SERVICE, useClass: CollaborationUserPropertiesService },
    { provide: API_CLIENT_SYSTEMS_SERVICE, useClass: ApiClientCollaborationSystemsService },
    { provide: API_CLIENT_COMPANY_SETTINGS_SERVICE, useClass: ApiClientCollaborationCompanySettingsService },
    { provide: API_CLIENT_ASSESSMENTS_SERVICE, useClass: ApiClientCollaborationAssessmentsService },
    { provide: API_CLIENT_RISKS_SERVICE, useClass: ApiClientCollaborationRisksService },
    { provide: API_CLIENT_EMPLOYEES_SERVICE, useClass: ApiClientCollaborationEmployeesService },
		{ provide: ErrorHandler, useClass: GlobalErrorHandler, },
		{ provide: UrlSerializer, useClass: CustomUrlSerializer },
  ],
  bootstrap: [CollaborationComponent]
})
export class CollaborationAppModule {}