<label class="dropdown__header" [attr.for]="id">
    <ng-content select="[mine-dropdown-label]"></ng-content>
    <span *ngIf="required" class="dropdown__header--required">&nbsp;*</span>    
</label>

<div
	class="dropdown__wrapper"
	[ngClass]="{
		'dropdown__wrapper--error': !!error,
		'dropdown__wrapper--disabled': !!disabled,
		'dropdown__wrapper--focus': listOpen
	}"
	[attr.id]="id"
	tabindex="0">

	<input
		[attr.data-label]="'Dropdown: ' + id"
		type="checkbox"
		[(ngModel)]="listOpen"
		class="dropdown__checkbox"
		id="dropdown-{{ id }}"
	/>
	<label for="dropdown-{{ id }}" class="dropdown__label" >
		<span class="dropdown__label-prefix">
			<ng-content ></ng-content>
		</span>
		<span class="dropdown__placeholder" *ngIf="placeholder && !selectedValue && !searchTerm">{{ placeholder }}</span>
		<span *ngIf="searchTerm && !selectedValue"> {{searchTerm}} </span>
		<span *ngIf="selectedValue" class="dropdown__selected-value" [value]="selectedValue.value" tp tpUseTextContent [tpOnlyTextOverflow]="true">
			<ng-container *ngIf="selectedValuePrefix; else selectedValueTpl">
				<span class="dropdown__selected-value-prefix">{{ selectedValuePrefix }}</span>
				<span class="dropdown__selected-value dropdown__selected-value--bold">{{ selectedValue.value }}</span>
			</ng-container>

			<ng-template #selectedValueTpl>
				{{ selectedValue.value }}
			</ng-template>
		</span>

		<svg class="dropdown__arrow">
			<use xlink:href="../../../assets/img/sprite.svg#icon-triangle-arrow---Regular-state"></use>
		</svg>
	</label>

	<ul *ngIf="listOpen" #dropdown class="dropdown__panel">
		<li #option *ngFor="let option of options; trackBy: trackByFn"
            data-cy="dropdown__option"
			class="dropdown__option"
			[class.dropdown__option--indent]="option.indent"
			[ngClass]="{ 'dropdown__option-disabled' : option.disabled }"
			[attr.data-indent]="option.indent"
			[attr.data-label]="option.value"
			[tp]="option.value"
			[tpOnlyTextOverflow]="true"
			(click)="onOptionChanged(option)">
			{{ option.value }}
		</li>

		<ng-container *ngIf="hasCustom">
            <li class="dropdown__option">
                <ng-content select="[custom-option]"></ng-content>
            </li>
        </ng-container>
	</ul>
</div>

<span *ngIf="error !== ''" class="dropdown__error">{{error}}</span>