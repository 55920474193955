import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { TrashSystemsColumnKeys } from './trash-systems.enum';
import { TrashSystem } from 'src/app/api/models/systems/systems.interface';
import { ProfileQuery } from 'src/app/profile/state/profile.query';
import { CustomValuesQuery } from 'src/app/company-settings/state/custom-values/custom-values.query';
import { CustomValueTypeEnum } from 'src/app/api/models/company-settings/custom-values.enum';
import { CustomValue } from 'src/app/api/models/company-settings/custom-values.interface';

@Injectable()
export class TrashSystemsTableCsvHelper {
    reasons: CustomValue[];

    constructor(
        private datePipe: DatePipe,
        private profileQuery: ProfileQuery,
        private customValuesQuery: CustomValuesQuery,
    ) { }

    getValueByKey(system: TrashSystem, key: TrashSystemsColumnKeys): string {
      this.reasons = this.customValuesQuery.getCustomValuesByType(CustomValueTypeEnum.DecommissionJustification);

      const values: Record<TrashSystemsColumnKeys, string> = {
        name: system.name ?? '-',
        removalReason: this.getReason(system.removalReason),
        updatedBy: this.getUpdatedBy(system),
        lastUpdate: system.removeDate ? this.datePipe.transform(system.removeDate, 'MMM dd, yyyy') : '-',
      };
      return values[key];
    }

    getUpdatedBy(system: TrashSystem): string {
        if (system.removedBy) {
            return this.profileQuery.getAgentById(system.removedBy)?.name ?? '-';
        }
        return '-';
    }

    getReason(customValueId: string): string {
      return this.reasons.find(({ id }) => id === customValueId)?.name ?? '-';
    }
}