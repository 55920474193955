import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpClientHelperService } from './http-client-helper.service';
import {
  Branding,
  CompanySettings,
  Inventory,
  Customization,
  Workflow,
  Security,
  CustomField,
  Activities,
  RopaDetails,
  CopyPreview,
  CustomDataSubjects,
  IconUploadResponse,
  DebugModeSettings
} from './models/company-settings/company-settings.interface';
import { JiraConnection, JiraSitesResponse, JiraProjectsResponse, JiraIssueTypesResponse } from './models/company-settings/jira.interface';
import { CustomFramework, CustomFrameworksResponse } from './models/company-settings/frameworks.interface';
import { CustomDataType } from './models/company-settings/custom-data-types.interface';
import { CustomValue, CustomValuesResponse } from './models/company-settings/custom-values.interface';
import { CustomValueTypeEnum } from './models/company-settings/custom-values.enum';
import { CustomFieldV2 } from './models/company-settings/custom-fields.interface';
import {JiraCallbackResponse, JiraConnectResponse} from "./models/developers/jira.interface";

@Injectable({
	providedIn: 'root'
})
export class BaseApiClientCompanySettingsService {

	protected readonly controller: string = 'settings';
	
	constructor(protected httpClientHelper: HttpClientHelperService) { }

	getCompanySettings(): Observable<CompanySettings> {
		return this.httpClientHelper.invokeGetAuth<CompanySettings>(`${this.controller}/CompanySettings`);
	}

	updateInventory(body: Inventory): Observable<Inventory> {
		return this.httpClientHelper.invokePostAuth<Inventory>(`${this.controller}/Inventory`, body);
	}

	updateCustomization(customization: Customization): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/Customization`, customization);
	}

	updateBranding(branding: Branding): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/Branding`, branding);
	}

	updateSecurity(security: Security): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/Security`, security);
	}

	updateCopyPreview(data: CopyPreview): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/CopyPreview`, data);
	}

	addWorkflow(workflow: Workflow): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/AddWorkflow`, workflow);
	}

	addCustomMitigation(name: string): Observable<Activities> {
		return this.httpClientHelper.invokePostAuth<Activities>(`${this.controller}/Mitigation`, {name});
	}

	updateCustomMitigation(mitigation: CustomField): Observable<Activities> {
		return this.httpClientHelper.invokePatchAuth<Activities>(`${this.controller}/Mitigation/${mitigation.id}`, mitigation);
	}

	deleteCustomMitigation(id: string): Observable<Activities> {
		return this.httpClientHelper.invokeDeleteAuth<Activities>(`${this.controller}/Mitigation/${id}`);
	}

	getCustomFrameworks(): Observable<CustomFrameworksResponse> {
		return this.httpClientHelper.invokeGetAuth<CustomFrameworksResponse>(`${this.controller}/CustomFrameworks`);
	}

	saveNewCustomFramework(newFramework: CustomFramework): Observable<CustomFramework> {
		return this.httpClientHelper.invokePostAuth<CustomFramework>(`${this.controller}/CustomFramework`, newFramework);
	}

	updateCustomFramework(framework: CustomFramework): Observable<CustomFramework> {
		return this.httpClientHelper.invokePatchAuth<CustomFramework>(`${this.controller}/CustomFramework/${framework.id}`, framework);
	}

	deleteCustomFramework(frameworkId: string): Observable<CustomFrameworksResponse> {
		return this.httpClientHelper.invokeDeleteAuth<CustomFrameworksResponse>(`${this.controller}/CustomFramework/${frameworkId}`);
	}

	updateInventoryEntities(entities: Pick<Inventory, "inventoryEntities">): Observable<Pick<Inventory, "inventoryEntities">> {
		return this.httpClientHelper.invokePostAuth<Pick<Inventory, "inventoryEntities">>(`${this.controller}/InventoryEntities`, entities);		
	}

	saveRopaDetails(details: RopaDetails): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/ropadetails`, details);
	}

	saveNewCustomDataType(name: string): Observable<CustomDataType> {
		return this.httpClientHelper.invokePostAuth<CustomDataType>(`${this.controller}/CustomDataType`, { name });
	}

	deleteCustomDataType(dataTypeId: string): Observable<void> {
		return this.httpClientHelper.invokeDeleteAuth<void>(`${this.controller}/CustomDataType/${dataTypeId}`);
	}

	addDataSubject(name: string): Observable<CustomDataSubjects> {
		return this.httpClientHelper.invokePostAuth<CustomDataSubjects>(`${this.controller}/DataSubjects`, {name});
	}

	updateDataSubject(customField: CustomField): Observable<CustomDataSubjects> {
		return this.httpClientHelper.invokePatchAuth<CustomDataSubjects>(`${this.controller}/DataSubjects/${customField.id}`, customField);
	}

	deleteDataSubject(id: string): Observable<CustomDataSubjects> {
		return this.httpClientHelper.invokeDeleteAuth<CustomDataSubjects>(`${this.controller}/DataSubjects/${id}`);
	}

	getDataSubject(id: string): Observable<CustomDataSubjects> {
		return this.httpClientHelper.invokeGetAuth<CustomDataSubjects>(`${this.controller}/DataSubjects/`);
	}

	uploadIcon(fileName: string): Observable<IconUploadResponse> {
		return this.httpClientHelper.invokePostAuth<IconUploadResponse>(`${this.controller}/icon/upload`, {fileName});
	}

	saveIcon(filePath: string): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/icon/validateAndSave`, {filePath});
	}

	getCustomValues(): Observable<CustomValuesResponse> {
		return this.httpClientHelper.invokeGetAuth<CustomValuesResponse>(`${this.controller}/custom-values`);
	}

	addCustomValue(type: CustomValueTypeEnum, name: string): Observable<CustomValue> {
		return this.httpClientHelper.invokePostAuth<CustomValue>(`${this.controller}/custom-values/${type}`, { name });
	}

	updateCustomValues(type: CustomValueTypeEnum, updatedCustomValues: CustomValue[]): Observable<CustomValuesResponse> {
		return this.httpClientHelper.invokePutAuth<CustomValuesResponse>(`${this.controller}/custom-values/${type}`, updatedCustomValues);
	}

	deleteCustomValue(type: CustomValueTypeEnum, customValueId: string): Observable<void> {
		return this.httpClientHelper.invokeDeleteAuth<void>(`${this.controller}/custom-values/${type}/${customValueId}`);
	}

	setDebugMode(debugMode: DebugModeSettings): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/data-classifier/debug-mode`, debugMode);
	}

	getCustomFields(): Observable<CustomFieldV2[]> {
		return this.httpClientHelper.invokeGetAuth<CustomFieldV2[]>(`${this.controller}/custom-fields`);
	}

	addCustomField(customField: CustomFieldV2): Observable<CustomFieldV2> {
		return this.httpClientHelper.invokePostAuth<CustomFieldV2>(`${this.controller}/custom-fields/`, customField);
	}

	updateCustomField(id: string, customField: Partial<CustomFieldV2>): Observable<CustomFieldV2> {
		return this.httpClientHelper.invokePatchAuth<CustomFieldV2>(`${this.controller}/custom-fields/${id}`, customField);
	}

	deleteCustomField(id: string): Observable<void> {
		return this.httpClientHelper.invokeDeleteAuth<void>(`${this.controller}/custom-fields/${id}`);
	}

	connectJira(): Observable<JiraConnectResponse> {
		return this.httpClientHelper.invokePostAuth<JiraConnectResponse>(`${this.controller}/tasks/jira/connect`, {});
	}

	jiraConnectCallback(code: string, state: string): Observable<JiraCallbackResponse> {
		return this.httpClientHelper.invokePostAuth<JiraCallbackResponse>(`${this.controller}/tasks/jira/callback`, {code, state});
	}

	getJiraSites(): Observable<JiraSitesResponse> {
		return this.httpClientHelper.invokeGetAuth<JiraSitesResponse>(`${this.controller}/tasks/jira/sites`);
	}

	getJiraProjects(siteId: string): Observable<JiraProjectsResponse> {
		return this.httpClientHelper.invokeGetAuth<JiraProjectsResponse>(`${this.controller}/tasks/jira/sites/${siteId}/projects`);
	}

	getJiraIssueTypes(siteId: string, projectId: string): Observable<JiraIssueTypesResponse> {
		return this.httpClientHelper.invokeGetAuth<JiraIssueTypesResponse>(`${this.controller}/tasks/jira/sites/${siteId}/projects/${projectId}/issue-types`);
	}

	updateJiraData(jiraData: JiraConnection): Observable<void> {
		return this.httpClientHelper.invokePostAuth<void>(`${this.controller}/tasks/jira`, jiraData);
	}
}
