type PathMatch = 'full' | 'prefix';

export const FullPathMatch = 'full' as PathMatch;

export const PrefixPathMatch = 'prefix' as PathMatch;

export class RoutesManager {
	static readonly pageNotFound: string = 'page-not-found';
	static readonly pageNotAllowed: string = 'page-not-allowed';
	static readonly portalNoPermissions: string = 'pending-verification';
	static readonly postCheckout: string = 'post-checkout';
	static readonly underMaintenance: string = 'under-maintenance';

	// LOGIN ROUTES //
	static readonly login: string = 'login';
	static readonly signup: string = 'sign-up';
	
	// ONBOARDING ROUTES //
	static readonly onboarding: string = 'onboarding';
	static readonly onboarding_default: string = 'default';
	static readonly onboarding_data_mapping: string = 'data-mapping';
	static readonly onboarding_welcome: string = 'welcome';
	static readonly onboarding_create_account: string = 'create-account';
	static readonly onboarding_company: string = 'company';
	static readonly onboarding_connecting: string = 'connecting';
	static readonly onboarding_verify: string = 'verify';
	static readonly onboarding_manual: string = 'verification-required';
	static readonly onboarding_error: string = 'error';
	static readonly onboarding_scheduler: string = 'scheduler';
	static readonly onboarding_confirm_user: string = 'confirm-user';
	static readonly onboarding_verify_auth0: string = 'auth0-verify-user';
	static readonly onboarding_privacy_operations: string = 'privacy-operations';

	// HOME ROUTES //
	static readonly homePage: string = 'home';

	// REQUEST ROUTES //
	static readonly requests: string = 'requests';
	static readonly requests_opened: string = 'open';
	static readonly requests_closed: string = 'closed';
	// static readonly requests_ticket: string = 'ticket';
	static readonly export: string = 'export';
	static readonly requests_ticket_v2: string = 'ticket';
	static readonly requests_ticket_create: string = 'create';
	static readonly requests_ticket_copy_file_viewer: string = 'file-viewer';
	static readonly dsr_setup: string = 'dsr-setup';
	static readonly privacy_rights: string = 'privacy-rights';
	static readonly privacy_workflows_eligibility: string = 'workflows';
	static readonly privacy_right_display: string = 'display';
	static readonly dsr_localizations: string = 'localizations';
    static readonly dsr_templates: string = 'templates';
	static readonly dsr_rules: string = 'rules';

	// INTEGRATIONS ROUTES //
	static readonly data_sources: string = 'data-sources';
	static readonly integrations: string = 'integrations';
	static readonly oauth_callback: string = 'oauthCallback';

	// DATA MAPPING //
	// Only saved for backward compatability purposes. DO NOT USE!!!
	static readonly datamapping: string = 'datamapping';

	// AI ASSETS ROUTES //
	static readonly ai_assets: string = 'ai-assets';

	// AI ASSESSMENTS ROUTES //
	static readonly ai_assessments: string = 'assessments';
	static readonly ai_assessments_templates: string = 'templates';

	// RISK REGISTRY ROUTES //
	static readonly risk_registry: string = 'risk-registry';

	// API Keys ROUTES //
	static readonly api_keys: string = 'api-keys';
	static readonly developers: string = 'developers';
	static readonly notifications: string = 'notifications';
	static readonly integrations_webhook_events: string = 'integration-webhook';
	static readonly jira_tasks: string = 'tasks';
	static readonly jira_connect: string = 'connect';
	static readonly jira_oauth: string = 'oauth';
	static readonly jira_setup: string = 'setup';
	
	// AUTOMATIONS ROUTES //
	static readonly automations: string = 'automations';
	static readonly automations_communication: string = 'communication-templates';
	static readonly automations_requests: string = 'request-status';
	static readonly automated_responses: string = 'automated-responses';
	static readonly automations_reject_status: string = 'reject-status';
	static readonly automations_complete_status: string = 'complete-status';
	static readonly automations_reject_response: string = 'reject-response';
	static readonly automations_cancel_response: string = 'cancel-response';
	static readonly automations_complete_response: string = 'complete-response';
	static readonly automations_acknowledge_response: string = 'acknowledge-response';
	static readonly automations_copy_reject_response: string = 'copy-reject-response';
	static readonly automations_copy_cancel_response: string = 'copy-cancel-response';
	static readonly automations_copy_complete_response: string = 'copy-complete-response';
	static readonly automations_copy_acknowledge_response: string = 'copy-acknowledge-response';
	static readonly automations_copy_attachment_response: string = 'copy-attachment-response';
	static readonly automations_notmail_complete_response: string = 'mail-complete-response';
    static readonly automations_notmail_reject_response: string = 'mail-reject-response';
    static readonly automations_notmail_cancel_response: string = 'mail-cancel-response';
	static readonly automations_edit_complete_response: string = 'edit-complete-response';
    static readonly automations_edit_reject_response: string = 'edit-reject-response';
    static readonly automations_edit_cancel_response: string = 'edit-cancel-response';
	static readonly automations_notsell_complete_response: string = 'notsell-complete-response';
    static readonly automations_notsell_reject_response: string = 'notsell-reject-response';
    static readonly automations_notsell_cancel_response: string = 'notsell-cancel-response';

	// PLANS ROUTES
	static readonly plans: string = 'plans';

	// CONSENT MANAGEMENT
	static readonly consentManagement: string = 'consent-management';

	// PRIVACY CENTER
	static readonly privacyCenter: string = 'privacy-center';

	// PROVIDERS AUTH ROUTES //
	static readonly gmailCallback: string = 'gmailCallback';
	static readonly microsoftCallback: string = 'microsoftCallback';
    static readonly entraCallback: string = 'EntraCallback';
	static readonly auth0Callback: string = 'auth0Callback';
	static readonly ssoCallback: string = 'ssoCallback';

	// PROFILE ROUTES //
	static readonly profileHome: string = 'profile';

	// Email Forwarding //
	static readonly emailForwarding: string = 'forward-emails';

	// Dashboard //
	static readonly dashboard: string = 'dashboard';
	static readonly dashboardRequestHandling: string = 'request-handling';
	static readonly dashboardDataClassification: string = 'data-classifications';
	static readonly dashboardDataInventory: string = 'data-inventory';
	
	//DataMapping 
	static readonly dataMapping: string = 'data-mapping'; // old route
	static readonly dataMappingLogin: string = 'data-mapping-login';
	static readonly dataMappingRopaReport: string = 'ropa-report';
	static readonly dataMappingDataSources: string = 'data-sources';
	static readonly dataMappingManageAccounts: string = 'manage-accounts';
	static readonly dataMappingAccountsManagement: string = 'accounts';
	static readonly dataMappingConnectUserAccount: string = 'connect-user';
	static readonly dataMappingSuccess: string = 'success';

	// DataMapping Systems
	static readonly addSystem: string = 'add-system';
	static readonly risk: string = 'risk';

	// Employees
	static readonly employees: string = 'employees';

	//Processing Activities
	static readonly processingActivities: string = 'processing-activities';
	static readonly ropa: string = 'ropa-report';
	static readonly activity: string = 'activity';
	static readonly addActivity: string = 'add-activity';

	//Channels
	static readonly channels: string = 'channels';

	//Demo
	static readonly demo: string = 'demo';

	//Company settings
	static readonly companySettings: string = 'company-settings';
	static readonly teammates: string = 'teammates';
	static readonly account: string = 'account';
	static readonly profile: string = 'profile'; // section in inventory
	static readonly branding: string = 'branding';
	static readonly general: string = 'general';
	static readonly frameworks: string = 'frameworks';
	static readonly classifications: string = 'classifications';
	static readonly activities: string = 'activities';
	static readonly properties: string = 'properties'; // old route, only for backward compatibility / navigation
	static readonly inventory: string = 'inventory';
	static readonly trash: string = 'trash'; // section in inventory
	static readonly vpnTunnel: string = 'vpn';
	static readonly automationsDashboard: string = 'automations-dashboard';
	static readonly dsrStorage: string = 'dsr-storage'; // section in developers
	static readonly customization: string = 'customization';
	static readonly developersPrismatic: string = 'diy-automations';

	//User search
	static readonly userSearch: string = 'user-search';

	//Dpo advisor
	static readonly dpoAdvisor: string = 'dpo-advisor';

	//Systems
	static readonly systems: string = 'systems';

	//Vendor Management
	static readonly vendorManagement: string = 'vendor-management';

	//Impact Assessment
	static readonly impactAssessment: string = 'impact-assessment';

	//Discovery Tools
	static readonly discovery: string = 'discovery';
	static readonly discoveryTools: string = 'discovery-tools'; // old route
	static readonly discoveryToolsEmail: string = 'emails';
	static readonly discoveryToolsSSO: string = 'sso';
	static readonly discoveryToolsCloudHosting: string = 'cloud-hosting';
	static readonly discoveryToolsWebsiteScan: string = 'website-scan';
	static readonly discoveryToolsCloudHostingGcpCallback: string = 'cloudHostingGcpCallback';

	//Data types
	static readonly dataTypes: string = 'data-types';
	static readonly manageContentDiscovery: string = 'manage';

	//Policies
	static readonly policies: string = 'policies';

	//Radar
	static readonly radar: string = 'radar'; 

	//Reviewer role
	static readonly tasks: string = 'tasks';

	//Unused assets
	static readonly unusedAssets: string = 'unused-assets';
	static readonly unusedSystems: string = 'systems';
	static readonly unusedEmployees: string = 'employees';

	//PII classifier
	static readonly dataClassifier: string = 'data-classifier';
	static readonly piiClassifier: string = 'pii-classifier'; // old route
	static readonly piiClassifierDataTypes: string = 'data-types';
	static readonly piiClassifierSchema: string = 'schema';

	//Setup non DSR integration
	static readonly setupNonDsrIntegration: string = 'setup-non-dsr-integration';

	//Collaboration
	static readonly collaboration: string = 'collaboration';
	static readonly collaborationAuthProxy: string = 'collaboration-auth-proxy';
	static readonly collaborationLogout: string = 'collaboration-logout';
	static readonly collaborationExpiredLink: string = 'expired-link';
	static readonly collaborationAssessmentPage: string = 'assessment-page';
	static readonly collaborationAssessmentFormSubmitted: string = 'confirmation';
}