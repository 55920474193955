import { computed, Injectable, Signal } from "@angular/core";
import { Observable, map } from "rxjs";
import { PrivacyRightItem } from "src/app/api/models/dsr/dsr-rights.interface";
import { DsrPrivacyRightsStore } from "./dsr-privacy-rights.store";

@Injectable({
    providedIn: 'root'
})
export class DsrPrivacyRightsQuery {

    constructor(
        private store: DsrPrivacyRightsStore,
    ) { }

    setLoading(loading: boolean): void {
        this.store.set({ 'loading': loading });
    }

    getLoading(): Signal<boolean | undefined> {
        return this.store.signal('loading');
    }

    selectLoading(): Observable<boolean | undefined> {
        return this.store.select('loading');
    }

    setRights(rights: PrivacyRightItem[]): void {
        const mapRights = new Map<string, PrivacyRightItem>();
        rights.forEach(right => {
            mapRights.set(right.id, right);
        });
        
        this.store.set({ 'rights': mapRights });
    }

    updateRightNameAndDescription(rightId: string, name: string, description: string): void {
        this.store.get('rights').get(rightId).displayName = name;
        this.store.get('rights').get(rightId).description = description;
    }

    // by default, return rights without deleted
    getRights(includeDeletedRights = false): Signal<Map<string, PrivacyRightItem>> {
        return computed(() => {
            const rights = this.store.get('rights');
            return includeDeletedRights ? rights : this.filterDeletedRights(rights);
          });
    }

    // by default, return rights without deleted
    selectRights(includeDeletedRights = false): Observable<Map<string, PrivacyRightItem>> {
        return this.store.select('rights').pipe(
            map(rights => includeDeletedRights ? rights : this.filterDeletedRights(rights)),
        );
    }

    private filterDeletedRights(rights: Map<string, PrivacyRightItem>): Map<string, PrivacyRightItem> {
        return new Map(Array.from(rights).filter(([key, item]) => !item.deleted));
    }

    setRightEnabled(rightId: string, enabled: boolean): void {
        this.store.get('rights').get(rightId).enabled = enabled;
    }

    addRight(right: PrivacyRightItem): void {
        this.store.get('rights').set(right.id, right);
    }
    
    deleteRight(rightId: string): void {
        this.store.get('rights').get(rightId).deleted = true;
    }

}