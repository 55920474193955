import { ContentSchema } from "./content-schema.interface";

export class ContentSchemaHelper {

	// common schema
	static readonly commonSchema: ContentSchema = {
		id: 'c3130d8d-436c-46b4-aa59-34c9eea19e6b',
		name: 'common'
	};

	// seo schema
	static readonly seoSchema: ContentSchema = {
		id: 'b714d504-bfc1-43a4-9ba4-3dfe2c9cbcd1',
		name: 'seo'
	};

	// authentication schema
	static readonly authSchema: ContentSchema = {
		id: '56e741a2-d9ec-4383-937f-55b546b08226',
		name: 'authentication'
	};

	// menus schema
	static readonly menuSchema: ContentSchema = {
		id: '11e5d7ad-ba9c-45dd-b73c-7119bf62a424',
		name: 'menu'
	};

	// onboarding schema
	static readonly onboardingSchema: ContentSchema = {
		id: '5841695a-a39f-450a-b985-0e6a571ee4bf',
		name: 'onboarding'
	};

	// home schema
	static readonly homeSchema: ContentSchema = {
		id: '2ff2df58-8035-4b1d-9fd2-f5263fc3eefc',
		name: 'home'
	};
	
	// plans schema
	static readonly plansSchema: ContentSchema = {
		id: '9c755d88-33da-4733-beda-89480a0c5b25',
		name: 'plans'
	};

	// api keys schema
	static readonly apiKeysSchema: ContentSchema = {
		id: 'b034be76-069e-4d90-a8ec-a8f380fe3115',
		name: 'api-keys'
	};
	
	// privacy center schema
	static readonly privacyCenterSchema: ContentSchema = {
		id: '22baa062-9979-46cf-94a1-85dce5988a96',
		name: 'privacy-center'
	};

	// company profile schema
	static readonly profileSchema: ContentSchema = {
		id: '3697af4f-5b6a-4c7f-a403-bcab6e8a9d8f',
		name: 'profile'
	};

	// knowledge base links schema
	static readonly knowledgeBaseSchema: ContentSchema = {
		id: '8bedb1d8-7c4a-4b09-8f3f-3d71cfd902ee',
		name: 'knowledge-base-urls'
	};

	// export dialog schema
	static readonly exportDialogSchema: ContentSchema = {
		id: '473fb461-9316-44fc-a393-d747c659a0a8',
		name: 'export-dialog'
	};

	// integrations schema
	static readonly integrationsSchema: ContentSchema = {
		id: 'bb29f5f7-f81d-4330-a000-15fa2175c52a',
		name: 'integrations'
	};

	static readonly integrationsWebhookSchema: ContentSchema = {
		id: '2d6ad2ac-06e3-4585-a5ad-48da147beb4e',
		name: 'integrations-webhook'
	};

	static readonly integrationsInternalSystemSchema: ContentSchema = {
		id: 'f6e157d8-0f52-47d7-82aa-26dfb10cf9fd',
		name: 'integrations-internal-system'
	};

	static readonly integrationsApiKeySchema: ContentSchema = {
		id: '16750ac2-af5e-4edc-a1b4-82323a8a20f3',
		name: 'integrations-api-key'
	};
	
	static readonly integrationsOauthSchema: ContentSchema = {
		id: '933a5401-8bb5-4842-86cb-0f0c2ce383ba',
		name: 'integrations-oauth'
	};

	static readonly integrationsEmailSchema: ContentSchema = {
		id: '3a10868c-b8e1-43ba-8b1d-7979da1e33e2',
		name: 'integrations-email'
	};

	static readonly createTicketFormSchema: ContentSchema = {
		id: '0d0d4629-3a50-4bfd-baf9-c11dfc371e0a',
		name: 'create-ticket-form'
	};

	static readonly ticketConversationSchema: ContentSchema = {
		id: 'b4362de1-5205-4e1d-9e80-e87297d4a683',
		name: 'ticket-conversation'
	};

	static readonly automationsSchema: ContentSchema = {
		id: 'a7781829-e55f-4d1b-98ae-81c4248b1de7',
		name: 'automations'
	};

	static readonly dashboardSchema: ContentSchema = {
		id: '6134bf10-50ee-4119-aa13-6356d1882362',
		name: 'dashboard'
	};

	//generic ticket schema
	static readonly ticketSchema: ContentSchema = {
		id:'ba23e2f6-173f-469a-bcc1-09a3089b4581',
		name: 'ticket'
	};

	static readonly requestsTableSchema: ContentSchema = {
		id:'0c2faa7b-3dd8-4d1d-8525-242b0b2ab875',
		name: 'requests-table'
	};
	
	static readonly authorizationDialogSchema: ContentSchema = {
		id:'22266413-fe62-4293-9cf2-84896b5a0601',
		name: 'authorization-dialog'
	};

	static readonly redactedTicketSchema: ContentSchema = {
		id:'d2d46aa7-1bad-4a01-ad18-008a5e1082fd',
		name: 'redacted-ticket'
	};

	static readonly requestPageSchema: ContentSchema = {
		id: 'f9c7a844-cc7a-4115-8f4e-b1858aaaf175',
		name: 'requests-page'
	};
	
	static readonly forwardEmailsSchema: ContentSchema = {
		id:'b8752015-25e0-43d8-b910-4ab379998017',
		name: 'forward-emails'
	};

	// checkout schema
	static readonly checkoutSchema: ContentSchema = {
		id:'1b20ac7e-9e1b-492c-b591-ed198ea9695c',
		name: 'checkout'
	};

	static readonly dataMappingSchema: ContentSchema = {
		id: '2e572753-0adb-484d-bea9-63fffdb1cd40',
		name: 'data-mapping'
	};

	static readonly integrationInsightsSchema: ContentSchema = {
		id: 'dd4b30b7-46b8-4fbb-8b83-956e50dafe9a',
		name: 'integration-insights'
	};

	static readonly connectChannelsSchema: ContentSchema = {
		id: '081f1a3b-6096-45c5-adf8-dda4047997be',
		name: 'connect-channels'
	};

	static readonly developersSchema: ContentSchema = {
		id: '0ab44938-2d2c-49b2-b872-4f5a3969ee48',
		name: 'developers'
	};

	static readonly languagesSchema: ContentSchema = {
		id:'8c3ac1a7-fe46-46b9-9aea-2f0a9ce4d0ea',
		name:'localization-languages'
	};

	static readonly consentManagementSchema: ContentSchema = {
		id: '76aed7d4-0964-43f3-ad1d-5ccdbf6747f9',
		name: 'consent-management'
	};

	static readonly templatesLocalization: ContentSchema = {
		id: '60c4ec9d-741c-4ae3-85c4-7195d517baef',
		name:'templates-localization'
	};

	static readonly integrationAdvancedSettingsSchema: ContentSchema = {
		id: '5c83d7b9-b2aa-4d10-b962-30b01ab31ca9',
		name:'integration-advanced-settings'
	};

	static readonly ropaSchema: ContentSchema = {
		id: '0675996c-0146-411d-9faa-30413f6ecd47',
		name:'ropa'
	};

	// company settings schema
	static readonly companySettingsSchema: ContentSchema = {
		id: 'dd2cd5d1-7603-41b3-bda2-aacf05cc4d4f',
		name: 'company-settings'
	};
	
	static readonly addSystemSchema: ContentSchema = {
		id: '372cda41-ba8a-4cc4-8792-4398671aa39e',
		name: 'add-systems'
	};

	static readonly userSearchSchema: ContentSchema = {
		id: 'cbaa0250-d9ce-409b-aee8-484c771b5ee1',
		name: 'user-search'
	};

	static readonly systemsSchema: ContentSchema = {
		id: '7c2f4d54-0be8-4faf-9ba5-bd084eb4a942',
		name: 'systems'
	};

	static readonly navMenuSchema: ContentSchema = {
		id: '4b613509-bbad-44e3-a2e5-56d093783f10',
		name: 'nav-menu'
	};

	static readonly navMenuV2Schema: ContentSchema = {
		id: 'f0eadcac-ea1d-4581-886d-4b6eff214ba3',
		name: 'nav-menu-v2'
	};
	
	static readonly inventorySchema: ContentSchema = {
		id: '9963bfb8-5ea4-4c63-93db-3991520ee3ec',
		name: 'inventory'
	};

	static readonly vendorScheme: ContentSchema = {
		id: 'be0fea4a-f018-4c16-a2c8-235d2480c0ff',
		name: 'vendor-management'
	};

	static readonly impactAssessment: ContentSchema = {
		id: '2e7ada65-20fc-44aa-9d06-b7ffaf1afbc6',
		name: 'impact-assessment'
	};

	static readonly reviewSubmitDialogSchema: ContentSchema = {
		id: '789d9cd4-d63d-4b4c-a9cf-297dd362beee',
		name: 'review-submit-dialog'
	};

	static readonly discoveryTools: ContentSchema = {
		id: '699aab05-185d-4f57-93af-1296ff8514ac',
		name: 'discovery-tools'
	};

	static readonly dataTypesSchema: ContentSchema = {
		id: '033d1b53-2c34-4962-b1f7-9f116d6bc522',
		name: 'data-types'
	};

	static readonly errorsSchema: ContentSchema = {
		id: 'c0ca8083-ffc9-417c-a0b8-d9abda3d6931',
		name: 'errors'
	};
	
	static readonly policiesSchema: ContentSchema = {
		id: '82bfeb5c-37c1-4e3b-ac56-c45c1843a737',
		name: 'policies'
	};

	static readonly ropaPdfSchema: ContentSchema = {
		id: '6088b62e-b422-49aa-8eca-58eea207ae35',
		name: 'ropa-pdf'
	};

	static readonly ropaXlsxSchema: ContentSchema = {
		id: '52eb1a0e-2add-4e6e-ae55-2d1dfc377a20',
		name: 'ropa-xlsx'
	};

	static readonly employeesSchema: ContentSchema = {
		id: '7ff4b482-6974-48d3-96f9-86f676879ca5',
		name: 'employees'
	};
	
	static readonly ProcessingAcitivitiesSchema: ContentSchema = {
		id: '6e5053de-2ece-4851-9c8c-7d05885387b1',
		name: 'processing-activities'
	};

	static readonly ArchiveSystemSchema: ContentSchema = {
		id: '4e327bff-25a5-477f-9f5e-d34cf3d70574',
		name: 'archive-system'
	};

	static readonly RadarSchema: ContentSchema = {
		id: 'b76a0aca-a984-4899-a8bc-a18c1d6ec16a',
		name: 'radar'
	};

	static readonly TasksSchema: ContentSchema = {
		id: '8c733591-c839-400f-9788-ac3131cca4f2',
		name: 'tasks'
	};

	static readonly UnusedAssetsSchema: ContentSchema = {
		id: '2fa3232d-3e4d-4782-a91d-3c4204974fd3',
		name: 'unused-assets'
	};

	static readonly PropertiesSchema: ContentSchema = {
		id: '617cc955-e13a-4c21-bdac-94219af8615d',
		name: 'properties'
	};
	
	static readonly SystemsDirectorySchema: ContentSchema = {
		id: '6b2c8597-711b-4631-8e5b-50ee63795b29',
		name: 'systems-directory'
	};

	static readonly VpnSchema: ContentSchema = {
		id: '45dab16d-13fe-4cd0-a837-6a45e3beb69a',
		name: 'vpn'
	};

	static readonly IntegrationCustomWebhookSchema: ContentSchema = {
		id: '09bae153-f5b9-4c91-af55-c05748dae1d7',
		name: 'custom-webhook-integration'
	};

	static readonly IntegrationCustomPiiSchema: ContentSchema = {
		id: 'd9316c91-fdbb-4bc5-9190-6d9794a56b77',
		name: 'custom-pii-integration'
	};

	static readonly RequestsAutopilotSchema: ContentSchema = {
		id: 'b250174d-a4f3-4382-9bdd-c52fb2b0ca84',
		name: 'requests-autopilot'
	};

	static readonly PIIClassifierSchema: ContentSchema = {
		id: 'ad7bb7ba-5248-4705-8c6c-457d8f4294c4',
		name: 'pii-classifier'
	};

	static readonly SystemsCategoryListSchema: ContentSchema = {
		id: '18b35df9-97a8-4b2c-aefe-462e4a7bd12a',
		name: 'systems-category-list'
	};

	static readonly ActivityCategoryListSchema: ContentSchema = {
		id: '4ed65fcf-cc23-4660-9e4c-57f1289f84c3',
		name: 'activity-category-list'
	};

	static readonly DsrStorageSchema: ContentSchema = {
		id: '3b251b11-c626-433e-b4ca-583b1939e019',
		name: 'dsr-storage'
	};

	static readonly SharedDialogs: ContentSchema = {
		id: '69e17d46-db95-4f09-9eb7-79d61a6aea65',
		name: 'shared-dialogs'
	};

	static readonly TicketIndications: ContentSchema = {
		id: 'e8e541ae-e89d-49ef-b93b-104f9680f3bc',
		name: 'ticket-indications'
	};

	static readonly CompanySettingsCustomization: ContentSchema = {
		id: '8d83e30d-caa1-4144-bfe7-a216c7878da4',
		name: 'company-settings-customization'
	};

	static readonly DataClassifierSettings: ContentSchema = {
		id: 'e7067c82-971d-496e-a9c4-966555a9e63a',
		name: 'data-classifier-settings'
	};

	static readonly AiAssets: ContentSchema = {
		id: '98a0fed9-8e41-4674-9063-3513dfe0c624',
		name: 'ai-assets'
	};

	static readonly AiAssessments: ContentSchema = {
		id: '8e60873d-57e8-4f8b-9d7b-7d8011b22f04',
		name: 'ai-assessments'
	};

	static readonly AiAssessmentsCollaboration: ContentSchema = {
		id: '5c8e63da-fd46-49f1-b18c-49a6bb8ab6a4',
		name: 'ai-assessments-collaboration'
	};

	static readonly CustomFieldsPanel: ContentSchema = {
		id: 'cc85e2dd-69bd-4170-a657-716c85ba5024',
		name: 'custom-fields-panel'
	};

	static readonly DsrTicketInfo: ContentSchema = {
		id: '67e4d246-29fb-4e14-9629-ee92c5a197f6',
		name: 'dsr-ticket-info'
	};

	static readonly DsrTicketHeader: ContentSchema = {
		id: 'fa631d7f-8267-4497-b960-d442ddbbd9e6',
		name: 'dsr-ticket-header'
	};

	static readonly DsrTicketProcessing: ContentSchema = {
		id: 'a4492b25-f9c5-4c3d-bf9d-56d31f5454d5',
		name: 'dsr-ticket-processing'
	};

	static readonly DsrTasksRecordPanel: ContentSchema = {
		id: '0262b558-4e57-41cb-91a9-cf2f0809785b',
		name: 'dsr-tasks-record-panel'
	};

	static readonly DsrTicketContent: ContentSchema = {
		id: '5fe649e9-a954-427d-b1b3-25a69e4c7de3',
		name: 'dsr-ticket-content'
	};
	
	static readonly Chatbot: ContentSchema = {
		id: 'bbf57370-b459-4763-9549-d98a26e394ea',
		name: 'chatbot'
	};

	static readonly DsrCollectionTicketProcessing: ContentSchema = {
		id: '26baab15-158c-4c62-8b72-a02cd7bb2ecd',
		name: 'dsr-collection-ticket-processing'
	};

	static readonly Jira: ContentSchema = {
		id: '32ea9c20-9374-4b48-82cf-b4e0f26b5b4d',
		name: 'jira'
	};

	static readonly MaintenanceSchema: ContentSchema = {
		id: 'b6f8d848-1c84-443a-b378-5a08d8e3b7b7',
		name: 'maintenance'
	};

	static readonly DsrSetup: ContentSchema = {
		id: 'e17f73d8-b523-474b-8f72-06ad4ae5d39c',
		name: 'dsr-setup'
	};
	
	static readonly DsrRight: ContentSchema = {
		id: '0f0e7765-3e9d-4572-879b-444c65c801a1',
		name: 'dsr-privacy-right'
	};

	static readonly LocationCodes: ContentSchema = {
		id: '4620251d-85f7-4349-b4ba-e994004c9964',
		name: 'location-codes'
	};

	static readonly ExportRopaDialog: ContentSchema = {
		id: 'aa5e6ee2-4959-4e27-abb6-529f1e61bcbb',
		name: 'export-ropa-dialog'
	};

	static readonly PdfGenerator: ContentSchema = {
		id: '444b0d4e-e942-4aad-94a9-457de4a9c51d',
		name: 'pdf-generator'
	};

	static readonly CopyFileViewer: ContentSchema = {
		id: '9fd23ada-5cd5-4fee-920a-fcafdf2113b5',
		name: 'copy-file-viewer'
	};

	static readonly Risks: ContentSchema = {
		id: '593a2758-39b8-402b-a201-5a80b355b248',
		name: 'risks'
	};

	static readonly DsrSetupLocalization: ContentSchema = {
		id: '01cc6ac9-91c5-41ac-92e6-1facfbaca402',
		name: 'dsr-setup-localization'
	};
	
	static readonly DsrTemplatePage: ContentSchema = {
		id: '96c024fd-d20a-4afb-a678-e1b03c69862f',
		name: 'dsr-template-page'
	};

    static readonly DsrTemplatesPage: ContentSchema = {
        id: '84826c8c-4c11-4a7a-a97a-317eb53a36aa',
        name: 'dsr-setup-templates'
    };

	static readonly DsrNewRightDialog: ContentSchema = {
        id: 'eb41c751-61c0-49b9-8993-b2c88b4133e9',
        name: 'dsr-new-right-dialog'
    };
}